import { createSelector, createFeatureSelector } from '@ngrx/store';
import { IStateShared, IOnlineMenuPageProductsImage } from '../interface';

const state = createFeatureSelector<IStateShared, IOnlineMenuPageProductsImage[]>('onlineMenuProductsImages');

export const getImagesForOnlineMenuPages = createSelector(
    state,
    onlineMenuPagesImages => onlineMenuPagesImages
);

export const getImagesForOnlineMenuPage = (pageId: number) => createSelector(
    state,
    onlineMenuPagesImages => onlineMenuPagesImages.find(obj => obj.pageId === pageId)
);

export const getImageForOnlineMenuPageProduct = (pageId: number, pageProductId: number) => createSelector(
    getImagesForOnlineMenuPage(pageId),
    pageImages => {
        if (!pageImages || !pageImages.data) return null;

        return pageImages.data.find(image => image.ParentId === pageProductId);
    }
);

export const isDownloadingAnyOnlineMenuPageImages = createSelector(
    state,
    onlineMenuPagesImages => onlineMenuPagesImages.some(obj => obj.isDownloading === true),
);
