import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Store } from '@ngrx/store';

import * as Tokens from '@shared/core/tokens';
import * as State from '@shared/state/interface';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { IMenuFlowDefaultActivation, IMenuFlowDetailsModel } from '@shared/state/interface';
import { MenuFlowsMapper } from '@shared/core/mappers';

@Injectable({
    providedIn: 'root',
})
export class MenuFlowsService {

    constructor(
        @Inject(Tokens.CONFIG_TOKEN) public config: IConfig,
        public httpClient: HttpClient,
        public store: Store<State.IStateShared>,
    ) {
    }

    public getMenuFlowDetailsForLocation(menuFlowId: number, locationNo: number): Observable<IMenuFlowDetailsModel> {
        return this.httpClient
            .get<APIv3.MenuFlowDetailsModel>(`${this.config.api.base}/menuFlows/${menuFlowId}/details/${locationNo}`)
            .pipe(
                map((menuFlow: APIv3.MenuFlowDetailsModel) => MenuFlowsMapper.mapGetMenuFlowDetailsForLocation(menuFlow))
            );
    }

    public getFlatProductsFromMenuFlow(menuFlow: IMenuFlowDetailsModel): APICommon.IMenuFlowProduct[] {
        return menuFlow.Pages.reduce((prevPage, nextPage) => prevPage.concat(nextPage.Products), []);
    }

    public getDefaultActivation(menuFlowId: number): Observable<IMenuFlowDefaultActivation> {
        return this.httpClient
            .get<APIv3.MenuFlowDefaultActivation>(`${this.config.api.base}/menuFlows/${menuFlowId}/defaultActivation`)
            .pipe(
                map((menuFlow: APIv3.MenuFlowDefaultActivation) => MenuFlowsMapper.mapGetDefaultActivation(menuFlow))
            );
    }
}
