import { IOnlineOrderDetailedBusinessModel } from '@shared/state';

export class DetailedOrderTotalsReceiptCalculator implements OLO.Ordering.IOrderSummary {
    public Subtotal: number = 0;
    public Tax: number = 0;
    public Total: number = 0;
    public Surcharges: APICommon.IOrderSurchargeDefinition[] = [];

    constructor(
        private _order: IOnlineOrderDetailedBusinessModel
    ) {
        if (!_order) return;

        this._calculateValues();
    }

    private _calculateValues(): void {
        this.Surcharges = this._order.Surcharges;
        this.Total = this._order.TotalGrossValue;
        this.Subtotal = this._order.TotalNettValue;
        this.Tax = this._order.TotalTaxes.reduce((acc, tax) => acc += tax.Value, 0);
    }
}
