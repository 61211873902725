import { createSelector, createFeatureSelector } from '@ngrx/store';
import { IStateShared, ICartPopup } from '../interface';

const state = createFeatureSelector<IStateShared, ICartPopup>('cartPopup');

export const getCartPopup = createSelector(
    state,
    cartPopup => cartPopup,
);

export const isCartPopupVisible = createSelector(
    state,
    cartPopup => cartPopup.isVisible,
);

export const cartPopupAnimation = createSelector(
    state,
    cartPopup => cartPopup.animation,
);
