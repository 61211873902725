export class CollectionTypeGroupDetector {
    private _collectionType: OLO.Enums.COLLECTION_TYPE = null;

    constructor(
        private _orderTypeId: number,
        private _config: IConfig,
    ) {
        if (!_orderTypeId || !_config) return;

        this._init();
    }

    private _init(): void {
        const c = this._config.collectionTypes;

        const deliveryId = c?.delivery?.orderTypeId || null;
        const dineInBuzzer = c?.dineIn?.dineInBuzzer?.orderTypeId || null;
        const dineInTable = c?.dineIn?.dineInTable?.orderTypeId || null;
        const pickupsIds = c?.pickup?.orderTypeIds || [];

        const oid = this._orderTypeId;
        switch (true) {
            case oid === deliveryId || oid === OLO.Enums.COLLECTION_TYPE.DELIVERY:
                this._collectionType = OLO.Enums.COLLECTION_TYPE.DELIVERY;
                break;

            case oid === dineInBuzzer || oid === OLO.Enums.COLLECTION_TYPE.DINE_IN:
            case oid === dineInTable || oid === OLO.Enums.COLLECTION_TYPE.DINE_IN:
                this._collectionType = OLO.Enums.COLLECTION_TYPE.DINE_IN;
                break;

            case pickupsIds.includes(oid) || oid === OLO.Enums.COLLECTION_TYPE.PICKUP:
                this._collectionType = OLO.Enums.COLLECTION_TYPE.PICKUP;
                break;
        }
    }

    public getCollectionType(): OLO.Enums.COLLECTION_TYPE {
        return this._collectionType;
    }

    public isDineIn(): boolean {
        return this.getCollectionType() === OLO.Enums.COLLECTION_TYPE.DINE_IN;
    }

    public isDelivery(): boolean {
        return this.getCollectionType() === OLO.Enums.COLLECTION_TYPE.DELIVERY;
    }

    public isPickup(): boolean {
        return this.getCollectionType() === OLO.Enums.COLLECTION_TYPE.PICKUP;
    }
}