import { NgModule } from '@angular/core';

// import {
//     AlertMessagesStateModule,
//     AvailablePickupsStateModule,
//     CartStateModule,
//     CreditCardsStateModule,
//     CurrentLocationStateModule,
//     DietaryTagsImagesStateModule,
//     HistoryOrdersStateModule,
//     IngredientsStateModule,
//     LoaderStateModule,
//     LocationsStateModule,
//     LocationsFiltersStateModule,
//     LocationsImagesStateModule,
//     MembersStateModule,
//     MenuFlowDefaultActivationsStateModule,
//     MenuFlowImagesStateModule,
//     MenuFlowsStateModule,
//     ModalsStateModule,
//     OnlineMenuStateModule,
//     OnlineMenuPagesImagesStateModule,
//     OnlineOrderStateModule,
//     OrderingTimeInfoStateModule,
//     PaymentStateModule,
//     PickupTimeValidateStateModule,
//     ProductImagesStateModule,
//     ReorderStateModule,
//     RouterStateModule,
//     TopBarStateModule,
//     VenuesImagesStateModule,
//     WizzardStateModule,
// } from '@shared';

// /*
//     If you need to customize shared effects, create own module in your current project with new effects,
//     and include it as a feature
// */
// const stateModules: any[] = [
//     AlertMessagesStateModule,
//     AvailablePickupsStateModule,
//     CartStateModule,
//     CreditCardsStateModule,
//     CurrentLocationStateModule,
//     DietaryTagsImagesStateModule,
//     HistoryOrdersStateModule,
//     IngredientsStateModule,
//     LoaderStateModule,
//     LocationsStateModule,
//     LocationsFiltersStateModule,
//     LocationsImagesStateModule,
//     MembersStateModule,
//     MenuFlowDefaultActivationsStateModule,
//     MenuFlowImagesStateModule,
//     MenuFlowsStateModule,
//     ModalsStateModule,
//     OnlineMenuStateModule,
//     OnlineMenuPagesImagesStateModule,
//     OnlineOrderStateModule,
//     OrderingTimeInfoStateModule,
//     PaymentStateModule,
//     PickupTimeValidateStateModule,
//     ProductImagesStateModule,
//     ReorderStateModule,
//     ReorderStateModule, /* Does that actually works? Check meta-reducers */
//     RouterStateModule, /* Check meta-reducers */
//     TopBarStateModule,
//     VenuesImagesStateModule,
//     WizzardStateModule,
// ];

import { StateSharedModule } from '@shared/state';

@NgModule({
    imports: [
        StateSharedModule,
    ],
    exports: [
        StateSharedModule
    ],
})
export class StateModule { }
