import { Injectable, Inject } from '@angular/core';
import { Store, select, Action } from '@ngrx/store';
import { Actions, Effect, ofType } from '@ngrx/effects';

import * as actions from '../actions';
import * as selectors from '../selectors';

import * as Tokens from '@shared/core/tokens';
import * as Utils from '@shared/core/utils';
import * as Services from '@shared/core/services';

import * as StateModels from '../interface';

import { Observable, of, forkJoin } from 'rxjs';
import { catchError, map, switchMap, take, filter, withLatestFrom, delay, mergeMap } from 'rxjs/operators';
import { IExecutePaymentModel } from '../interface';


@Injectable()
export class PaymentsEffects {
    @Effect() public paymentInitWithPrevalidatedCard$: Observable<Action> = this._actions$
        .pipe(
            ofType(
                actions.PaymentInit
            ),
            withLatestFrom(
                this._store.pipe(select(selectors.getCardState)),
                this._store.pipe(select(selectors.getMemberState)),
                this._store.pipe(select(selectors.paymentHasErrors)),
                this._store.pipe(select(selectors.getPaymentStepsStatus)),
                this._store.pipe(select(selectors.getPaymentState))
            ),
            switchMap(([action, cardState, memberState, paymentHasErrors, step, paymentState]) => {
                if (paymentHasErrors) {
                    if (paymentState.OrderId && paymentState.data.TransactionId) {
                        if (this._config.demoMode === true) {
                            return [
                                actions.PaymentClearErrors(),
                                actions.__DEMO__PaymentStepPaymentStatusCheck({ TransactionId: paymentState.data.TransactionId, OrderId: paymentState.OrderId }),
                            ];
                        }

                        return [
                            actions.PaymentClearErrors(),
                            actions.PaymentStepPaymentStatusCheck({ TransactionId: paymentState.data.TransactionId, OrderId: paymentState.OrderId }),
                        ];
                    }

                    return this._error('#33 Payment process has errors');
                }

                if (step !== 'init') {
                    return this._error(`#37 Payment step error. Should be "init", is: ${step}`);
                }

                if (this._config.paymentProvider === undefined || this._config.paymentProvider === null) {
                    return this._error('#41 Payment provider not defined. Defined it config.js file');
                }

                const isGuest: boolean = memberState.isGuestModeEnabled && memberState.guestData !== null;
                if (isGuest) {
                    return [
                        actions.MemberValidateEmailDataRequest({ email: memberState.guestData.Email, memberPhoneIdPriority: true }),
                        actions.MemberValidatePhoneRequest({ phone: memberState.guestData.MobileNumber, memberPhoneIdPriority: true }),
                        actions.PaymentStepValidateGuestData(null),
                    ];
                }

                if (this._config.demoMode === true) {
                    return of(actions.__DEMO__PaymentStepCreateOrder());
                }

                /* For members */
                return of(actions.PaymentStepCreateOrder());
            })
        );

    @Effect() public stepValidateGuestData$: Observable<Action> = this._actions$
        .pipe(
            ofType(actions.PaymentStepValidateGuestData),
            switchMap(action => this._store
                .pipe(
                    select(selectors.getMemberState),
                    filter(member => member.validateEmail.isValidating !== true && member.validatePhone.isValidating !== true),
                    take(1),
                    switchMap(state => {
                        if (state.validateEmail.hasFailed || state.validatePhone.hasFailed) {
                            return this._error('#120 Invalid guest\'s email address or phone number');
                        }

                        return forkJoin(
                            this._membersService
                                .validateMemberByProperty(
                                    state.guestData.MobileNumber,
                                    state.guestData.MobilePhoneCountryId,
                                    OLO.Enums.LOGIN_TYPE.MOBILE_PHONE_BASED_LOGIN
                                ),
                            this._membersService
                                .validateMemberByProperty(
                                    state.guestData.Email,
                                    state.guestData.MobilePhoneCountryId,
                                    OLO.Enums.LOGIN_TYPE.EMAIL_BASED_LOGIN
                                ),
                        ).pipe(
                            withLatestFrom(
                                this._store.pipe(select(selectors.paymentHasErrors)),
                                this._store.pipe(select(selectors.getPaymentStepsStatus)),
                            ),
                            switchMap(([[byPhone, byEmail], paymentHasErrors, step]) => {
                                if (paymentHasErrors) {
                                    return this._error('#133 Payment process has errors');
                                }

                                if (step !== 'validate_guest') {
                                    return this._error(`#137 Payment step error. Should be "validate_guest", is: ${step}`);
                                }

                                const nextStep = () => {
                                    if (this._config.demoMode === true) {
                                        return of(actions.__DEMO__PaymentStepCreateOrder());
                                    }

                                    /* If card is provided but not saved, run these steps */
                                    if (action.creditCard) {
                                        switch (this._config.paymentProvider) {
                                            case OLO.Enums.PAYMENT_PROVIDER.CONVERGE:
                                                return [
                                                    actions.GetCreditCardToken({
                                                        cardNumber: action.creditCard.cardNo,
                                                        expiryDate: action.creditCard.expDate,
                                                        saveCard: action.creditCard.save
                                                    }
                                                    ),
                                                    actions.PaymentStepValidateGuestCardToken()
                                                ];

                                            case OLO.Enums.PAYMENT_PROVIDER.PAYMENT_EXPRESS:
                                            case OLO.Enums.PAYMENT_PROVIDER.FAT_ZEBRA:
                                            case OLO.Enums.PAYMENT_PROVIDER.ADYEN:
                                                return of(actions.PaymentStepCreateOrder());

                                            case OLO.Enums.PAYMENT_PROVIDER.CARD_CONNECT:
                                                console.warn('TODO - HANDLE CARD CONNECT');

                                                return [];

                                            default:
                                                console.error('Payment provider not provided in config.js');

                                                return [];
                                        }
                                    }

                                    return of(actions.PaymentStepCreateOrder());
                                };

                                return nextStep();

                            })
                        );
                    })
                )),
            catchError(ex => this._error('#212 Payment failed', ex))

        );

    @Effect() public stepValidateGuestCardTokens$: Observable<Action> = this._actions$
        .pipe(
            ofType(
                actions.PaymentStepValidateGuestCardToken
            ),
            switchMap(action => this._store
                .pipe(
                    select(selectors.getCardState),
                    filter(cardsState => cardsState.token.isGettingToken === false
                        && (cardsState.token.hasSucceeded === true
                            && cardsState.activeCardToken !== null
                            || cardsState.token.hasFailed === true
                        )
                    ),
                    take(1),
                    withLatestFrom(
                        this._store.pipe(select(selectors.paymentHasErrors)),
                        this._store.pipe(select(selectors.getPaymentStepsStatus)),
                    ),
                    switchMap(([cardsState, paymentHasErrors, step]) => {
                        if (paymentHasErrors) {
                            return this._error('#234 Payment process has errors');
                        }

                        if (step !== 'validate_card_token') {
                            return this._error(`#238 Payment step error. Should be "validate_card_token", is: ${step}`);
                        }

                        if (cardsState.token.hasFailed || cardsState.activeCardToken === null) {
                            return this._error('#242 Unable to get card token');
                        }

                        return of(actions.PaymentStepCreateOrder());
                    })
                ))
        );

    @Effect() public stepCreateOrder$: Observable<Action> = this._actions$
        .pipe(
            ofType(
                actions.PaymentStepCreateOrder,
            ),
            withLatestFrom(
                this._store.pipe(select(selectors.canPostOnlineOrder)),
                this._store.pipe(select(selectors.canPayForOnlineOrder)),
                this._store.pipe(select(selectors.isPaymentProcessValid)),
                this._store.pipe(select(selectors.paymentHasErrors)),
                this._store.pipe(select(selectors.getPaymentStepsStatus)),
                this._store.pipe(select(selectors.isZeroPricedOrder))
            ),
            switchMap(([action, canPostOnlineOrder, canPayForOnlineOrder, isPaymentProcessValid, paymentHasErrors, step, isZeroPricedOrder]) => {

                if (!canPostOnlineOrder) {
                    return this._error('#264a Unable to create order - insufficient data or data corrupted');
                }

                if (step !== 'create_order') {
                    return this._error(`#268 Payment step error. Should be "create_order", is: ${step}`);
                }

                if (isZeroPricedOrder) {
                    return [
                        actions.OnlineOrderClearPostOrderRequestFlags(),
                        actions.OnlineOrderCreateRequest(),
                        actions.PaymentStepSkipForZeroPricedOrder(),
                    ];
                }

                if (!canPayForOnlineOrder || !isPaymentProcessValid || paymentHasErrors) {
                    return this._error('#264b Unable to create order - payment validation or corrupted data');
                }

                return [
                    actions.OnlineOrderClearPostOrderRequestFlags(),
                    actions.OnlineOrderCreateRequest(),
                    actions.PaymentStepPay(),
                ];
            })
        );

    @Effect() public stepPay$: Observable<Action> = this._actions$
        .pipe(
            ofType(
                actions.PaymentStepPay
            ),
            switchMap(action => this._store
                .pipe(
                    select(selectors.getOnlineOrderState),
                    filter(orderState => orderState.createRequest.isCreating === false),
                    take(1),
                    withLatestFrom(
                        this._store.pipe(select(selectors.getCardState)),
                        this._store.pipe(select(selectors.getOnlineOrder)),
                        this._store.pipe(select(selectors.getActiveCardDetails)),
                        this._store.pipe(select(selectors.isAccountChargeSelected)),
                    ),
                    switchMap(([orderState, cardState, onlineOrder, cardDetails, isAccountChargeSelected]) => {
                        if (orderState.createRequest.hasFailed) {
                            return this._error('#299 Unable to create order');
                        }

                        return this._store
                            .pipe(
                                select(selectors.isCartLocationsPickupsCalculating),
                                filter(isCalculating => isCalculating === false),
                                take(1),
                                withLatestFrom(
                                    this._store.pipe(select(selectors.paymentHasErrors)),
                                    this._store.pipe(select(selectors.getPaymentStepsStatus)),
                                ),
                                switchMap(([calculated, paymentHasErrors, step]) => {
                                    if (paymentHasErrors) {
                                        return this._error('#312 Unable to create order due to payment process errors');
                                    }

                                    if (step !== 'paying') {
                                        return this._error(`#317 Payment step error. Should be "paying", is: ${step}`);
                                    }
                                    if (isAccountChargeSelected) {
                                        /* Handle account charge */
                                        return this._paymentsService.payWithAccountCharge(onlineOrder.Id, {
                                            Amount: onlineOrder.TotalGrossValue,
                                            SetOrderAsValidatedOnSuccess: true,
                                        })
                                            .pipe(
                                                switchMap(payload => {
                                                    switch (payload.Status) {
                                                        case OLO.Enums.PAYMENT_STATUS.SUCCESS:
                                                            return of(actions.PaymentStepComplete({ OrderId: onlineOrder.Id, payload }));

                                                        case OLO.Enums.PAYMENT_STATUS.FAILED:
                                                            return [
                                                                actions.PaymentReset(),
                                                                ...this._error('#373 Payment declined by Payment Provider. Status: ' + payload.Status, payload.Status)
                                                            ];
                                                    }
                                                }),
                                            );
                                    }

                                    const OrderId: number = onlineOrder.Id;
                                    const PaymentProvider: OLO.Enums.PAYMENT_PROVIDER = this._config.paymentProvider;
                                    const PaymentMethod: IExecutePaymentModel = {
                                        Amount: onlineOrder.TotalLeftToPay,
                                        PaymentAccountId: cardState.activeCardId,
                                        Token: cardState.activeCardToken,
                                        SetOrderAsValidatedOnSuccess: true,
                                        PaymentProvider: PaymentProvider,
                                    };

                                    if (PaymentProvider === OLO.Enums.PAYMENT_PROVIDER.CARD_CONNECT) {
                                        PaymentMethod.ExpirationDate = Utils.CreditCards.dateToISOString(cardDetails.ExpirationDate);
                                    }

                                    if(PaymentProvider === OLO.Enums.PAYMENT_PROVIDER.FAT_ZEBRA) {
                                        PaymentMethod.FatZebraToken = {
                                            r: cardState.fatZebra.r,
                                            v: cardState.fatZebra.v,
                                        };
                                    }

                                    const card = cardState.data.find(obj => cardState.activeCardToken ?
                                        (obj.Token === cardState.activeCardToken) :
                                        (cardState.activeCardId === obj.Id)
                                    );
                                    if(PaymentProvider === OLO.Enums.PAYMENT_PROVIDER.ADYEN) {
                                        PaymentMethod.CardData = {
                                            EncryptedCardNumber: card?.AdyenPaymentData?.encryptedCardNumber,
                                            EncryptedExpiryMonth: card?.AdyenPaymentData?.encryptedExpiryMonth,
                                            EncryptedExpiryYear: card?.AdyenPaymentData?.encryptedExpiryYear,
                                            EncryptedSecurityCode: card?.AdyenPaymentData?.encryptedSecurityCode,
                                            SaveForFuture: card?.SaveAwait
                                        };

                                        if(cardState.activeCardToken) {
                                            PaymentMethod.Token = null;
                                            PaymentMethod.PaymentAccountId = null;
                                        }

                                        if(cardState.activeCardId) {
                                            PaymentMethod.CardData = {
                                                EncryptedSecurityCode: card?.AdyenPaymentData?.encryptedSecurityCode || null,
                                            };
                                        }
                                    }

                                    if(PaymentProvider === OLO.Enums.PAYMENT_PROVIDER.STRIPE && !cardState.activeCardId && cardState.activeCardToken) {
                                        PaymentMethod.Token = card.Token;
                                        PaymentMethod.PaymentAccountId = null;
                                        PaymentMethod.StripePaymentDetails = {
                                            CardType: card.CardType,
                                            SaveForLaterUse: card?.SaveAwait,
                                            Last4Digits: card.StripePaymentData?.card?.last4 || null,
                                            ExpirationDate: card.ExpirationDate,
                                            IsDefault: card.IsDefault || false
                                        };
                                    }

                                    return this._paymentsService.pay(OrderId, PaymentMethod)
                                        .pipe(
                                            map(({ TransactionId }) => actions.PaymentStepPaymentStatusCheck({ TransactionId, OrderId })),
                                            catchError(ex => this._error('#350 Payment failed', ex))
                                        );
                                })
                            );
                    })
                )),
            catchError(ex => this._error('#374 Payment failed', ex))
        );

    @Effect() public stepPaymentStatusCheck$: Observable<Action> = this._actions$
        .pipe(
            ofType(
                actions.PaymentStepPaymentStatusCheck
            ),
            withLatestFrom(
                this._store.pipe(select(selectors.getPaymentStepsStatus)),
            ),
            mergeMap(([action, step]) => {
                if (step !== 'payment_status_check') {
                    return this._error(`#361 Payment step error. Should be "payment_status_check", is: ${step}`);
                }

                return this._paymentsService.getPaymentStatus(action.TransactionId)
                    .pipe(
                        delay(1000),
                        switchMap(payload => {
                            switch (payload.Status) {
                                case OLO.Enums.PAYMENT_STATUS.SUCCESS:
                                    return of(actions.PaymentStepComplete({ OrderId: action.OrderId, payload }));

                                case OLO.Enums.PAYMENT_STATUS.FAILED:
                                    return [
                                        actions.PaymentReset(),
                                        ...this._error('#373 Payment declined by Payment Provider. Status: ' + payload.Status, payload.Status)
                                    ];

                                default:
                                    return of(actions.PaymentStepPaymentStatusCheck({ TransactionId: action.TransactionId, OrderId: action.OrderId }));
                            }
                        }),
                    );
            }),
            catchError(ex => this._error('#382 Payment status check failed', ex))
        );

    @Effect() public stepSkipPay$: Observable<Action> = this._actions$
        .pipe(
            ofType(
                actions.PaymentStepSkipForZeroPricedOrder
            ),
            switchMap(action => this._store
                .pipe(
                    select(selectors.getOnlineOrderState),
                    filter(orderState => orderState.createRequest.isCreating === false),
                    take(1),
                    withLatestFrom(
                        this._store.pipe(select(selectors.getOnlineOrder)),
                    ),
                    switchMap(([orderState, onlineOrder]) => {
                        if (orderState.createRequest.hasFailed) {
                            return this._error('#299a Unable to create order');
                        }

                        return of(actions.PaymentStepComplete({ OrderId: onlineOrder.Id, payload: {} }));

                    })
                )),
            catchError(ex => this._error('#350a Skip Payment failed', ex))
        );


    @Effect() public cleanUpOnSuccessfulPayment$ = this._actions$
        .pipe(
            ofType(actions.PaymentStepComplete),
            withLatestFrom(
                this._store
                    .pipe(
                        select(selectors.getCardState)
                    ),
                this._store
                    .pipe(
                        select(selectors.isMemberAuthorizedJWT)
                    )
            ),
            switchMap(([action, cardsState, isAuthorized]) => {
                const bundleActions: Action[] = [
                    actions.CreditCardTokenDataReset(),
                    actions.OnlineOrderStateReset(),
                    actions.CartReset(),
                    actions.LocationsFiltersReset(),
                    actions.CurrentLocationReset(),
                    actions.SetCollectionType({
                        orderTypeId: null,
                        address: null,
                        tableNo: null
                    }),
                    actions.CreditCardsStateReset()
                ];

                if (this._config.onlineOrders?.sendAutoConfirmationEmail === true) {
                    bundleActions.push(
                        actions.OnlineOrderSendConfrimationEmailRequest({ orderId: action.OrderId })
                    );
                }

                return bundleActions;
            }),
        );

    private _error(error: string = '', ex: any = null): Array<Action> {
        console.error('Payment error:', error, ex);

        return [
            actions.OnlineOrderClearPostOrderRequestFlags(),
            // actions.CreditCardTokenDataReset(),
            actions.PaymentStepFailed(error)
        ];
    }

    constructor(
        @Inject(Tokens.CONFIG_TOKEN) private _config: IConfig,
        private _actions$: Actions,
        private _paymentsService: Services.PaymentsService,
        private _membersService: Services.MembersService,
        private _store: Store<StateModels.IStateShared>,
    ) { }
}
