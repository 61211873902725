import { createAction, props } from '@ngrx/store';

export const GooglePlaceSearchRequest = createAction(
    '[Google] Request place search',
    props<{ search: string; }>(),
);

export const GooglePlaceSearchSuccessRequest = createAction(
    '[Google] Success request place search',
    props<{ search: string; payload: Array<APICommon.IGooglePlaceDetails>; }>(),
);


export const GooglePlaceSearchErrorRequest = createAction(
    '[Google] Error request place search',
    props<{ search: string; ex: any; }>(),
);

export const GoogleInitSuccess = createAction(
    '[Google] Init Success'
);
