import { IAdyenSettingsResponse } from '@shared/state';

export class AdyenPaymentProviderMapper {
    public static mapAdyenPaymentGetSettingsForLocation(settingsForLocation: APIv3.PaymentsGetAdyenSettings.Responses.$200): IAdyenSettingsResponse {
        return settingsForLocation ? {
            CountryCode: settingsForLocation.CountryCode,
            Currency: settingsForLocation.Currency,
            ClientKey: settingsForLocation.ClientKey,
            Environment: settingsForLocation.Environment,
        } : null;
    }
}
