import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

import { PaymentsEffects } from './payment.effects';
import { PaymentsDemoEffects } from './payment-demo.effects';
import { paymentsReducer } from './payment.reducer';

@NgModule({
    declarations: [],
    imports: [
        CommonModule,
        StoreModule.forFeature('payment', paymentsReducer),
        EffectsModule.forFeature([
            PaymentsEffects,
            PaymentsDemoEffects,
        ]),
    ],
    exports: [],
    providers: [],
})
export class PaymentStateModule { }
