import { createReducer, on, Action } from '@ngrx/store';
import { ILocations } from './locations.interface';
import * as actions from './locations.actions';

const initialState: ILocations = {
    isDownloading: false,
    hasSucceeded: false,
    hasFailed: false,
    data: []
};

export const locationsReducerFn = createReducer(
    initialState,
    on(
        actions.LocationsRequest,
        (state, action) => ({
            ...state,
            isDownloading: true,
            hasSucceeded: false,
            hasFailed: false,
        })
    ),
    on(
        actions.LocationsSuccessRequest,
        (state, action) => ({
            ...state,
            isDownloading: false,
            hasSucceeded: true,
            hasFailed: false,
            data: [
                ...action.payload
            ],
        })
    ),
    on(
        actions.LocationsErrorRequest,
        (state, action) => ({
            ...state,
            isDownloading: false,
            hasSucceeded: false,
            hasFailed: true,
        })
    )
);


export function locationsReducer(state: ILocations | undefined, action: Action) {
    return locationsReducerFn(state, action);
}
