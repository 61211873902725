import { createSelector, createFeatureSelector } from '@ngrx/store';
import { IStateShared, IGoogle } from '../interface';

const state = createFeatureSelector<IStateShared, IGoogle>('google');

export const getGoogleState = createSelector(
    state,
    google => google,
);

export const getGooglePlacesResults = createSelector(
    state,
    google => google?.places?.data || null
);

export const isGoogleInit = createSelector(
    state,
    google => google.places.hasInit,
);
