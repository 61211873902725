import { createAction, props } from '@ngrx/store';
import { IProductRestriction, IProductRestrictionsRequest } from './product-restrictions.interface';

export const ProductRestrictionsRequest = createAction(
    '[Product restrictions] Request',
    props<{ params: IProductRestrictionsRequest; }>(),
);

export const ProductRestrictionsSuccessRequest = createAction(
    '[Product restrictions] Success request',
    props<{ payload: IProductRestriction[]; }>(),
);

export const ProductRestrictionsErrorRequest = createAction(
    '[Product restrictions] Error request'
);
