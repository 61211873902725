import { createSelector, createFeatureSelector } from '@ngrx/store';
import { IStateShared, IMenuFlowImage } from '../interface';

const state = createFeatureSelector<IStateShared, IMenuFlowImage[]>('menuFlowImages');

export const getMenuFlowImagesState = createSelector(
    state,
    menuFlowImages => menuFlowImages
);

export const getImageForMenuFlow = (menuFlowId: number) => createSelector(
    state,
    menuFlowImages => menuFlowImages.find(image => image.MenuFlowId === menuFlowId)
);
