import { Injectable } from '@angular/core';
import { Action, Store, select } from '@ngrx/store';
import { Effect, Actions, ofType } from '@ngrx/effects';

import * as actions from '../actions';
import * as selectors from '../selectors';

import * as Utils from '@shared/core/utils';
import * as Services from '@shared/core/services';

import * as StateModels from '../interface';

import { Observable, of } from 'rxjs';
import { map, catchError, switchMap, withLatestFrom, auditTime } from 'rxjs/operators';

@Injectable()
export class ProductRestrictionsEffects {
    @Effect() public requestRestrictionsForLocation$: Observable<Action> = this._actions$
        .pipe(
            ofType(
                actions.CurrentLocationPickupTimeSet,
                actions.OrderTypesSuccessRequest,
                actions.CartSetup,
            ),
            /* wait for current pickupt time set */
            auditTime(50),
            withLatestFrom(
                this._store
                    .pipe(
                        select(selectors.getCurrentLocationDetails)
                    ),
                this._store
                    .pipe(
                        select(selectors.getCart)
                    ),
                this._store
                    .pipe(
                        select(selectors.getCurrentPickupTime)
                    )
            ),
            switchMap(([action, currentLocation, cart, currentPickupTime]) => {
                if (!currentLocation) return [];

                const pickupTime: string = (action.type === actions.OrderTypesSuccessRequest.type || action.type === actions.CurrentLocationPickupTimeSet.type) ?
                    currentPickupTime?.DateLocalISO : cart?.pickupTime?.DateLocalISO;

                const dateToCheck = Utils.Dates.getLocalISOFormatDate(pickupTime ? Utils.Dates.createDate(pickupTime) : Utils.Dates.createDate(), false);

                const params = {
                    locationNo: currentLocation.LocationNo,
                    dateToCheck,
                };

                return [
                    actions.ProductRestrictionsRequest({ params })
                ];
            })
        );

    @Effect() onRequestProductRestrictions$: Observable<Action> = this._actions$
        .pipe(
            ofType(actions.ProductRestrictionsRequest),
            switchMap(({ params }) => this._productService.getProductRestrictionsForLocation(params)
                .pipe(
                    map(payload => actions.ProductRestrictionsSuccessRequest({ payload })),
                    catchError(ex => of(actions.ProductRestrictionsErrorRequest()))
                )),
        );

    constructor(
        private _actions$: Actions,
        private _productService: Services.ProductsService,
        private _store: Store<StateModels.IStateShared>,
    ) { }
}
