import { createReducer, on, Action } from '@ngrx/store';
import { IModal } from './modal.interface';
import * as actions from './modal.actions';

const initialState: IModal[] = [];


export const modalReducerFn = createReducer(
    initialState,
    on(
        actions.ModalOpen,
        (state, action) => {
            const foundModal = state.find(modal => modal.id === action.modal.id);

            if (foundModal) {
                return state;
            }
            return [
                ...state,
                action.modal
            ];
        }
    ),
    on(
        actions.ModalSwap,
        (state, action) => state.map(modal => {
            if (modal.id === action.modalId) {
                return {
                    ...modal,
                    ...action.details,
                };
            }

            return modal;
        })
    ),
    on(
        actions.ModalClose,
        (state, action) => {
            /* If id is null, close ALL! */
            if (!action.id) {
                return [];
            }

            return state.filter(modal => modal.id !== action.id);
        }
    ),

    on(
        actions.ModalCloseAll,
        (state, action) => state.filter(modal => {
            if (action.typesExcludedFromClosing.includes(modal.type)) {
                return true;
            }
            return false;
        })
    ),
    on(
        actions.ModalAnimate,
        (state, action) => state.map(modal => {
            const idMatchesOrIdNotProvided: boolean = !action.id || modal.id === action.id;

            if (idMatchesOrIdNotProvided) {
                return {
                    ...modal,
                    animate: action.animation,
                };
            }

            return modal;
        })
    ),
    on(
        actions.ModalSetLoading,
        (state, action) => state.map(modal => {
            if (modal.id === action.id) {
                return {
                    ...modal,
                    isLoading: action.isLoading,
                };
            }
            return modal;
        })
    ),
);

export function modalReducer(state: IModal[] | undefined, action: Action) {
    return modalReducerFn(state, action);
}
