import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Store } from '@ngrx/store';

import * as Tokens from '@shared/core/tokens';
import * as State from '@shared/state/interface';

import * as actions from '@shared/state/actions';

import { forkJoin, Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { ILoyaltyAppMessageModel, ILoyaltyAppModel, ILoyaltyProgramAppAssignmentModel, IOrder, IPaginatedListLoyaltyAppIntroductionPageModel } from '@shared/state/interface';
import { LoyaltyAppMapper } from '@shared/core/mappers';

@Injectable({
    providedIn: 'root',
})
export class LoyaltyAppService {
    constructor(
        @Inject(Tokens.CONFIG_TOKEN) public config: IConfig,
        public httpClient: HttpClient,
        public store: Store<State.IStateShared>,
    ) { }

    public apiGetLoyaltyAppInfo(): Observable<ILoyaltyAppModel> {
        return this.httpClient
            .get<APIv3.LoyaltyAppModel>(`${this.config.api.base}/loyaltyapp`)
            .pipe(
                map((response: APIv3.LoyaltyAppModel) => LoyaltyAppMapper.mapGetLoyaltyAppInfo(response))
            );
    }

    public apiGetLoyaltyIntroductionPages(): Observable<IPaginatedListLoyaltyAppIntroductionPageModel> {
        return this.httpClient
            .get<APIv3.LoyaltyAppGetLoyaltyAppIntroductionPages.Responses.$200>(`${this.config.api.base}/loyaltyapp/introductionPages?status=1`)
            .pipe(
                map((response: APIv3.LoyaltyAppGetLoyaltyAppIntroductionPages.Responses.$200) => LoyaltyAppMapper.mapApiGetLoyaltyIntroductionPages(response))
            );
    }

    public apiGetLoyaltyPrograms(): Observable<ILoyaltyProgramAppAssignmentModel[]> {
        return this.httpClient
            .get<APIv3.LoyaltyAppGetLoyaltyPrograms.Responses.$200>(`${this.config.api.base}/loyaltyapp/loyaltyPrograms`)
            .pipe(
                map((response: APIv3.LoyaltyAppGetLoyaltyPrograms.Responses.$200) => LoyaltyAppMapper.mapApiGetLoyaltyPrograms(response))
            );
    }

    public apiGetLoyaltyProductPrograms(): Observable<APICommon.IGetLoyaltyProductProgramBusinessModel[]> {
        return this.httpClient
            .get<APIv3.LoyaltyProgramAppAssignmentModel[]>(`${this.config.api.base}/loyaltyapp/loyaltyPrograms`)
            .pipe(
                switchMap((response: APIv3.LoyaltyProgramAppAssignmentModel[]) => forkJoin(response
                    .map(prod => this.httpClient
                        .get<APIv3.GetLoyaltyProductProgramBusinessModel>(`${this.config.api.base}/LoyaltyProductProgram/${prod.LoyaltyProgramId}`)
                        .pipe(
                            map((loyaltyProgram: APIv3.GetLoyaltyProductProgramBusinessModel) => LoyaltyAppMapper.mapGetLoyaltyProductPrograms(loyaltyProgram))
                        )
                    ))
                )
            );
    }

    public apiGetOrderTypes(locationNo: number): Observable<IOrder[]> {
        return this.httpClient
            .get<APIv3.LoyaltyAppGetLoyaltyAppOrderTypes.Responses.$200>(`${this.config.api.base}/loyaltyapp/orderTypes?locationNo=${locationNo}`)
            .pipe(
                map((response: APIv3.LoyaltyAppGetLoyaltyAppOrderTypes.Responses.$200) => LoyaltyAppMapper.mapApiGetOrderTypes(response))
            );
    }

    public apiGetMessages(): Observable<ILoyaltyAppMessageModel[]> {
        return this.httpClient
            .get<APIv3.LoyaltyAppGetLoyaltyAppMessages.Responses.$200>(`${this.config.api.base}/loyaltyapp/messages`)
            .pipe(
                map((response: APIv3.LoyaltyAppGetLoyaltyAppMessages.Responses.$200) => LoyaltyAppMapper.mapApiGetMessages(response))
            );
    }

    public requestLoyaltyAppInfo(): void {
        this.store.dispatch(actions.AppSettingsRequest());
    }

    public requestLoyaltyIntroductionPages(): void {
        this.store.dispatch(actions.LoyaltyIntroductionPagesRequest());
    }


}
