export class Products {
    public static toSingleItemsByQuantity(items: APICommon.IMenuFlowProduct[]) {
        return items.map((product) => {
            let x = 0;
            let singleItem = [];
            while (x < product.Quantity) {
                singleItem.push({ ...product, Quantity: 1 });
                ++x;
            }

            return singleItem;
        })
            .reduce((prev, curr) => prev.concat(curr), []);
    }

}
