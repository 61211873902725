import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

import { PickupTimeValidateEffects } from './pickup-time-validate.effects';

@NgModule({
    declarations: [],
    imports: [
        CommonModule,
        EffectsModule.forFeature([
            PickupTimeValidateEffects
        ]),
    ],
    exports: [],
    providers: [],
})
export class PickupTimeValidateStateModule { }
