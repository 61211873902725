import { createReducer, on, Action } from '@ngrx/store';
import * as actions from './latest-transactions.actions';
import { ILatestTransaction } from './latest-transactions.interface';

const initialState: ILatestTransaction[] = [];

export const latestTransactionsReducerFn = createReducer<ILatestTransaction[]>(
    initialState,
    on(
        actions.LatestTransactionsReset,
        () => ([])
    ),
    on(
        actions.LatestTransactionsRequest,
        (state, action) => {
            let found: boolean = false;
            const recalculatedState: ILatestTransaction[] = state.reduce((acc, item) => {
                if (item.memberId === action.memberId) {
                    found = true;
                    return [
                        ...acc,
                        {
                            ...item,
                            isDownloading: true,
                            hasSucceeded: false,
                            hasFailed: false,
                        }
                    ];
                }
                return [
                    ...acc,
                    item,
                ];
            }, []);

            return found ? recalculatedState : [
                ...state,
                {
                    memberId: action.memberId,
                    isDownloading: true,
                    hasSucceeded: false,
                    hasFailed: false,
                    downloadedDate: null,
                    data: null,
                }
            ];
        }
    ),
    on(
        actions.LatestTransactionsSuccessRequest,
        (state, action) => state.map(obj => {
            if (obj.memberId === action.memberId) {
                return {
                    ...obj,
                    isDownloading: false,
                    hasSucceeded: true,
                    hasFailed: false,
                    downloadedDate: new Date().getTime(),
                    data: action.payload,
                };
            }

            return obj;
        })
    ),
    on(
        actions.LatestTransactionsErrorRequest,
        (state, action) => state.map(obj => {
            if (obj.memberId === action.memberId) {
                return {
                    ...obj,
                    isDownloading: false,
                    hasSucceeded: false,
                    hasFailed: true,
                };
            }

            return obj;
        })
    ),
);

export function latestTransactionsReducer(state: ILatestTransaction[] | undefined, action: Action) {
    return latestTransactionsReducerFn(state, action);
}
