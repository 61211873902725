import { Inject, Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Store, select } from '@ngrx/store';

import { Controllers } from '@shared/core/controllers';
import * as Services from '@shared/core/services';

import * as Tokens from '@shared/core/tokens';

import { Observable } from 'rxjs';
import { take, map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class AppModeRootGuard implements CanActivate {
    constructor(
        @Inject(Tokens.CONFIG_TOKEN) private _config: IConfig,
        private _router: Router,
        private _controllers: Controllers,
        private _routeService: Services.RouteService,
    ) { }

    public canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean> | Promise<boolean> | boolean {
        switch (true) {
            case this._config.appMode === IAppMode.LOYALTY_ONLY && state.url !== '/loyalty':
                return this._controllers.members.isAuthorized$()
                    .pipe(
                        take(1),
                    )
                    .toPromise()
                    .then(isAuthorized => {
                        if (!isAuthorized) return this._router.navigate(['/loyalty']);

                        return this._routeService.navigateToProfileLoyaltyHome();
                    });

            case this._config.appMode === IAppMode.ORDERING_ONLY && state.url === '/loyalty':
                return this._router.navigate(['/']);

            case state.url === '/loyalty':
                return this._controllers.members.isAuthorized$()
                    .pipe(
                        take(1),
                    )
                    .toPromise()
                    .then(isAuthorized => {
                        if (!isAuthorized) return true;
                        return this._routeService.navigateToProfileLoyaltyHome();
                    });
            default:
                return true;
        }
    }
}
