/* https://github.com/pmiatkowski/angularNgrx/blob/master/src/app/state/effects/currency.effects.ts */
import { Injectable } from '@angular/core';
import { Action } from '@ngrx/store';
import { Effect, Actions, ofType } from '@ngrx/effects';

import * as actions from './locations.actions';
import * as Services from '@shared/core/services';

import { Observable, of } from 'rxjs';
import { mergeMap, catchError, map } from 'rxjs/operators';

@Injectable()
export class LocationsEffects {

    @Effect() requestLocations$: Observable<Action> = this._actions$
        .pipe(
            ofType(actions.LocationsRequest),
            mergeMap(action => this._locationsService.getLocationsWithOnlineOrdering(action.params)
                .pipe(
                    map(payload => {
                        payload.forEach(location => {
                            const isConfgiruedForOrdering = !!location.OrderTypes?.length;
                            if (!isConfgiruedForOrdering) {
                                console.warn(`
                                Location ${location.LocationFriendlyName} has no order type configured.
                                Please configure orderTypes for this specific location to turn it on.
                                `);
                            }
                        });

                        // const size = 10;
                        // payload = payload.map(location => ({
                        //     ...location,
                        //     LocationFriendlyName: new Array(size).fill('dupa', 0, size)
                        //         .join(' '),
                        //     StreetAddress: new Array(size).fill('Ofiar taska 666', 0, size)
                        //         .join(' ')
                        // }));

                        return actions.LocationsSuccessRequest({ params: action.params, payload });
                    }),
                    catchError(ex => of(actions.LocationsErrorRequest(action.params, ex)))
                )),
        );

    constructor(
        private _actions$: Actions,
        private _locationsService: Services.LocationsService,
    ) { }

}
