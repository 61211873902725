/* https://github.com/pmiatkowski/angularNgrx/blob/master/src/app/state/effects/currency.effects.ts */
import { Injectable, Inject } from '@angular/core';
import { Action, Store, select } from '@ngrx/store';
import { Effect, Actions, ofType } from '@ngrx/effects';

import { ModalsService } from '@shared/core/services/modals.shared.service';
import * as Tokens from '@shared/core/tokens';

import * as actions from '../actions';
import * as selectors from '../selectors';
import * as StateModels from '../interface';

import { Observable, of, never } from 'rxjs';
import { switchMap, takeWhile, delay, map, filter, withLatestFrom, auditTime } from 'rxjs/operators';

@Injectable()
export class ModalEffects {
    private _animationTimeout: number = this._config && (this._config.modals || this._config.modals.animateTimeout) ? this._config.modals.animateTimeout : 500;

    @Effect() public closeAllModalsWithExeptionTypes$: Observable<Action> = this._actions$
        .pipe(
            ofType(
                actions.ModalCloseAll,
            ),
            withLatestFrom(
                this._store.pipe(select(selectors.getAllModals)),
            ),
            switchMap(([action, modals]) => {
                return modals.filter(modal => {
                    if (action.typesExcludedFromClosing.includes(modal.type)) {
                        return false;
                    }
                    return true;
                }).map(modal => action.animation === StateModels.MODAL_ANIMATION.OUT ? actions.ModalRequestClose(modal.id) : actions.ModalClose({ id: modal.id }));
            }),
        );

    @Effect({ dispatch: false }) public onModalAnyAction$: Observable<never> = this._actions$
        .pipe(
            ofType(
                actions.ModalOpen,
                actions.ModalRequestClose,
                actions.ModalBackgroundClicked,
                actions.ModalAnimate,
                actions.ModalClose,
            ),
            takeWhile(() => this._config.modals && this._config.modals.preventGlobalScroll),
            auditTime(0),
            withLatestFrom(
                this._store.select(selectors.getAllModals),
                this._modalsSharedService.modalContainer$
            ),
            switchMap(([action, modals, container]) => {
                const isModalOpen: boolean = modals.length > 0;
                const isScrollVisible: boolean = document.body.clientHeight > window.innerHeight;

                if (isModalOpen) {
                    /* When open */
                    /* TODO! FIX TOPBAR tick WHEN OPENING AND CLOSING DUE TO POSITION:FIXED! */
                    if (isScrollVisible && container) {
                        container.nativeElement.style.overflowY = 'scroll';
                        /* If any difference because scrollbar */
                        const bodyWidth: number = document.body.clientWidth;
                        const windowInnerWidth: number = window.innerWidth || window.outerWidth;
                        const diff: number = windowInnerWidth - bodyWidth;

                        if (diff) {
                            document.body.style.paddingRight = `${diff}px`;
                        }

                        document.body.style.overflowY = 'hidden';

                    }

                } else {
                    /* When closed */
                    if (container) {
                        container.nativeElement.style.overflowY = null;
                    }
                    /* IE FIX - AOLO-208 */
                    document.body.removeAttribute('style');
                }

                return never();
            })

        );

    @Effect() public onModalCloseRequest$: Observable<Action> = this._actions$
        .pipe(
            ofType(actions.ModalRequestClose),
            map(action => actions.ModalAnimate(action.modalId, StateModels.MODAL_ANIMATION.OUT)),
        );

    @Effect() public onModalAnimateOut$: Observable<Action> = this._actions$
        .pipe(
            ofType(actions.ModalAnimate),
            filter(action => action.animation === StateModels.MODAL_ANIMATION.OUT),
            delay(this._animationTimeout),
            map(action => actions.ModalClose({ id: action.id }))
        );

    @Effect() public onModalBackgroundClick$: Observable<Action | never> = this._actions$
        .pipe(
            ofType(actions.ModalBackgroundClicked),
            switchMap(action => {

                if (this._config.modals && this._config.modals.bgClickClose) {
                    return of(actions.ModalRequestClose());
                }

                return never();
            })
        );

    constructor(
        @Inject(Tokens.CONFIG_TOKEN) private _config: IConfig,
        private _actions$: Actions,
        private _store: Store<StateModels.IStateShared>,
        private _modalsSharedService: ModalsService,
    ) {
    }

}
