export const isDeclarationCheck = (...params): boolean => {
    return params.length === 3
        && typeof params[0] === 'object'
        && typeof params[1] === 'string'
        && params[2].hasOwnProperty('value')
        && params[2].hasOwnProperty('writable')
        && params[2].hasOwnProperty('configurable');
};

export const readonly = (target, key, descriptor) => {
    descriptor.writable = false;
    return descriptor;
};

export const freeze = (target, key: string, descriptor: PropertyDescriptor): PropertyDescriptor => {
    descriptor.writable = false;
    descriptor.configurable = false;
    return descriptor;
};

export const deprecate = (...params) => {
    const isDeclaration = isDeclarationCheck(...params);

    const func = (target: any, key: string, descriptor: PropertyDescriptor): PropertyDescriptor => {
        if (typeof descriptor.value === 'function') {
            const original = descriptor.value;

            descriptor.value = (...args) => {
                let parent: string = '';

                if (typeof target === 'object' && target.hasOwnProperty('constructor')) {
                    ((Class) => {
                        let className = Class.constructor.toString();
                        className = className.substring('function '.length);
                        className = className.substring(0, className.indexOf('('));

                        parent = className;
                    })(target);
                }

                let text = `Function '${key}' is deprecated`;
                text = parent ? `${text}. Invoked in '${parent}'.` : `${text}.`;
                original.bind(this, args);
            };
        }
        return descriptor;
    };

    return isDeclaration ? func.apply(this, params) : func;
};

export const tryCatch = (...params): PropertyDescriptor => {
    const isDeclaration = isDeclarationCheck(...params);
    const func = (target: any, key: string, descriptor: PropertyDescriptor): PropertyDescriptor => {
        if (typeof descriptor.value === 'function') {
            const original = descriptor.value;

            descriptor.value = (...args) => {
                let parent: string = '';

                if (typeof target === 'object' && target.hasOwnProperty('constructor')) {
                    ((Class) => {
                        let className = Class.constructor.toString();
                        className = className.substring('function '.length);
                        className = className.substring(0, className.indexOf('('));

                        parent = className;
                    })(target);
                }
                try {
                    original.apply(params[0], args);
                } catch (err) {
                    console.error(`---> CheckError in ${parent}.${key}:`, err);
                    // tslint:disable-next-line:no-console
                    console.trace('---> Tracing');
                }

            };
        }
        return descriptor;
    };
    return isDeclaration ? func.apply(this, params) : func;
};

export const helloClass = (...args) => {
    return (Class) => {
        let parent: string = '';
        let className = Class.toString();
        className = className.substring('function '.length);
        className = className.substring(0, className.indexOf('('));

        parent = className;

        console.log(`::: Hello from class '${parent}'`);
        // tslint:disable-next-line:no-unused-new
        new Class(...args);
    };
};

export const logClass = (...args) => {
    return (Class) => {
        // tslint:disable-next-line:no-unused-new
        return Class.bind(null, ...args);
    };
};
