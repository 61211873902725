import { Action, createAction, props } from '@ngrx/store';
import * as State from '@shared/state/interface';

export const AvailablePickupsCalculateRequest = createAction(
    '[Available pickups] Request calculate - waiting for opening hours and openNow status to generate proper list',
    props<{ locationNo: number; }>(),
);

export const AvailablePickupsCalculateSuccessRequest = createAction(
    '[Available pickups] Success calculate - recived opening hours and is open status for location',
    props<{ locationNo: number; payload: OLO.Ordering.IPickupTime[]; }>(),
);

export const AvailablePickupsCalculateErrorRequest = createAction(
    '[Available pickups] Error calculate - error on opening hours or isOpen status for location',
    props<{ locationNo: number; ex?: any; }>(),
);
