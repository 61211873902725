import { createSelector, createFeatureSelector } from '@ngrx/store';
import { IStateShared, IOnlineMenuPagesImages } from '../interface';
import * as Utils from '@shared/core/utils';

const state = createFeatureSelector<IStateShared, IOnlineMenuPagesImages>('onlineMenuPagesImages');

export const getOnlineMenuPagesImages = createSelector(
    state,
    onlineMenuPagesImages => onlineMenuPagesImages
);

export const getOnlineMenuPageImage = (pageId: number, imageType: OLO.Enums.IMAGE_TYPE = OLO.Enums.IMAGE_TYPE.ForWeb) => createSelector(
    state,
    onlineMenuPageImage => {
        let imagesType: string = Utils.Images.toImageTypeString(imageType);

        return onlineMenuPageImage[imagesType].find(obj => obj.pageId === pageId);
    }
);
