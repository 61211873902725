export class PickupModels {
    private get _baseModel() {
        return class PickupScheduleObject {
            public Id: number = null;
            public Index: number = null;
            public Name: string = null;
            public ShortName: string = null;
            public Date: Date = null;
            public MinutesFromNow: number = null;
            public IsAsap: boolean = false;
            public Hour: string = null;
            public DateLocalISO: string = null;
            public PlaceOrderTimeout: Date = null;
            public IsToday: boolean = null;
            public DayNo: number = null;

            constructor(
                model: Partial<OLO.Ordering.IPickupTime> = {}
            ) {
                Object.keys(model).forEach(key => {
                    this[key] = model[key];
                });
            }
        };
    }

    public generate(pickupObjectModel: Partial<OLO.Ordering.IPickupTime> = {}): OLO.Ordering.IPickupTime {
        return new this._baseModel(pickupObjectModel);
    }

    public generateDefaultSchedule(overwrite: Partial<OLO.Ordering.IPickupTime> = {}): OLO.Ordering.IPickupTime {
        return new this._baseModel({
            Id: -1,
            Name: 'Schedule for later',
            ShortName: 'Schedule for later',
            ...overwrite,
        });
    }

    public generateDefaultAsap(overwrite: Partial<OLO.Ordering.IPickupTime> = {}): OLO.Ordering.IPickupTime {
        return new this._baseModel({
            Id: 1,
            Name: `Today ASAP`,
            ShortName: `ASAP`,
            DayNo: 0,
            ...overwrite
        });
    }
}
