
export class Messages {
    public static mapErrors(errors: { [key: string]: any; }): OLO.Components.IMappedMessage[] {
        const keys: string[] = Object.keys(errors);

        return keys.reduce((acc, key) => {
            let error: OLO.Components.IMappedMessage;
            switch (key) {
                case 'minimumOrderValue':
                    error = {
                        message: errors[key],
                        messageType: 'disabled',
                    };
                    break;
                case 'max':
                    error = {
                        message: `Maximum value should be ${errors[key]?.max}`,
                        messageType: 'danger',
                    };
                    break;
                case 'min':
                    error = {
                        message: `Minimum value should be ${errors[key]?.min}`,
                        messageType: 'danger',
                    };
                    break;
                case 'fieldMatchTo':
                    error = {
                        message: errors[key].message,
                        messageType: 'danger',
                    };
                    break;
                case 'previousTransaction':
                    error = {
                        message: 'Please refresh page due to previous transaction details',
                        messageType: 'danger',
                    };
                    break;

                case 'processingPayment':
                    error = {
                        message: 'There was an issue processing payment',
                        messageType: 'danger',
                    };
                    break;

                case 'cardRetry':
                case 'cardChange':
                    error = {
                        message: 'There was an issue with this card',
                        messageType: 'danger',
                    };
                    break;

                case 'required':
                    error = {
                        message: 'This field is required',
                        messageType: 'danger',
                    };
                    break;

                case 'cellPhoneContryError':
                    error = {
                        message: errors[key].message,
                        messageType: 'danger',
                    };
                    break;

                case 'cellPhoneError':
                    error = {
                        message: errors[key].message,
                        messageType: 'danger',
                    };
                    break;

                case 'phoneNotRegistered':
                    error = {
                        message: 'Phone is not verified',
                        messageType: 'danger',
                    };
                    break;

                case 'email':
                    error = {
                        message: 'Email is not correct',
                        messageType: 'danger',
                    };
                    break;

                case 'maxlength':
                    error = {
                        message: `Max. ${errors[key].requiredLength} characters allowed`,
                        messageType: 'danger',
                    };
                    break;

                case 'minlength':
                    error = {
                        message: `Min. ${errors[key].requiredLength} characters`,
                        messageType: 'danger',
                    };
                    break;

                case 'fieldsMatchError':
                    error = {
                        message: 'Values don\'t match',
                        messageType: 'danger',
                    };
                    break;

                case 'phoneTaken':
                    error = {
                        message: 'This phone number has already been taken',
                        messageType: 'warning',
                    };
                    break;

                case 'emailTaken':
                    error = {
                        message: 'This email has already been taken',
                        messageType: 'warning',
                    };
                    break;

                default:
                    error = {
                        message: typeof errors[key] === 'string' ? errors[key] : Messages.parseError(errors, key),
                        messageType: 'danger',
                    };
            }

            return [
                ...acc,
                {
                    key,
                    originalValue: errors[key],
                    ...error
                }
            ];
        }, []);
    }

    public static parseError(errors: {[key: string]: any;}, key: string): {[key: string]: any;} {
        if(!errors[key]) return null;

        try {
            return JSON.parse(JSON.stringify(errors[key]));
        } catch (ex) {
            console.error('ParseError', ex, errors, key);

            return null;
        }
    }
}
