import { createSelector, createFeatureSelector } from '@ngrx/store';
import { IStateShared, IAppSettings } from '../interface';
import * as Utils from '@shared/core/utils';

const state = createFeatureSelector<IStateShared, IAppSettings>('appSettings');

export const getDeviceType = createSelector(
    state,
    appSettings => appSettings.deviceType,
);

export const isDeviceMobile = createSelector(
    state,
    appSettings => appSettings.deviceType === OLO.Enums.DEVICE_TYPE.MOBILE,
);

export const isDeviceDesktop = createSelector(
    state,
    appSettings => appSettings.deviceType === OLO.Enums.DEVICE_TYPE.DESKTOP,
);

export const getAppLocationMode = createSelector(
    state,
    appSettings => appSettings.locationMode,
);

export const isInLocationMode = createSelector(
    state,
    appSettings => appSettings.locationMode === OLO.Enums.APP_MODE.LOCATION
);

export const isInVenueMode = createSelector(
    state,
    appSettings => appSettings.locationMode === OLO.Enums.APP_MODE.VENUE
);

export const hasAppInitialized = createSelector(
    state,
    appSettings => appSettings.hasInitialized
);

export const getAppSettingsLinks = createSelector(
    state,
    appSettings => {
        if (!appSettings.apiSettings.data) return null;
        const a = appSettings.apiSettings.data.AppSettings;
        if (!a) return null;

        const links: IConfigLink[] = [];

        if (a.ContactUsUrl) {
            links.push({
                name: 'Contact Us',
                url: a.ContactUsUrl,
            });
        }

        if (a.UserAgreementUrl) {
            links.push({
                name: 'User Agreement',
                url: a.UserAgreementUrl,
            });
        }

        if (a.PrivacyPolicyUrl) {
            links.push({
                name: 'Privacy Policy',
                url: a.PrivacyPolicyUrl,
            });
        }

        return links;
    }
);

export const getLoyaltyAppSettings = createSelector(
    state,
    appSettings => appSettings.apiSettings
);

export const isOnline = createSelector(
    state,
    appSettings => appSettings.isOnline
);

export const isOnlineMapped = createSelector(
    isOnline,
    online => online ? null : Utils.Messages.mapErrors({ offline: 'You are currently offline' })[0],
);

export const getLoyaltyAppId = createSelector(
    getLoyaltyAppSettings,
    settings => settings.data?.Id || null
);
