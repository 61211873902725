import { createSelector, createFeatureSelector, MemoizedSelector } from '@ngrx/store';
import { IStateShared, ICurrentLocation } from '../interface';

const state = createFeatureSelector<IStateShared, ICurrentLocation>('currentLocation');

export const getCurrentLocationNo = createSelector(
    state,
    currentLocation => currentLocation && currentLocation.locationNo || undefined
);

export const getCurrentPickupTime = createSelector(
    state,
    currentLocation => currentLocation && currentLocation.pickupTime || undefined
);

export const getCurrentLocationValidationState = createSelector(
    state,
    currentLocation => currentLocation.validation
);

export const currentLocationIsValid = createSelector(
    state,
    currentLocation => currentLocation.validation ? currentLocation.validation.hasSucceeded : false
);

export const currentLocationIsValidating = createSelector(
    state,
    currentLocation => currentLocation.validation ? currentLocation.validation.isValidating : false
);
