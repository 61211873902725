import { ILoyaltyAppModel } from '@shared/state';

export class JWTMapper {
    public static mapReqestJWToken(login: OLO.Authorization.IJWTokenRequest): { Email: string; Password: string; } {
        return login ? {
            Email: login.Email,
            Password: login.Password,
        } : null;
    }

    public static mapPostJWTokenResponse(token: APIv3.JwtTokenResponse): OLO.Authorization.IJWTokenObject {
        return token ? {
            AccessToken: token.AccessToken,
            RefreshToken: token.RefreshToken,
        } : null;
    }
}
