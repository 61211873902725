import { Injectable, Inject } from '@angular/core';
import { Store, Action, select } from '@ngrx/store';
import { Actions, ofType, Effect } from '@ngrx/effects';

import * as actions from '../actions';
import * as selectors from '../selectors';

import * as StateModels from '../interface';

import * as Services from '@shared/core/services';
import * as Tokens from '@shared/core/tokens';
import * as Utils from '@shared/core/utils';

import { Observable, of } from 'rxjs';
import { switchMap, map, mergeMap, catchError, withLatestFrom, filter } from 'rxjs/operators';

@Injectable()
export class OrderTypesEffects {
    @Effect() public onCollectionTypeChangeSelectDefaultOrderTypeForSelectedCollectionTypeGroup$: Observable<Action> = this._actions$
        .pipe(
            ofType(
                actions.SetCollectionType
            ),
            withLatestFrom(
                this._store
                    .pipe(
                        select(selectors.getFilteredOrderTypesForCartsLocationByGroup(this._config))
                    ),
                this._store
                    .pipe(
                        select(selectors.getSelectedOrderType)
                    ),
                this._store
                    .pipe(
                        select(selectors.isCheckoutPage)
                    ),
            ),
            filter(([action, orderTypes, selectedOrderType, isCheckoutPage]) => isCheckoutPage === true),
            switchMap(([action, orderTypes, selectedOrderType, isCheckoutPage]) => {
                const collectionType: OLO.Enums.COLLECTION_TYPE = new Utils.CollectionTypeGroupDetector(action.orderTypeId, this._config).getCollectionType();
                const orderType: OLO.Enums.COLLECTION_TYPE = new Utils.CollectionTypeGroupDetector(selectedOrderType?.Id || null, this._config).getCollectionType();

                const firstAvailableDefaultOrderType = orderTypes.find(obj => {
                    const orderTypeCollectionGroupId = new Utils.CollectionTypeGroupDetector(obj.Id, this._config).getCollectionType();

                    return orderTypeCollectionGroupId === collectionType;
                });
                if (collectionType !== orderType && firstAvailableDefaultOrderType) {
                    return of(
                        actions.OnlineOrderTypeSelect({ orderType: firstAvailableDefaultOrderType })
                    );
                }

                return [];
            })
        );

    @Effect() public requestOrderTypeForLocation$: Observable<Action> = this._actions$
        .pipe(
            ofType(
                actions.CurrentLocationSet,
                actions.CartSetup,
            ),
            withLatestFrom(
                this._store
                    .pipe(
                        select(selectors.getOrderTypesForAllLocations)
                    )
            ),
            switchMap(([action, orderTypes]) => {
                const foundDownloaded = orderTypes.find(obj => obj.locationNo === action.locationNo && obj.hasSucceeded === true);
                if (foundDownloaded) {
                    return [];
                }

                return [
                    actions.OrderTypesRequest({ locationNo: action.locationNo }),
                ];
            })
        );

    @Effect() public requestOrderTypes$: Observable<Action> = this._actions$
        .pipe(
            ofType(
                actions.OrderTypesRequest
            ),
            mergeMap(({ locationNo }) => this._loylatyAppService.apiGetOrderTypes(locationNo)
                .pipe(
                    map(payload => actions.OrderTypesSuccessRequest({ locationNo, payload })),
                    catchError(ex => {
                        console.error('OrderTypes request error', ex);

                        return of(actions.OrderTypesErrorRequest({ locationNo, ex }));
                    })
                )
            )
        );

    @Effect() public resetOnlineOrderTypeOnRequest$: Observable<Action> = this._actions$
        .pipe(
            ofType(
                actions.CurrentLocationSet,
                actions.CartSetup,
                actions.OrderTypesRequest,
            ),
            switchMap(() => [
                actions.OnlineOrderTypeSelect({ orderType: null })
            ])
        );

    constructor(
        @Inject(Tokens.CONFIG_TOKEN) private _config: IConfig,
        private _actions$: Actions,
        private _store: Store<StateModels.IStateShared>,
        private _loylatyAppService: Services.LoyaltyAppService,
    ) { }
}
