import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { ServiceWorkerModule } from '@angular/service-worker';

import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { StoreRouterConnectingModule } from '@ngrx/router-store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';


import { ROOT_REDUCERS } from './state/state.reducers';
import { stateEffects } from './state/state.effects';
import { stateMetaReducers } from './state/state.meta-reducers';
import { StateModule } from './state/state.module';

import { RootRoutesModule } from './routes-root.module';
import { SharedProvidersModule } from '@shared/shared-providers.module';

import { AppComponent } from './app.component';

import { CONFIG } from './config';
import { environment } from '../environments/environment';
import { buildEnvironment } from '../environments/environment.build';

@NgModule({
    declarations: [
        AppComponent
    ],
    imports: [
        BrowserAnimationsModule,
        RootRoutesModule,
        ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
        /* NGRX config */
        StoreModule.forRoot(ROOT_REDUCERS, {
            metaReducers: [
                ...stateMetaReducers
            ],
            runtimeChecks: {
                strictStateImmutability: false,
                strictActionImmutability: false, /* Throws an error from meta.decorator */
                strictStateSerializability: false,
                strictActionSerializability: false, /* Thorw an error from action */
            },
        }),
        EffectsModule.forRoot([
            ...stateEffects
        ]),
        StoreRouterConnectingModule.forRoot({}),
        StateModule,
        /* NGRX config end */

        SharedProvidersModule.forRoot({
            data: CONFIG,
            environment,
            buildEnvironment
        }),
        StoreDevtoolsModule.instrument()
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
