import { ILoyaltyAppMessageModel, ILoyaltyAppModel, ILoyaltyProgramAppAssignmentModel, IOrder, IPaginatedListLoyaltyAppIntroductionPageModel } from '@shared/state';

export class LoyaltyAppMapper {
    public static mapGetLoyaltyAppInfo(loyaltyAppInfo: APIv3.LoyaltyAppModel): ILoyaltyAppModel {
        return loyaltyAppInfo ? {
            Id: loyaltyAppInfo.Id,
            AppName: loyaltyAppInfo.AppName,
            LastChangedDate: loyaltyAppInfo.LastChangedDate,
            AppType: loyaltyAppInfo.AppType,
            AppSettings: loyaltyAppInfo.AppSettings ? {
                Id: loyaltyAppInfo.AppSettings.Id,
                ShowIntroductionPages: loyaltyAppInfo.AppSettings.ShowIntroductionPages,
                LastChangeDate: loyaltyAppInfo.AppSettings.LastChangeDate,
                IntroductionPagesRefreshDate: loyaltyAppInfo.AppSettings.IntroductionPagesRefreshDate,
                DefaultConvergeSettings: loyaltyAppInfo.AppSettings.DefaultConvergeSettings ? {
                    ApiUrl: loyaltyAppInfo.AppSettings.DefaultConvergeSettings.ApiUrl,
                    MechantId: loyaltyAppInfo.AppSettings.DefaultConvergeSettings.MechantId,
                    UserId: loyaltyAppInfo.AppSettings.DefaultConvergeSettings.UserId,
                    Pin: loyaltyAppInfo.AppSettings.DefaultConvergeSettings.Pin,
                    VendorId: loyaltyAppInfo.AppSettings.DefaultConvergeSettings.VendorId,
                    SessionToken: loyaltyAppInfo.AppSettings.DefaultConvergeSettings.SessionToken ? {
                        SessionToken: loyaltyAppInfo.AppSettings.DefaultConvergeSettings.SessionToken.SessionToken
                    } : null,
                } : null,
                DefaultCardConnectSettings: loyaltyAppInfo.AppSettings.DefaultCardConnectSettings ? {
                    ApiUrl: loyaltyAppInfo.AppSettings.DefaultCardConnectSettings.ApiUrl,
                    MerchantID: loyaltyAppInfo.AppSettings.DefaultCardConnectSettings.MerchantID,
                } : null,
                DefaultPaymentExpressSettings: loyaltyAppInfo.AppSettings.DefaultPaymentExpressSettings ? {
                    Url: loyaltyAppInfo.AppSettings.DefaultPaymentExpressSettings.Url,
                    SessionToken: loyaltyAppInfo.AppSettings.DefaultPaymentExpressSettings.SessionToken,
                } : null,
                DefaultFatZebraSettings: loyaltyAppInfo.AppSettings.DefaultFatZebraSettings ? {
                    DirectPostUrl: loyaltyAppInfo.AppSettings.DefaultFatZebraSettings.DirectPostUrl,
                    ReturnPath: loyaltyAppInfo.AppSettings.DefaultFatZebraSettings.ReturnPath,
                    Verification: loyaltyAppInfo.AppSettings.DefaultFatZebraSettings.Verification,
                } : null,
                DefaultAdyenSettings: loyaltyAppInfo.AppSettings.DefaultAdyenSettings ? {
                    CountryCode: loyaltyAppInfo.AppSettings.DefaultAdyenSettings.CountryCode,
                    Currency: loyaltyAppInfo.AppSettings.DefaultAdyenSettings.Currency,
                    ClientKey: loyaltyAppInfo.AppSettings.DefaultAdyenSettings.ClientKey,
                    Environment: loyaltyAppInfo.AppSettings.DefaultAdyenSettings.Environment,
                } : null,
                PaymentExpressTokenizationSuccessCalbackUrl: loyaltyAppInfo.AppSettings.PaymentExpressTokenizationSuccessCalbackUrl,
                PaymentExpressTokenizationErrorCalbackUrl: loyaltyAppInfo.AppSettings.PaymentExpressTokenizationErrorCalbackUrl,
                ContactUsUrl: loyaltyAppInfo.AppSettings.ContactUsUrl,
                UserAgreementUrl: loyaltyAppInfo.AppSettings.UserAgreementUrl,
                PrivacyPolicyUrl: loyaltyAppInfo.AppSettings.PrivacyPolicyUrl,
                TermsAndConditionsUrl: loyaltyAppInfo.AppSettings.TermsAndConditionsUrl,
                GoogleMapsApiKey: loyaltyAppInfo.AppSettings.GoogleMapsApiKey,
            } : null,
            SocialMediaSettings: loyaltyAppInfo.SocialMediaSettings ?
                loyaltyAppInfo.SocialMediaSettings.map((mediaSettings: APIv3.LoyaltyAppSocialMediaSettingsModel) => ({
                    Id: mediaSettings.Id,
                    AppKey: mediaSettings.AppKey,
                    Name: mediaSettings.Name,
                    SocialMediaType: mediaSettings.SocialMediaType,
                })) : null,
            RedemptionRate: loyaltyAppInfo.RedemptionRate,
            MinimumiOSVersion: loyaltyAppInfo.MinimumiOSVersion,
            MinimumDroidVersion: loyaltyAppInfo.MinimumDroidVersion
        } : null;
    }

    public static mapGetLoyaltyProductPrograms(loyaltyProductPrograms: APIv3.GetLoyaltyProductProgramBusinessModel): APICommon.IGetLoyaltyProductProgramBusinessModel {
        return loyaltyProductPrograms ? {
            Id: loyaltyProductPrograms.Id,
            ProgramName: loyaltyProductPrograms.ProgramName,
            StartDate: loyaltyProductPrograms.StartDate,
            EndDate: loyaltyProductPrograms.EndDate,
            ProductId: loyaltyProductPrograms.ProductId,
            DaysValid: loyaltyProductPrograms.DaysValid,
            PurchaseQuantity: loyaltyProductPrograms.PurchaseQuantity,
            ProductQauantityToIssue: loyaltyProductPrograms.ProductQauantityToIssue,
            ProductDescription: loyaltyProductPrograms.ProductDescription,
            PLU: loyaltyProductPrograms.PLU,
        } : null;
    }

    public static mapApiGetLoyaltyIntroductionPages(loyaltyIntroduction: APIv3.LoyaltyAppGetLoyaltyAppIntroductionPages.Responses.$200):
    IPaginatedListLoyaltyAppIntroductionPageModel {
        return loyaltyIntroduction ? {
            PageNo: loyaltyIntroduction.PageNo,
            PageSize: loyaltyIntroduction.PageSize,
            Items: loyaltyIntroduction.Items ?
                loyaltyIntroduction.Items.map((item: APIv3.LoyaltyAppIntroductionPageModel) => ({
                    Id: item.Id,
                    AppId: item.AppId,
                    Title: item.Title,
                    Text: item.Text,
                    AnimationMsInterval: item.AnimationMsInterval,
                    LastChangeDate: item.LastChangeDate,
                    Status: item.Status,
                    DisplayIndex: item.DisplayIndex,
                })) : null
        } : null;
    }

    public static mapApiGetLoyaltyPrograms(loyaltyPrograms: APIv3.LoyaltyAppGetLoyaltyPrograms.Responses.$200): ILoyaltyProgramAppAssignmentModel[] {
        return loyaltyPrograms ? loyaltyPrograms.map((item: APIv3.LoyaltyProgramAppAssignmentModel) => ({
            Id: item.Id,
            LoyaltyAppId: item.LoyaltyAppId,
            LoyaltyProgramId: item.LoyaltyProgramId,
            Type: item.Type,
            DisplayOrder: item.DisplayOrder,
        })) : null;
    }

    public static mapApiGetOrderTypes(loyaltyPrograms: APIv3.LoyaltyAppGetLoyaltyAppOrderTypes.Responses.$200): IOrder[] {
        if (!loyaltyPrograms) return null;

        const mappedPrograms: IOrder[] = loyaltyPrograms.map((item: APIv3.OrderType) => ({
            Id: item.Id,
            Description: item.Description,
            Instructions: item.Instructions,
            Details: item.Details ?
                item.Details.map((detailDefinition: APIv3.OrderTypeDetailDefinition) => ({
                    Id: detailDefinition.Id,
                    OrderTypeId: detailDefinition.OrderTypeId,
                    CustomerFriendlyName: detailDefinition.CustomerFriendlyName,
                    CustomerFriendlyDescription: detailDefinition.CustomerFriendlyDescription,
                    DataType: detailDefinition.DataType,
                    IsRequired: detailDefinition.IsRequired,
                    DisplayIndex: detailDefinition.DisplayIndex,
                })) : null,
            MinimumOrderValueName: item.MinimumOrderValueName,
            MinimumOrderValueDescription: item.MinimumOrderValueDescription,
            MinimumOrderValue: item.MinimumOrderValue,
            Surcharges: item.Surcharges ?
                item.Surcharges.map((surcharges: APIv3.OrderSurchargeDefinition) => ({
                    Id: surcharges.Id,
                    CustomerFriendlyName: surcharges.CustomerFriendlyName,
                    CustomerFriendlyDescription: surcharges.CustomerFriendlyDescription,
                    DisplayIndex: surcharges.DisplayIndex,
                    IsVisibleForCustomer: surcharges.IsVisibleForCustomer,
                    ProductId: surcharges.ProductId,
                    PLU: surcharges.PLU,
                    Value: surcharges.Value,
                })) : null,
            IsEnabledForFutureOrdering: item.IsEnabledForFutureOrdering,
            Disclaimers: item.Disclaimers ?
                item.Disclaimers.map((disclaimers: APIv3.OrderDisclaimerDefinition) => ({
                    Id: disclaimers.Id,
                    CustomerFriendlyName: disclaimers.CustomerFriendlyName,
                    CustomerFriendlyDescription: disclaimers.CustomerFriendlyDescription,
                    IsRequired: disclaimers.IsRequired,
                    DisplayIndex: disclaimers.DisplayIndex,
                })) : null,
            IsProductsRestrictionEnabled: item.IsProductsRestrictionEnabled,
        }));

        mappedPrograms.forEach((order: IOrder) => ({
            Details: order.Details ? order.Details
                .sort((a, b) => (isNaN(a.DisplayIndex) ? a.Id : a.DisplayIndex) - (isNaN(b.DisplayIndex) ? b.Id : b.DisplayIndex))
                : order.Details,
        }));

        return mappedPrograms;
    }

    public static mapApiGetMessages(messages: APIv3.LoyaltyAppGetLoyaltyAppMessages.Responses.$200): ILoyaltyAppMessageModel[] {
        if (!messages) return null;

        const mappedMessages: ILoyaltyAppMessageModel[] = messages.map((item: APIv3.LoyaltyAppMessageModel) => ({
            Id: item.Id,
            DisplayOrder: item.DisplayOrder,
            Title: item.Title,
            Text: item.Text,
            Url: item.Url,
            UrlDescrption: item.UrlDescrption,
            UrlPlacement: item.UrlPlacement,
        }));

        return mappedMessages.sort((a, b) => a.DisplayOrder - b.DisplayOrder);
    }
}
