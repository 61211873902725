import { createReducer, on, Action } from '@ngrx/store';
import { ICurrentLocation } from './current-location.interface';
import * as actions from './current-location.actions';

const initialState: ICurrentLocation = {
    locationNo: null,
    pickupTime: null,
    validation: {
        isValidating: false,
        hasSucceeded: false,
        hasFailed: false,
    },
};

export const currentLocationReducerFn = createReducer(
    initialState,
    on(
        actions.CurrentLocationValidationRequest,
        (state, action) => ({
            ...state,
            validation: {
                isValidating: true,
                hasSucceeded: false,
                hasFailed: false,
            }
        })
    ),
    on(
        actions.CurrentLocationValidationSuccessRequest,
        (state, action) => ({
            ...state,
            validation: {
                isValidating: false,
                hasSucceeded: true,
                hasFailed: false,
            }
        })
    ),
    on(
        actions.CurrentLocationValidationErrorRequest,
        (state, action) => ({
            ...state,
            validation: {
                isValidating: false,
                hasSucceeded: false,
                hasFailed: true,
            }
        })
    ),
    on(
        actions.CurrentLocationValidationReset,
        (state, action) => ({
            ...state,
            validation: {
                isValidating: false,
                hasSucceeded: false,
                hasFailed: false,
            }
        })
    ),
    on(
        actions.CurrentLocationReset,
        (state, action) => ({
            locationNo: null,
            pickupTime: null,
            validation: {
                isValidating: false,
                hasSucceeded: false,
                hasFailed: false,
            },
        })
    ),
    on(
        actions.CurrentLocationPickupTimeRestore,
        actions.CurrentLocationPickupTimeSet,
        actions.CurrentLocationFiltersPickupTimeSync,
        (state, action) => {
            // console.trace('SET PICKUP TIME', action);
            return ({
                ...state,
                pickupTime: action.pickupTime
            });
        }
    ),
    on(
        actions.CurrentLocationSet,
        (state, action) => ({
            ...state,
            locationNo: action.locationNo,
        })
    )
);

// export function currentLocationReducerX(state: ICurrentLocation = initialState, action:
//     actions.CurrentLocationPickupTimeSet |
//     actions.CurrentLocationFiltersPickupTimeSync |
//     actions.CurrentLocationPickupTimeRestore |
//     actions.CurrentLocationSet |
//     actions.CurrentLocationReset
// ): ICurrentLocation {
//     switch (action.type) {
//         case actions.CURRENT_RESET:
//             return {
//                 locationNo: null,
//                 pickupTime: null,
//             };

//         case actions.CURRENT_LOCATION_PICKUP_TIME_RESTORE:
//         case actions.CURRENT_LOCATION_PICKUP_TIME_SET:
//         case actions.CURRENT_LOCATION_FILTERS_PICKUP_TIME_SYNC:
//             return {
//                 ...state,
//                 pickupTime: (action as actions.CurrentLocationPickupTimeSet).pickupTime
//             }

//         case actions.CURRENT_LOCATION_SET:
//             return {
//                 ...state,
//                 locationNo: (action as actions.CurrentLocationSet).locationNo
//             };

//         default:
//             return state;
//     }
// }

export function currentLocationReducer(state: ICurrentLocation | undefined, action: Action) {
    return currentLocationReducerFn(state, action);
}
