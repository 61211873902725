import { ILoyaltyAppModel, IMenuFlowDefaultActivation, IMenuFlowDetailsModel, IMenuFlowPage } from '@shared/state';
import * as Utils from '@shared/core/utils';

export class MenuFlowsMapper {
    public static mapGetMenuFlowDetailsForLocation(menuFlowDetails: APIv3.MenuFlowDetailsModel): IMenuFlowDetailsModel {
        if (!menuFlowDetails) return null;

        const mapedMenuFlowDetails: IMenuFlowDetailsModel = {
            MenuFlowId: menuFlowDetails.MenuFlowId,
            MenuFlowName: menuFlowDetails.MenuFlowName,
            OverridePrice: menuFlowDetails.OverridePrice,
            IsInPromotion: menuFlowDetails.IsInPromotion,
            IsActive: menuFlowDetails.IsActive,
            MenuFlowDescription: menuFlowDetails.MenuFlowDescription,
            Pages: menuFlowDetails.Pages ?
                menuFlowDetails.Pages.map((page: APIv3.MenuFlowPage) => ({
                    PageIdentifier: page.PageIdentifier,
                    PageNo: page.PageNo,
                    PageName: page.PageName,
                    PageDescription: page.PageDescription,
                    PageMode: page.PageMode,
                    PageMinQuantity: page.PageMinQuantity,
                    PageMaxQuantity: page.PageMaxQuantity,
                    SpecialPriceQuantity: page.SpecialPriceQuantity,
                    SpecialPrice: page.SpecialPrice,
                    SpecialPriceSelection: page.SpecialPriceSelection,
                    PriceChangeMode: page.PriceChangeMode,
                    PriceChangeValue: page.PriceChangeValue,
                    IsUpsell: page.IsUpsell,
                    UpsellText: page.UpsellText,
                    HideFromKiosk: page.HideFromKiosk,
                    AutoAddItems: page.AutoAddItems,
                    Products: page.Products ?
                        page.Products.map((product: APIv3.MenuFlowProduct) => ({
                            PageProductIdentifier: product.PageProductIdentifier,
                            MenuFlowID: product.MenuFlowID,
                            ProductId: product.ProductId,
                            ProductCategoryId: product.ProductCategoryId,
                            ProductFamilyIds: product.ProductFamilyIds,
                            ProductName: product.ProductName,
                            OriginalPrice: product.OriginalPrice,
                            PriceSetupPriceLevelID: product.PriceSetupPriceLevelID,
                            OverridedPrice: product.OverridedPrice,
                            ExcludeFromSpecialPricing: product.ExcludeFromSpecialPricing,
                            LinksToPage: product.LinksToPage,
                            Quantity: product.Quantity,
                            PageNo: product.PageNo,
                            SchdeduleId: product.SchdeduleId,
                            TerminalsAssignedToSchedule: product.TerminalsAssignedToSchedule,
                            Plu: product.Plu,
                            ProductDescription: product.ProductDescription,
                            IsInstruction: product.IsInstruction,
                            DisplayIndex: product.DisplayIndex,
                            BackgroundColor: product.BackgroundColor,
                            MinimumQuantity: product.MinimumQuantity,
                            MaximumQuantity: product.MaximumQuantity,
                            KioskAutoAddQty: product.KioskAutoAddQty,
                            StockAmount: product.StockAmount,
                            State: product.State,
                            HasIngredients: product.HasIngredients,
                            HasModifiers: product.HasModifiers,
                        })) : null,
                    UniversalTags: page.UniversalTags ?
                        page.UniversalTags.map((tag: APIv3.TagModel) => ({
                            Id: tag.Id,
                            Name: tag.Name,
                        })) : null,
                })) : null,
            UpsellMenuFlowId: menuFlowDetails.UpsellMenuFlowId,
            MenuFlowNotes: menuFlowDetails.MenuFlowNotes,
            StockAmount: menuFlowDetails.StockAmount,
            State: menuFlowDetails.State,
            Kilojoules: menuFlowDetails.Kilojoules,
            DietaryTags: menuFlowDetails.DietaryTags ?
                menuFlowDetails.DietaryTags.map((tag: APIv3.TagModel) => ({
                    Id: tag.Id,
                    Name: tag.Name,
                })) : null,
            CustomerFriendlyName: menuFlowDetails.CustomerFriendlyName,
            CustomerFriendlyDescription: menuFlowDetails.CustomerFriendlyDescription,
        };

        mapedMenuFlowDetails.Pages = mapedMenuFlowDetails.Pages.reduce((acc, Page) => {
            if (Page.PageMode !== 0) return acc;

            Page.Products = Page.Products.map(product => {
                if (product.BackgroundColor && typeof product.BackgroundColor === 'string') {
                    const match = product.BackgroundColor.match(/^#[a-f0-9]{8}$/i);
                    if (match) {
                        return {
                            ...product,
                            BackgroundColor: Utils.Colors.hexToRGBA(product.BackgroundColor),
                        };
                    }

                    return product;

                }

                return product;
            });
            Page.Products = Page.Products.sort((a, b) => a.DisplayIndex - b.DisplayIndex);

            return [
                ...acc,
                Page,
            ];
        }, [] as IMenuFlowPage[]);

        return mapedMenuFlowDetails;
    }

    public static mapGetDefaultActivation(defaultActivation: APIv3.MenuFlowDefaultActivation): IMenuFlowDefaultActivation {
        return defaultActivation ? {
            MenuFlowId: defaultActivation.MenuFlowId,
            MenuFlowItems: defaultActivation.MenuFlowItems ?
                defaultActivation.MenuFlowItems.map((item: APIv3.MenuFlowDefaultProduct) => ({
                    MenuFlowPageId: item.MenuFlowPageId,
                    PLU: item.PLU,
                    Quantity: item.Quantity,
                })) : null,
        } : null;
    }
}
