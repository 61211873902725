import { createSelector, createFeatureSelector } from '@ngrx/store';
import { IStateShared, IPayment } from '../interface';
import * as Utils from '@shared/core/utils';

const state = createFeatureSelector<IStateShared, IPayment>('payment');

export const getPaymentState = createSelector(
    state,
    payment => payment
);

export const paymentHasErrors = createSelector(
    state,
    payment => payment.Errors.length > 0
);

export const getPaymentStepsStatus = createSelector(
    state,
    payment => payment.PaymentStepStatus
);

export const getPaymentStatus = createSelector(
    state,
    payment => {
        const p = payment;
        if (p.hasFailed === true) return 'failed';

        if (p.isPaying) {
            return 'pending';
        }

        if (p.hasSucceeded) {
            return 'success';
        }

        if (p.hasFailed) {
            if (p.data && p.data.Status === OLO.Enums.PAYMENT_STATUS.FAILED) return 'declined';

            return 'failed';
        }

        return null;

    }
);

export const isPaying = createSelector(
    state,
    payment => {
        const s = payment.PaymentStepStatus;
        return s !== null && s !== 'failed' && s !== 'complete';
    }
);

export const isPaymentComplete = createSelector(
    state,
    payment => payment.PaymentStepStatus === 'complete'
);

export const getPaymentErrors = createSelector(
    state,
    payment => payment.Errors
);

export const isPaymentDisabledForPayments = createSelector(
    getPaymentStatus,
    isPaying,
    (paymentStatus, isPayingFlag) => {
        return (paymentStatus === 'pending' || paymentStatus === 'success') ||
            isPayingFlag;
    }
);

export const getPaymentErrorsMapped = createSelector(
    getPaymentErrors,
    getPaymentState,
    (errors, payment) => {
        if (payment.hasSucceeded || payment.PaymentStepStatus === 'complete' || payment.data !== null && payment.data.Status === OLO.Enums.PAYMENT_STATUS.SUCCESS) {
            return Utils.Messages.mapErrors({ previousTransaction: 'Previous transaction available' })[0];
        }

        if (!errors.length) return null;
        /* Currently we handle only one error */
        return Utils.Messages.mapErrors({ processingPayment: `We're having trouble processing payment. Please try again` })[0];
    }
);
