import { createSelector, createFeatureSelector } from '@ngrx/store';
import { IStateShared, IIngredient } from '../interface';

const state = createFeatureSelector<IStateShared, IIngredient[]>('ingredients');

export const getIngredientsNotMatchingParams = (locationNo: number, ...productIds: number[]) => createSelector(
    state,
    ingredients => ingredients.filter(ingredient => !(ingredient.LocationNo === locationNo && productIds.includes(ingredient.ProductId) === true))
);

export const getIngredients = createSelector(
    state,
    ingredients => ingredients
);

export const getIngredientsForProduct = (productId: number, locationNo: number) => createSelector(
    state,
    ingredients => ingredients.find(ingredient => ingredient.ProductId === productId && ingredient.LocationNo === locationNo)
);

export const getIngredientsByLocationNo = (locationNo: number) => createSelector(
    state,
    ingredients => ingredients.filter(obj => obj.LocationNo === locationNo)
);

export const getIngredientsForProducts = (locationNo: number, ...productIds: number[]) => createSelector(
    state,
    ingredients => ingredients.filter(ingredient => ingredient.LocationNo === locationNo && productIds.includes(ingredient.ProductId))
);

export const isDownloadingAnyIngredientModifiers = createSelector(
    state,
    ingredients => ingredients.some(obj => obj.isDownloading)
);
