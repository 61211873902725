import { createAction, props } from '@ngrx/store';

export const OnlineMenuPagesImagesRequest = createAction(
    '[Online menu pages images] Request images for online menu page',
    props<{ params: APICommon.IImagesGetParams; imageType: OLO.Enums.IMAGE_TYPE; pageIds: number[]; }>(),
);

export const OnlineMenuPagesImagesSuccessRequest = createAction(
    '[Online menu pages images] Success request images for online menu page',
    props<{ pageIds: number[]; imageType: OLO.Enums.IMAGE_TYPE; payload: APICommon.IImageUrlModel[]; }>(),
);

export const OnlineMenuPagesImagesErrorRequest = createAction(
    '[Online menu pages images] Error request images for online menu page',
    props<{ pageIds: number[]; imageType: OLO.Enums.IMAGE_TYPE; ex?: any; }>(),
);
