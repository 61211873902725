import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { DevConsoleModule } from './devConsole';

import { DevButtonComponent } from './dev-button.shared.component';
import { DevComponent } from './dev.shared.component';


@NgModule({
    declarations: [
        DevButtonComponent,
        DevComponent,
    ],
    imports: [
        CommonModule,
        FormsModule,
        DevConsoleModule,
    ],
    exports: [
        DevConsoleModule,
        DevButtonComponent,
        DevComponent,
    ],
    providers: [],
})
export class DevModule { }
