import { createReducer, on, Action } from '@ngrx/store';
import * as actions from './loyalty-introduction-pages-images.actions';
import { ILoyaltyIntroductionPageImage } from './loyalty-introduction-pages-images.interface';

const initialState: ILoyaltyIntroductionPageImage[] = [];

export const loyaltyIntroductionPagesImagesReducerFn = createReducer<ILoyaltyIntroductionPageImage[]>(
    initialState,
    on(
        actions.LoyaltyIntroductionPageImageRequest,
        (state, action) => {
            let found: boolean = false;
            const recalculatedState: ILoyaltyIntroductionPageImage[] = state.reduce((acc, item) => {
                if (item.PageId === action.pageId) {
                    found = true;
                    return [
                        ...acc,
                        {
                            ...item,
                            isDownloading: true,
                            hasSucceeded: false,
                            hasFailed: false,
                        }
                    ];
                }
                return [
                    ...acc,
                    item,
                ];
            }, []);

            return found ? recalculatedState : [
                ...state,
                {
                    PageId: action.pageId,
                    isDownloading: true,
                    hasSucceeded: false,
                    hasFailed: false,
                    downloadedDate: null,
                    data: null,
                }
            ];
        }
    ),
    on(
        actions.LoyaltyIntroductionPageImageSuccessRequest,
        (state, action) => state.map(obj => {
            if (obj.PageId === action.pageId) {
                return {
                    ...obj,
                    isDownloading: false,
                    hasSucceeded: true,
                    hasFailed: false,
                    downloadedDate: new Date().getTime(),
                    data: action.payload || null,
                };
            }

            return obj;
        })
    ),
    on(
        actions.LoyaltyIntroductionPageImageErrorRequest,
        (state, action) => state.map(obj => {
            if (obj.PageId === action.pageId) {
                return {
                    ...obj,
                    isDownloading: false,
                    hasSucceeded: false,
                    hasFailed: true,
                };
            }

            return obj;
        })
    ),
);

export function loyaltyIntroductionPagesImagesReducer(state: ILoyaltyIntroductionPageImage[] | undefined, action: Action) {
    return loyaltyIntroductionPagesImagesReducerFn(state, action);
}
