import { map } from 'rxjs/operators';
import * as Utils from '@shared/core/utils';
import { IMemberFavouriteItemAdditionModel } from '@shared/state';

export class LocationsMapper {
    public static mapGetLocationsWithOnlineOrdering(locationsWithOnlineOrdering: APIv3.TerminalsGetOnlineOrderingLocations.Responses.$200):
    APICommon.IOnlineOrderingLocationBusinessModel[] {
        return locationsWithOnlineOrdering ? locationsWithOnlineOrdering?.map((location: APIv3.OnlineOrderingLocationBusinessModel) => ({
            LocationNo: location.LocationNo,
            VenueNo: location.VenueNo,
            LocationDescription: location.LocationDescription,
            StreetAddress: location.StreetAddress,
            LocationOLOIsActive: location.LocationOLOIsActive,
            EOIPriceLevelID: location.EOIPriceLevelID,
            LocationCode: location.LocationCode,
            LocationEmail: location.LocationEmail,
            LocationPhone: location.LocationPhone,
            GLCode: location.GLCode,
            VenueGLID: location.VenueGLID,
            State: location.State,
            Country: location.Country,
            PostCode: location.PostCode,
            Latitude: location.Latitude,
            Longitude: location.Longitude,
            TimeZoneId: location.TimeZoneId,
            LocationFriendlyName: location.LocationFriendlyName,
            LocationNotes: location.LocationNotes,
            MerchantID: location.MerchantID,
            GooglePlacesID: location.GooglePlacesID,
            ExternalLocationID: location.ExternalLocationID,
            SAPCustomerID: location.SAPCustomerID,
            OnlineOrderingStatus: location.OnlineOrderingStatus,
            Suburb: location.Suburb,
            TableLayoutId: location.TableLayoutId,
            IsOpenNow: location.IsOpenNow,
            MinimumPickupTime: location.MinimumPickupTime,
            OrderingTimeInfo: location.OrderingTimeInfo ?
                location.OrderingTimeInfo.map((item: APIv3.LocationOrderingTimeInfoModel) => ({
                    DayOfWeek: item.DayOfWeek,
                    Date: item.Date,
                    OpeningTime: item.OpeningTime,
                    ClosingTime: item.ClosingTime,
                    PickupTimes: item.PickupTimes ?
                        item.PickupTimes.map((pickup: APIv3.LocationOrderingPickupTimeInfoModel) => ({
                            From: Utils.Dates.createHoursIntFromDate(pickup.From) < Utils.Dates.createHoursIntFromDate(item.OpeningTime) ?
                                item.OpeningTime : pickup.From,
                            To: Utils.Dates.createHoursIntFromDate(pickup.To) > Utils.Dates.createHoursIntFromDate(item.ClosingTime) ?
                                item.ClosingTime : pickup.To,
                            MinimumPickupTime: pickup.MinimumPickupTime,
                        })) : null,
                })) : null,
            VenueTags: location.VenueTags ?
                location.VenueTags.map((item: APIv3.TerminalVenueTagModel) => ({
                    Id: item.Id,
                    TagId: item.TagId,
                    VenueNo: item.VenueNo,
                    TagName: item.TagName,
                })) : null,
            LocationTags: location.LocationTags ?
                location.LocationTags.map((item: APIv3.TerminalLocationTagModel) => ({
                    Id: item.Id,
                    TagId: item.TagId,
                    LocationNo: item.LocationNo,
                    TagName: item.TagName,
                })) : null,
            FutureOrderingMinDaysAhead: location.FutureOrderingMinDaysAhead,
            FutureOrderingMaxDaysAhead: location.FutureOrderingMaxDaysAhead,
            DeliveryRadius: location.DeliveryRadius,
            OrderTypes: location.OrderTypes ?
                location.OrderTypes.map((item: APIv3.TerminalLocationOrderTypeModel) => ({
                    Id: item.Id,
                    TypeDescription: item.TypeDescription,
                    IsEnabledForFutureOrdering: item.IsEnabledForFutureOrdering,
                })) : null,
            OnlineOrderConfirmationEmailNotes: location.OnlineOrderConfirmationEmailNotes,
        })) : null;
    }
}
