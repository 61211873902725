import { ActionReducer, Action } from '@ngrx/store';
import * as StateModels from '../interface';

/* This meta reducer should be attached directly into root Store */

export function stateMetaReducer(reducer: ActionReducer<StateModels.IStateShared>): ActionReducer<StateModels.IStateShared> {
    return function newReducer(state: StateModels.IStateShared, action: Action): StateModels.IStateShared {

        switch (action.type) {
            case '[State] Restored':
                return { ...(action as any).state };

            default: {
                return reducer(state, action);
            }
        }
    };
}
