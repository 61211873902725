import { IFatZebraSettingsResponse } from '@shared/state';

export class FatZebraPaymentProviderMapper {
    public static mapGetSettingsForLocation(fatZebraSettings: APIv3.FatZebraSettingsResponse): IFatZebraSettingsResponse {
        return fatZebraSettings ? {
            DirectPostUrl: fatZebraSettings.DirectPostUrl,
            ReturnPath: fatZebraSettings.ReturnPath,
            Verification: fatZebraSettings.Verification?.toLocaleLowerCase(),
        } : null;
    }
}
