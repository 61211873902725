export class DateTimeRounder {
    private _tempDate: Date;

    constructor(
        public date: Date,
        public direction: 'up' | 'down',
    ) {
        this._tempDate = new Date(date.getTime());
    }

    public roundByQuarter(): Date {
        const minutes = this._tempDate.getMinutes();

        let newMinutes: number;
        switch (true) {
            case minutes < 15:
                newMinutes = this.direction === 'up' ? 15 : 0;
                break;

            case minutes >= 15 && minutes < 30:
                newMinutes = this.direction === 'up' ? 30 : 15;
                break;

            case minutes >= 30 && minutes < 45:
                newMinutes = this.direction === 'up' ? 45 : 30;
                break;

            case minutes >= 45 && minutes < 59:
                newMinutes = this.direction === 'up' ? 60 : 45;
                break;
        }

        return new Date(this._tempDate.setMinutes(newMinutes));
    }

    public roundByHour(): Date {
        const minutes = this._tempDate.getMinutes();

        let newMinutes: number;
        switch (true) {
            case minutes === 0:
                newMinutes = 0;
                break;
            case minutes < 30:
                newMinutes = this.direction === 'up' ? 60 : 0;
                break;

            case minutes >= 30:
                newMinutes = this.direction === 'up' ? 60 : 0;
                break;
        }

        return new Date(this._tempDate.setMinutes(newMinutes));
    }
}
