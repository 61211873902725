import { createSelector, createFeatureSelector } from '@ngrx/store';
import { IStateShared, ILocations } from '../interface';
import * as Utils from '@shared/core/utils';
import * as Models from '@shared/core/models';

const state = createFeatureSelector<IStateShared, ILocations>('locations');

export const getLocationsState = createSelector(
    state,
    locations => locations
);

export const getLocations = createSelector(
    state,
    locations => locations.data
);

export const isDownloadingLocations = createSelector(
    state,
    locations => locations.isDownloading
);

export const getLocationDetails = (locationNo: number) => createSelector(
    state,
    locations => !locations.data ? null : locations.data.find(location => location.LocationNo === locationNo)
);

export const getLocationLabels = (locationNo: number) => createSelector(
    state,
    locations => {
        const location = locations?.data?.find(obj => obj.LocationNo === locationNo);
        if (!location) return null;

        return location.OrderTypes?.map(obj => new Models.Label(obj.Id, obj.TypeDescription, true/* obj.IsEnabledForFutureOrdering */)) || null;
    }
);

export const hasRequestedLocations = createSelector(
    state,
    locations => {
        if (!locations || locations.isDownloading || (locations.isDownloading === false && locations.hasSucceeded === false && locations.hasFailed === false)) return false;

        return true;
    }
);

export const isLocationAvailableForOnlineOrdering = (locationNo: number) => createSelector(
    getLocationDetails(locationNo),
    location => {
        if (!location) return null;

        return location.OnlineOrderingStatus === 0;
    }
);

export const hasLocations = createSelector(
    state,
    locations => !locations.isDownloading && locations.data && locations.data.length > 0 ? true : false,
);

export const getOrderingTimeInfoByLocationNo = (locationNo: number, filterAvailable: boolean = true) => createSelector(
    state,
    locations => {
        if (!locations.data) return null;
        const location = locations.data?.find(obj => obj.LocationNo === locationNo) || null;
        if (!location) return;
        if (!filterAvailable) {
            return location?.OrderingTimeInfo?.map(orderingTimeInfo => ({
                ...orderingTimeInfo,
                IsOpen: new Utils.LocatioOpenStatus(orderingTimeInfo).isOpen(),
            })) || null;
        }

        return Utils.Pickups.getFilteredOrderingTimeInfo(location);
    }
);

export const getMinimumPickupTimesForAllLocationsByDate = (date: string | Date | null = null) => createSelector(
    state,
    locations => {
        let d: Date = Utils.Dates.createDate(date);
        const currDay = d.getDay();

        return locations.data?.map(obj => {
            const baseObj = {
                locationNo: obj.LocationNo,
                isDownloading: false,
                hasSucceeded: true,
                hasFailed: false,
            };

            const noDataAvailable: boolean = !obj?.OrderingTimeInfo || obj?.OrderingTimeInfo?.length === 0;

            if (noDataAvailable) {
                return {
                    ...baseObj,
                    MinimumPickupTime: null,
                };
            }

            const foundDay = Utils.Pickups.getFilteredOrderingTimeInfo(obj).find(orderingTime => orderingTime.DayOfWeek === currDay);

            if (!foundDay) {
                /* When there is no data about selected day  - assume location is closed*/
                return {
                    ...baseObj,
                    MinimumPickupTime: null,
                };
            }

            // const isLocationOpen: boolean = Utils.Dates.isHourInHoursRange(Utils.Dates.getLocalISOFormatDate(d), foundDay.OpeningTime, foundDay.ClosingTime, 'from');
            const isLocationOpenNowOrLaterToday: boolean = Utils.Dates.isHourInHoursRange(Utils.Dates.getLocalISOFormatDate(d), d, foundDay.ClosingTime, 'from');

            if (!isLocationOpenNowOrLaterToday) {
                /* Location is closed explicitly */
                return {
                    ...baseObj,
                    MinimumPickupTime: null,
                };
            }

            const foundCurrentPickupTime = foundDay.PickupTimes.find(pickupTime => Utils.Dates.isHourInHoursRange(
                Utils.Dates.getLocalISOFormatDate(d),
                pickupTime.From, pickupTime.To, 'both'
            ));
            if (!foundCurrentPickupTime) { /* By default we assume there is 0 preperation time */
                return {
                    ...baseObj,
                    MinimumPickupTime: 0,
                };
            }

            return {
                ...baseObj,
                MinimumPickupTime: foundCurrentPickupTime.MinimumPickupTime,
            };

        });
    });

export const getMinimumPickupTimeForLocation = (locationNo: number) => createSelector(
    getMinimumPickupTimesForAllLocationsByDate(),
    minimumPickups => minimumPickups.find(obj => obj.locationNo === locationNo)
);

export const getOrderInfoCompleteForLocationsByDate = (date: string | Date | null = null) => createSelector(
    state,
    locations => {
        let d: Date = Utils.Dates.createDate(date);
        const currDay = d.getDay();

        return locations.data?.map(obj => {
            const baseObj = {
                locationNo: obj.LocationNo,
                isDownloading: false,
                hasSucceeded: true,
                hasFailed: false,
            };

            const noDataAvailable: boolean = !obj?.OrderingTimeInfo || obj?.OrderingTimeInfo.length === 0;
            if (noDataAvailable) {
                return {
                    ...baseObj,
                    OpeningTime: null,
                    ClosingTime: null,
                    IsOpen: null,
                    MinimumPickupTime: null,
                };
            }

            const foundDay = obj?.OrderingTimeInfo.find(orderingTime => orderingTime.DayOfWeek === currDay);
            if (!foundDay) {
                /* When there is no data about selected day  - assume location is closed*/
                return {
                    ...baseObj,
                    OpeningTime: null,
                    ClosingTime: null,
                    IsOpen: false,
                    MinimumPickupTime: null,
                };
            }

            const minimumPickupTimeForDay = foundDay.PickupTimes.find(pickupTime => Utils.Dates.isHourInHoursRange(
                Utils.Dates.getLocalISOFormatDate(d),
                pickupTime.From, pickupTime.To,
                'both'
            ));

            return {
                ...baseObj,
                OpeningTime: foundDay.OpeningTime,
                ClosingTime: foundDay.ClosingTime,
                IsOpen: Utils.Dates.isHourInHoursRange(d, foundDay.OpeningTime, foundDay.ClosingTime, 'from'),
                MinimumPickupTime: minimumPickupTimeForDay ? minimumPickupTimeForDay.MinimumPickupTime : 0,
            };
        });
    });

export const getOrderInfoCompleteForLocationByDate = (locationNo: number, date: string | Date | null = null) => createSelector(
    getOrderInfoCompleteForLocationsByDate(date),
    locationsOrderInfo => locationsOrderInfo.find(obj => obj.locationNo === locationNo)
);


export const getEdgeOpeningHoursForLocations = (date: string | Date | null = null) => createSelector(
    getOrderInfoCompleteForLocationsByDate(date),
    (openingHours) => {
        const tempObj = {
            OpeningTime: null,
            ClosingTime: null,
        };
        openingHours.forEach(obj => {
            if (!obj.OpeningTime || !obj.ClosingTime) return;
            const tempOpen = tempObj.OpeningTime === null ? null : Utils.Dates.createHoursIntFromDate(tempObj.OpeningTime);
            const tempClose = tempObj.OpeningTime === null ? null : Utils.Dates.createHoursIntFromDate(tempObj.ClosingTime);
            const openingTime = obj.OpeningTime ? Utils.Dates.createHoursIntFromDate(obj.OpeningTime) : null; // new Date(`${currentDateString}T${obj.OpeningTime}.000Z`);
            const closingTime = obj.ClosingTime ? Utils.Dates.createHoursIntFromDate(obj.ClosingTime) : null; // new Date(`${currentDateString}T${obj.ClosingTime}.000Z`);


            if (tempOpen === null || tempOpen > openingTime) {
                tempObj.OpeningTime = obj.OpeningTime;
            }

            if (tempClose === null || tempClose < closingTime) {
                tempObj.ClosingTime = obj.ClosingTime;
            }
        });

        return tempObj;
    }
);

export const getEdgeMinimumPickupTimeForLocations = (date: string | Date | null = null) => createSelector(
    getMinimumPickupTimesForAllLocationsByDate(date),
    (minimumPickupTimes) => minimumPickupTimes.reduce((acc, curr) => {
        if (curr.MinimumPickupTime === null) return acc;

        if (acc === null) {
            return curr.MinimumPickupTime;
        }

        if (acc > (curr.MinimumPickupTime)) {
            return curr.MinimumPickupTime;
        }

        return acc;

    }, null as number)
);

export const getEdgeOrderingTimeInfoForLocations = (date: string | Date | null = null) => createSelector(
    getEdgeMinimumPickupTimeForLocations(date),
    getEdgeOpeningHoursForLocations(date),
    (MinimumPickupTime, openingHours) => ({
        MinimumPickupTime,
        ...openingHours
    })
);

export const getMinimumPickupTimeForLocationAndDate = (locationNo: number, date: string | Date | null = null) => createSelector(
    state,
    locations => {
        const location = locations.data?.find(obj => obj.LocationNo === locationNo);
        if (!location) return null;

        const foundObj = Utils.Pickups.getFilteredOrderingTimeInfo(location);
        if (!foundObj) return null;

        let d: Date = date instanceof Date ? date : Utils.Dates.createDate(date);
        const findDay = foundObj.find(Utils.Pickups.datesMatchByDayCallback(Utils.Dates.getLocalISOFormatDate(d)));

        if (findDay === undefined) {
            return null;
        }

        const foundPickup = findDay.PickupTimes.find(obj => Utils.Dates.isHourInHoursRange(Utils.Dates.getLocalISOFormatDate(d), obj.From, obj.To));

        if (foundPickup) return foundPickup;

        /* Default case when pickup time is not configured but location is open - take opening times and 0 minimumPickupTime */
        return {
            From: findDay.OpeningTime,
            To: findDay.ClosingTime,
            MinimumPickupTime: 0,
        };
    }
);

export const getOrderingTimeInfoForTodayByLocationNo = (locationNo: number) => createSelector(
    state,
    locations => {
        const foundObj = locations.data?.find(obj => obj.LocationNo === locationNo)?.OrderingTimeInfo;
        if (!foundObj) return null;
        const date = new Date();
        const currDay = date.getDay();

        return foundObj.find(obj => obj.DayOfWeek === currDay);
    }
);


export const isLocationOpenNowByOrderingTimeInfoObj = (locationNo: number) => createSelector(
    state,
    locations => {
        const foundObj = locations.data?.find(obj => obj.LocationNo === locationNo)?.OrderingTimeInfo;
        if (!foundObj) return null;
        const date = new Date();
        const currDay = date.getDay();

        const foundDay = foundObj.find(obj => obj.DayOfWeek === currDay);
        if (!foundDay) {
            return false;
        }

        return Utils.Dates.isHourInHoursRange(Utils.Dates.getLocalISOFormatDate(date), foundDay.OpeningTime, foundDay.ClosingTime, 'from');
    }
);

export const getLocationOpenStatus = (locationNo: number, forDate: Date = new Date()) => createSelector(
    getLocationDetails(locationNo),
    (location) => {
        const obj: OLO.Common.ILocationOpenStatus = {
            locationNo,
            status: null,
            message: '',
        };

        if (!location) return obj;

        const statusMessageObj = new Utils.LocationOpenStatusMessage(
            Utils.LocatioOpenStatus,
            location.OrderingTimeInfo,
            forDate,
        );

        return {
            ...obj,
            status: statusMessageObj.getStatus(),
            message: statusMessageObj.getMessageForDate(),
        };
    }
);

export const getAllLocationsPeriods = (format: string = 'ddd, D MMM', prefixes: boolean = true) => createSelector(
    state,
    locations => {
        if (!locations.data) return null;

        return locations.data?.reduce((acc, location) => {
            if (!location.OrderingTimeInfo || location.OrderingTimeInfo.length === 0) return acc;

            const locationIsConfiguredForFutureOrders = location.FutureOrderingMaxDaysAhead !== null && location.FutureOrderingMinDaysAhead !== null;
            let min: number = location.FutureOrderingMinDaysAhead;
            let max: number = location.FutureOrderingMaxDaysAhead;

            Utils.Pickups.getFilteredOrderingTimeInfo(location)
                .forEach(timeInfo => {
                    const foundInAcc = acc.some(existingObj => existingObj.Date.split('T')[0] === timeInfo.Date.split('T')[0]);
                    if (!foundInAcc) {
                        const daysDiff = Utils.Dates.datesDiffInDays(new Date(), Utils.Dates.createDate(timeInfo.Date));

                        const isTodayWithoutFutureOrderingConfigured = !locationIsConfiguredForFutureOrders && daysDiff === 0;
                        const isAnyDateWithFutureOrderingConfigured = locationIsConfiguredForFutureOrders && daysDiff >= min && daysDiff <= max;

                        if (isTodayWithoutFutureOrderingConfigured || isAnyDateWithFutureOrderingConfigured) {
                            acc.push(Utils.Pickups.createPeriodObject(timeInfo, format, prefixes));
                        }
                    }
                });

            return acc;

        }, [] as OLO.Ordering.IPeriod[]).sort((a, b) => a.Id - b.Id);
    }
);


/*
 * export const getLocationFuturePickupsList = (locationNo: number, format: string = 'ddd, D MMM', prefixes: boolean = true) => createSelector(
 *     getLocationPeriods(locationNo, format, prefixes),
 *     getLocationDetails(locationNo),
 *     (periods, location) => {
 *         if(!periods || !location) return null;
 */

//         return periods.map((period) => {

//             return Utils.Dates.generatePickupTimesFutureList(
//                 period,
//                 {
//                     location: null,
//                     asapPickupMins: nextTick,
//                     openingHours: [{
//                         Date: period.Date,
//                         DayOfWeek: period.DayOfWeek,
//                         OpeningTime: period.OpeningTime,
//                         ClosingTime: period.ClosingTime,
//                         // LocationId: 0, /* API bug */
//                     }],
//                     orderTimeoutBufferMins,
//                     startBufferMins,
//                     nextTick,
//                 });
//         })
//     }
// )


export const generatedFiltersPickupTimesForAllLocations = (
    config: IConfig,
    includeDayName: boolean = true,
    limit: number = null,
    isSchedule: boolean = false
) => createSelector(
    getEdgeOrderingTimeInfoForLocations(),
    (edgeCases) => {
        const DayOfWeek: number = new Date().getDay();

        if (edgeCases.MinimumPickupTime === null) {
            return null;
        }

        const asapModel: OLO.Ordering.IPickupTime = new Models.PickupModels().generateDefaultAsap();
        const scheduleModel: OLO.Ordering.IPickupTime = new Models.PickupModels().generateDefaultSchedule();

        const list = Utils.Pickups.generatePickupTimesList({
            location: null,
            asapPickupMins: edgeCases.MinimumPickupTime,
            openingHours: [{
                DayOfWeek: (DayOfWeek as APICommon.DayOfWeek),
                OpeningTime: edgeCases.OpeningTime,
                ClosingTime: edgeCases.ClosingTime,
                // LocationId: 0, /* API bug */
            }],
            orderTimeoutBufferMins: config.collectionTypes.pickup.orderTimeoutBufferMins,
            startBufferMins: config.collectionTypes.pickup.startBufferMins,
            nextTick: config.collectionTypes.pickup.nextTick,
            limit,
        }).filter(pickup => pickup.IsAsap === false);
        /*
         * .map(pickup => {
         *     //let Name: string = includeDayName ? `${Utils.Dates.getDayNameFromDate(pickup.Date)} ${pickup.Name}` : pickup.Name;
         */

        /*
         *     // if (isSchedule) {
         *     //     const diff = Utils.Dates.datesDiffInDays(new Date(), pickup.Date);
         */

        /*
         *     //     switch (true) {
         *     //         case diff === 0:
         *     //             Name = `Today - ${pickup.Name}`;
         *     //             break;
         *     //         case diff === 1:
         *     //             Name = `Tomorrow - ${pickup.Name}`;
         *     //             break;
         *     //     }
         */

        /*
         *     //     return {
         *     //         ...pickup,
         *     //         Name
         *     //     };
         *     // }
         *     return {
         *         ...pickup,
         *         Name,
         *     };
         * });
         */

        list.unshift(asapModel);

        if (limit && list.length > limit) {
            list.length = limit;
        }

        if (isSchedule) {
            list.push(scheduleModel);
        }

        return list;
    }
);

export const getPickupTimesForSelectedDateForLocation = (date: Date | string = null, locationNo: number) => createSelector(
    getLocationDetails(locationNo),
    (location) => {
        if (!location || !location.OrderingTimeInfo) return null;
        let d: string = !date && Utils.Dates.getLocalISOFormatDate(new Date(), false) || '';
        if (date instanceof Date) {
            d = Utils.Dates.getLocalISOFormatDate(date);
        }

        if (typeof date === 'string') {
            d = date;
        }

        d = d.split('T')[0];

        return Utils.Pickups.getFilteredOrderingTimeInfo(location).find(obj => obj.Date.split('T')[0] === d);
    }
);

export const getPickupTimesForSelectedDate = (date: Date | string = null) => createSelector(
    getLocations,
    locations => {
        if (!locations) return null;
        let d: string = !date && Utils.Dates.getLocalISOFormatDate(new Date(), false) || '';
        if (date instanceof Date) {
            d = Utils.Dates.getLocalISOFormatDate(date);
        }

        if (typeof date === 'string') {
            d = date;
        }

        d = d.split('T')[0];

        const tempObj = {
            OpeningTime: null,
            ClosingTime: null,
        };

        locations.forEach(location => {
            const openingHours = Utils.Pickups.getFilteredOrderingTimeInfo(location).find(orderingObj => orderingObj.Date.split('T')[0] === d);
            if (openingHours) {
                if (!openingHours.OpeningTime || !openingHours.ClosingTime) return;
                const tempOpen = tempObj.OpeningTime === null ? null : Utils.Dates.createHoursIntFromDate(tempObj.OpeningTime);
                const tempClose = tempObj.OpeningTime === null ? null : Utils.Dates.createHoursIntFromDate(tempObj.ClosingTime);
                const openingTime = openingHours.OpeningTime ? Utils.Dates.createHoursIntFromDate(openingHours.OpeningTime) : null;
                const closingTime = openingHours.ClosingTime ? Utils.Dates.createHoursIntFromDate(openingHours.ClosingTime) : null;


                if (tempOpen === null || tempOpen > openingTime) {
                    tempObj.OpeningTime = openingHours.OpeningTime;
                }

                if (tempClose === null || tempClose < closingTime) {
                    tempObj.ClosingTime = openingHours.ClosingTime;
                }
            }
        });

        return tempObj;
    }
);

export const generatedPickupTimesForAllLocationsForPeriod = (
    period: OLO.Ordering.IPeriod,
    orderTimeoutBufferMins: number = 0, /* Static value */
    startBufferMins: number = 60, /* Static value */
    nextTick: number = 60, /* Static value */
) => createSelector(
    getPickupTimesForSelectedDate(period.Date),
    (edgeCases) => {
        const DayOfWeek: number = period.DayOfWeek;

        return Utils.Pickups.generatePickupTimesFutureList(
            period,
            {
                location: null,
                asapPickupMins: nextTick,
                openingHours: [{
                    Date: period.Date,
                    DayOfWeek: (DayOfWeek as APICommon.DayOfWeek),
                    OpeningTime: edgeCases.OpeningTime,
                    ClosingTime: edgeCases.ClosingTime,
                    // LocationId: 0, /* API bug */
                }],
                orderTimeoutBufferMins,
                startBufferMins,
                nextTick,
            });
    }
);
