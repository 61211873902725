import { Injectable } from '@angular/core';
import { Store, select } from '@ngrx/store';

import * as selectors from '@shared/state/selectors';

import * as State from '@shared/state';
import { Observable } from 'rxjs';
import { map, distinct, skipWhile, auditTime, filter, combineLatest, switchMap, take, tap, pairwise } from 'rxjs/operators';
import { IMenuFlowPage } from '@shared/state';

@Injectable({
    providedIn: 'root'
})
export class MenuFlowsController {
    private _pageMockContainer: IMenuFlowPage = {
        PageIdentifier: null,
        PageNo: null,
        PageName: null,
        PageMinQuantity: null,
        PageMaxQuantity: null,
        SpecialPriceQuantity: null,
        SpecialPrice: null,
        SpecialPriceSelection: null,
        PriceChangeMode: null,
        PriceChangeValue: null,
        IsUpsell: false,
        UpsellText: null,
        Products: [],
        HideFromKiosk: false,
    };

    constructor(
        private _store: Store<State.IStateShared>,
    ) { }

    public isMenuFlowRequestingDetails$(menuFlowId: number): Observable<boolean> {
        return this._store
            .pipe(
                select(selectors.getOnlineMenu),
                switchMap(onlineMenu => this._store
                    .pipe(
                        select(selectors.isMenuFlowRequestingDetails(menuFlowId, onlineMenu?.locationNo))
                    ))

            );
    }

    public getCombinedMenuFlowPages$(menuFlowId: number, locationNo: number): Observable<APIv1.MenuFlowPage[]> {
        return this._store.pipe(
            select(selectors.getMenuFlowPages(menuFlowId, locationNo)),
            combineLatest(
                this._store
                    .pipe(
                        select(selectors.getMenuFlowDefaultActivation(menuFlowId)),
                        skipWhile(obj => obj === undefined || obj.data === undefined || obj.data === null),
                    )
            ),
            map(([pages, activations]) => {
                const whatsIncludedPage: IMenuFlowPage = {
                    ...this._pageMockContainer,
                    PageIdentifier: 1,
                    PageNo: 1,
                    PageName: 'What\'s Included',
                    Products: [],
                };

                const suggestedExtrasPage: IMenuFlowPage = {
                    ...this._pageMockContainer,
                    PageIdentifier: 2,
                    PageNo: 2,
                    PageName: 'Suggested Extras',
                    Products: [],
                };

                pages.forEach(page => {
                    page.Products.forEach(product => {
                        /* _OriginalPage is used to persist product page functionality and validation */
                        const p = { ...product, _OriginalPage: { ...page } } as APICommon.IMenuFlowProduct;

                        const isDefaultActive = activations.data.MenuFlowItems.find(activation => activation.PLU === product.Plu && activation.MenuFlowPageId === page.PageNo);

                        if (isDefaultActive) {
                            return whatsIncludedPage.Products.push(p);
                        }

                        suggestedExtrasPage.Products.push(p);
                    });
                });

                return [
                    whatsIncludedPage,
                    suggestedExtrasPage,
                ].filter(page => page.Products.length > 0);
            }),
        );
    }

    public menuFlowDetails$(menuFlowId: number, locationNo: number): Observable<State.IMenuFlows> {
        return this._store
            .pipe(
                select(selectors.getMenuFlow(menuFlowId, locationNo)),
                filter(menuFlow => menuFlow !== undefined && menuFlow !== null),
            );
    }

    public menuFlowPages$(menuFlowId: number, locationNo: number): Observable<IMenuFlowPage[]> {
        return this.menuFlowDetails$(menuFlowId, locationNo)
            .pipe(
                filter(menuFlow => menuFlow !== undefined && menuFlow.data !== null),
                map(menuFlow => menuFlow.data.Pages.filter(page => page.HideFromKiosk !== true && page.Products.length > 0)),
            );
    }
}
