export type ALERT_TYPE = 'error' | 'success' | 'warning' | 'info';

export enum ALERT_ANIMATION {
    IN = 'in',
    OUT = 'out',
}

export interface IAlertMessagesState {
    alertMessages?: IAlertMessage[];
}

export interface IAlertMessage {
    id?: number;
    type?: ALERT_TYPE;
    animation?: ALERT_ANIMATION;
    title?: string;
    description?: string;
}
