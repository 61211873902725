import * as Utils from '../utils';
/* https://blog.angularindepth.com/implementing-custom-component-decorator-in-angular-4d037d5a3f0d */
/* http://prideparrot.com/blog/archive/2018/7/extending_component_decorator_angular_6 */

/*
    Hint!
    Very basic rule of titles (I know this from SEO agency I had worked before): 'from detail to general description'
    Lets assume you own 'an adult' ;) website and you are currently on some video page,
    your title should look like that:
    "{ video title } [-/: ,] { video category } [-/: ,] { your website title }"

    And this is how this decorator works.
    You can override base title (before this decorator is instantiated) using Utils.Meta.setTags({title: '{YOUR TITLE}'}),
    although setting title manually is much slower than setting it directly in 'index.html' file!
*/
export interface IMetaTagsForDecorator extends Utils.IMetaTags {
    titleSeparator?: string;
    appendBaseTitle?: boolean;
    extendTitle?: boolean;
}

export function Meta(tags: IMetaTagsForDecorator = { titleSeparator: ' - ', appendBaseTitle: true, extendTitle: true }) {
    return function (target: any) {
        Object.defineProperties(target.prototype, {
            baseTitle: {
                value: null,
                writable: true,
                configurable: true,
                enumerable: true,
            },
            prevTitle: {
                value: null,
                writable: true,
                configurable: true,
                enumerable: true,
            },
            prevMetas: {
                value: {
                    author: null,
                    description: null,
                    keywords: null,
                },
                writable: true,
                configurable: true,
                enumerable: true,
            }
        });

        target.prototype.setTitle = function (title: string, appendBaseTitle: boolean = true, extendTitle: boolean = true, titleSeparator: string = ' - '): Promise<any> {
            return new Promise(resolve => {
                let tag = document.querySelector(`title`);
                if (!tag) {
                    tag = document.createElement('title');
                    document.head.append(tag);
                }

                if (this.prevTitle === null) {
                    this.prevTitle = tag.innerText || '';
                }

                if (title) {
                    if (extendTitle) {
                        return tag.innerHTML = `${title}${titleSeparator}${this.prevTitle}`;

                    }

                    if (appendBaseTitle) {
                        return tag.innerHTML = `${title}${titleSeparator}${this.baseTitle}`;
                    }

                    return tag.innerHTML = `${title}`;
                }

                return;
            });
        };

        target.prototype.viewClassName = function (): string {
            let classString = tags?.title ? `${tags.title.toLowerCase().replace(/[^a-z0-9]/gi, '-')}-view` : '';
            classString = classString.replace(/-{2,}/g, '-');
            return classString;
        };

        target.prototype.setViewCSSClassName = function (): void {
            const cssClassName: string = this.viewClassName();
            if (cssClassName) {
                document.body.classList.add(cssClassName);
            }
        };

        target.prototype.unsetViewCSSClassName = function (): void {
            const cssClassName: string = this.viewClassName();
            if (cssClassName) {
                document.body.classList.remove(cssClassName);
            }
        };

        target.prototype.setMeta = function (name: string, value: string = ''): Promise<any> {
            return new Promise(resolve => {
                let metaTag = document.querySelector(`meta[name="${name}"]`);
                if (!metaTag) {
                    metaTag = document.createElement('meta');
                    metaTag.setAttribute('name', name);
                    document.head.appendChild(metaTag);
                }
                /* Save current */
                if (this.prevMetas[name] === null) {
                    this.prevMetas[name] = metaTag.getAttribute('content');
                }

                metaTag.setAttribute('content', value);

                return;
            });
        };


        /* Restore previous values */
        /* Update ngOnInit && ngOnDestroy */
        let isNgOnInitDefined: boolean = target.prototype.ngOnInit !== undefined && typeof target.prototype.ngOnInit === 'function';
        let isNgOnDestroyDefined: boolean = target.prototype.ngOnDestroy !== undefined && typeof target.prototype.ngOnDestroy === 'function';

        if (!isNgOnInitDefined) {
            console.warn(`WARNING! No ngOnInit method detected in "${target.name}". Please add it to enable Meta`);
        }

        if (!isNgOnDestroyDefined) {
            console.warn(`WARNING! No ngOnDestroy method detected in: "${target.name}". Please add it to enanble Meta`);
        }

        const originalFunction = target.prototype.ngOnDestroy;
        const _ = this;
        Object.defineProperty(target.prototype, 'ngOnDestroy', {
            writable: true,
            value: function () {
                /* Restore title */
                if (this.baseTitle !== null) {
                    Utils.Meta.setTags({ title: this.baseTitle });
                    this.unsetViewCSSClassName();
                }
                /* Restore metas */
                for (let tag in tags) {
                    if (tag === 'description' || tag === 'keywords' || tag === 'author') {
                        Utils.Meta.setTags({ [tag]: this.prevMetas[tag] || '' });
                    }
                }

                if (typeof originalFunction === 'function') {
                    originalFunction.apply(this, arguments);
                }

            }
        });


        const originalInitFunction = target.prototype.ngOnInit;

        Object.defineProperty(target.prototype, 'ngOnInit', {
            writable: true,
            value: function () {
                if (this.baseTitle === null) {
                    /* Save main title suffix */
                    const titleTag = document.querySelector('title');
                    if (titleTag) {
                        this.baseTitle = titleTag.innerText;
                    }
                }

                /* Set static tags provided in decorator params */
                if (tags.title !== undefined) {
                    this.setTitle(tags.title, tags.appendBaseTitle, tags.extendTitle, tags.titleSeparator);
                    this.setViewCSSClassName();
                }

                /* Set metas */
                for (let tag in tags) {
                    if (tag === 'description' || tag === 'keywords' || tag === 'author') {
                        this.setMeta(tag, tags[tag]);
                    }
                }

                if (typeof originalInitFunction === 'function') {
                    originalInitFunction.apply(this, arguments);
                }

            }
        });

    };
}
