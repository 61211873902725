import { Injectable } from '@angular/core';
import { CanActivate, CanActivateChild, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Store, select } from '@ngrx/store';

import * as State from '@shared/state';
import * as Services from '@shared/core/services';
import * as actions from '@shared/state/actions';
import * as selectors from '@shared/state/selectors';

import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class UniqueCodeGuard implements CanActivate {
    constructor(
        private _store: Store<State.IStateShared>,
        private _authService: Services.AuthService,
        private _routeService: Services.RouteService,
        private _modalsService: Services.ModalsService,
        private _router: Router,
    ) { }
    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean> | Promise<boolean> | boolean {
        return this._store
            .pipe(
                select(selectors.isMemberAuthorized),
                take(1)
            ).toPromise()
            .then(isAuthorized => {
                if (!isAuthorized) {
                    const isValidMemberCodeUrl: boolean = route.queryParams.memberCode !== undefined
                        && route.queryParams.memberCode.length > 1;

                    if (isValidMemberCodeUrl) {
                        this._store.dispatch(actions.MemberUniqueCodeCheckRequest({ memberCode: route.queryParams.memberCode }));
                        this._store.dispatch(actions.MemberAuthorizationSetStep({ step: OLO.Enums.AUTH_STEP.REGISTER }));

                        this._modalsService.show({
                            id: 1,
                            type: 'auth',
                        });
                    }
                }
                return this._routeService.navigateToHomeView();
            });
    }
}
