import { createAction, props } from '@ngrx/store';
import { ILoyaltyAppTransactionModel } from '@shared/state';

export const LatestTransactionsRequest = createAction(
    '[Latest Transactions] Request latest transactions for member',
    props<{ memberId: number; }>(),
);

export const LatestTransactionsSuccessRequest = createAction(
    '[Latest Transactions] Success request latest transactions for member',
    props<{ memberId: number; payload: ILoyaltyAppTransactionModel[]; }>(),
);

export const LatestTransactionsErrorRequest = createAction(
    '[Latest Transactions] Error request latest transactions for member',
    props<{ memberId: number; ex?: any; }>(),
);

export const LatestTransactionsReset = createAction(
    '[Latest Transactions] Reset',
);
