import { createReducer, on, Action } from '@ngrx/store';
import { ITopBar } from './top-bar.interface';
import * as actions from './top-bar.actions';

const initialState: ITopBar = {
    isSticky: false,
    isCartVisible: true,
    isBackToLandingVisible: false,
    isBackToStoreVisible: false,
};

export const topBarReducerFn = createReducer(
    initialState,
    on(
        actions.TopBarSetStickyFlag,
        (state, action) => ({
            ...state,
            isSticky: action.isSticky,
        })
    ),
    on(
        actions.TopBarSetCartIconVisibility,
        (state, action) => ({
            ...state,
            isCartVisible: action.isVisible
        })
    ),
    on(
        actions.TopBarSetBackToStoreIconVisibility,
        (state, action) => ({
            ...state,
            isBackToStoreVisible: action.isVisible
        })
    ),
    on(
        actions.TopBarSetBackToLandingIconVisibility,
        (state, action) => ({
            ...state,
            isBackToLandingVisible: action.isVisible
        })
    ),
);

export function topBarReducer(state: ITopBar | undefined, action: Action) {
    return topBarReducerFn(state, action);
}
