import { createAction, props } from '@ngrx/store';
import { IOrder } from '@shared/state';

export const OrderTypesRequest = createAction(
    '[Order Types] Request order types for location',
    props<{ locationNo: number; }>(),
);

export const OrderTypesSuccessRequest = createAction(
    '[Order Types] Success request order types for location',
    props<{ locationNo: number; payload: IOrder[]; }>(),
);

export const OrderTypesErrorRequest = createAction(
    '[Order Types] Error request order types for location',
    props<{ locationNo: number; ex?: any; }>(),
);
