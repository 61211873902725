import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { DevConsoleComponent } from './dev-console.shared.component';

@NgModule({
    declarations: [
        DevConsoleComponent
    ],
    imports: [CommonModule],
    exports: [
        DevConsoleComponent
    ],
    providers: [],
})
export class DevConsoleModule { }
