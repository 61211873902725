import { createReducer, on, Action } from '@ngrx/store';
import { IMenuFlows } from './menu-flows.interface';
import * as actions from './menu-flows.actions';

const initialState: IMenuFlows[] = [];

export const menuFlowsReducerFn = createReducer(
    initialState,
    on(
        actions.MenuFlowsDetailsRequest,
        (state, action) => {
            const foundIndex: number = state.findIndex(menuFlow => menuFlow.MenuFlowId === action.menuFlowId && menuFlow.LocationNo === action.locationNo);

            if (foundIndex === -1) {
                return [
                    ...state,
                    {
                        isDownloading: true,
                        hasSucceeded: false,
                        hasFailed: false,
                        LocationNo: action.locationNo,
                        MenuFlowId: action.menuFlowId,
                        data: null,
                    }
                ];
            }

            return state.map((menuFlow, index) => {
                if (index === foundIndex) {
                    return {
                        ...menuFlow,
                        isDownloading: true,
                        hasSucceeded: false,
                        hasFailed: false,
                    };
                }

                return menuFlow;
            });
        }
    ),
    on(
        actions.MenuFlowsDetailsSuccessRequest,
        (state, action) => {
            const foundIndex: number = state.findIndex(menuFlow => menuFlow.MenuFlowId === action.menuFlowId && menuFlow.LocationNo === action.locationNo);

            if (foundIndex === -1) {
                return [
                    ...state,
                    {
                        isDownloading: false,
                        hasSucceeded: true,
                        hasFailed: false,
                        LocationNo: action.locationNo,
                        MenuFlowId: action.menuFlowId,
                        data: action.payload,
                    }
                ];
            }

            return state.map((menuFlow, index) => {
                if (index === foundIndex) {
                    return {
                        ...menuFlow,
                        isDownloading: false,
                        hasSucceeded: true,
                        hasFailed: false,
                        data: action.payload
                    };
                }

                return menuFlow;
            });
        }
    ),


    on(
        actions.MenuFlowsDetailsErrorRequest,
        (state, action) => {
            const foundIndex: number = state.findIndex(menuFlow => menuFlow.MenuFlowId === action.menuFlowId && menuFlow.LocationNo === action.locationNo);

            if (foundIndex === -1) {
                return [
                    ...state,
                    {
                        isDownloading: false,
                        hasSucceeded: false,
                        hasFailed: true,
                        LocationNo: action.locationNo,
                        MenuFlowId: action.menuFlowId,
                        data: null,
                    }
                ];
            }

            return state.map((menuFlow, index) => {
                if (index === foundIndex) {
                    return {
                        ...menuFlow,
                        isDownloading: false,
                        hasSucceeded: false,
                        hasFailed: true,
                    };
                }

                return menuFlow;
            });
        }
    ),


);


export function menuFlowsReducer(state: IMenuFlows[] | undefined, action: Action) {
    return menuFlowsReducerFn(state, action);
}
