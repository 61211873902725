import { Injectable, Inject } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { StripeInitResult, StripeTargetElementsList, ElementsConfiguration } from '@stripe/stripe-js';
import * as selectors from '@shared/state/selectors';

import * as State from '@shared/state';
import * as Tokens from '@shared/core/tokens';
import * as Services from '@shared/core/services';
import * as Utils from '@shared/core/utils';

import { Observable } from 'rxjs';
import { map, distinct, distinctUntilChanged, auditTime, filter, combineLatest, withLatestFrom, switchMap, take, tap, pairwise } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class PaymentController {
    constructor(
        @Inject(Tokens.CONFIG_TOKEN) private _config: IConfig,
        private _store: Store<State.IStateShared>,
        private _paymentService: Services.PaymentsService,
        private _adyenPaymentProviderService: Services.AdyenPaymentProviderService,
        private _stripePaymentProviderService: Services.StripePaymentProviderService
    ) { }

    public get isCommonType(): boolean {
        return this._config.paymentProvider === OLO.Enums.PAYMENT_PROVIDER.CARD_CONNECT || this._config.paymentProvider === OLO.Enums.PAYMENT_PROVIDER.CONVERGE;
    }

    public get isRedirectType(): boolean {
        return this._config.paymentProvider === OLO.Enums.PAYMENT_PROVIDER.PAYMENT_EXPRESS || this._config.paymentProvider === OLO.Enums.PAYMENT_PROVIDER.FAT_ZEBRA;
    }

    public get isProviderFormType(): boolean {
        return this._config.paymentProvider === OLO.Enums.PAYMENT_PROVIDER.ADYEN || this._config.paymentProvider === OLO.Enums.PAYMENT_PROVIDER.STRIPE;
    }

    public initAdyenForm(targetHmtl: HTMLElement | string, configuration?: Adyen.Configuration): void {
        this._store
            .pipe(
                select(selectors.getAdyenLocationConfig),
                filter(state => state.isDownloading === false && state.hasSucceeded === true),
                take(1)
            ).subscribe(state => {
                this._adyenPaymentProviderService.setupForm(state.data, targetHmtl, configuration);
            });
    }

    public destroyAdyenForm(): void {
        return this._adyenPaymentProviderService.destroyForm();
    }

    public async initStripeForm(targetHmtlElementsList: StripeTargetElementsList, configuration?: ElementsConfiguration): Promise<StripeInitResult> {
        return new Promise(resolve => {
            this._store
                .pipe(
                    select(selectors.getStripeLocationConfig),
                    filter(state => state.isDownloading === false && state.hasSucceeded === true),
                    take(1)
                ).subscribe(state => {
                    resolve(this._stripePaymentProviderService.setupForm(state.data, targetHmtlElementsList, configuration));
                });
        });
    }

    public destroyStripeForm(): void {
        return this._stripePaymentProviderService.destroyForm();
    }

    public getErrors$(): Observable<State.IPaymentError[]> {
        return this._store
            .pipe(
                select(selectors.getPaymentErrors)
            );
    }

    public getMappedError$(): Observable<OLO.Components.IMappedMessage> {
        return this._store
            .pipe(
                select(selectors.getPaymentErrorsMapped)
            );
    }

    public getMappedRecalcError$(): Observable<OLO.Components.IMappedMessage> {
        return this._store
            .pipe(
                select(selectors.hasRecalculateFailedErrorMapped),
            );
    }

    public isPaying$(): Observable<boolean> {
        return this._store
            .pipe(
                select(selectors.isPaying)
            );
    }

    public isPaymentComplete$(): Observable<boolean> {
        return this._store
            .pipe(
                select(selectors.isPaymentComplete)
            );
    }

    public paymentProvider(): OLO.Enums.PAYMENT_PROVIDER {
        return this._config.paymentProvider;
    }

    public resetPaymentFlow(): void {
        this._paymentService.resetPaymentFlow();
    }
}
