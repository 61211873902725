import { Injectable } from '@angular/core';
import { CanActivate, CanActivateChild, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Store } from '@ngrx/store';

import * as State from '@shared/state';
import * as Services from '@shared/core/services';
import * as actions from '@shared/state/actions';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class AuthorizationGuard implements CanActivate, CanActivateChild {
    constructor(
        private _store: Store<State.IStateShared>,
        private _authService: Services.AuthService,
        private _routeService: Services.RouteService,
        private _modalsService: Services.ModalsService,
    ) { }
    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean> | Promise<boolean> | boolean {
        return this._authService.isAuthorized$()
            .pipe(
                map(isAuthorized => {
                    if (!isAuthorized) {
                        this._store.dispatch(actions.MemberSignOut({ redirect: '/', resetCart: false }));

                        Promise.resolve(true)
                            .then(() => this._routeService.navigateToHomeView())
                            .then(() => this._modalsService.show({
                                type: 'auth'
                            }));

                        return false;
                    }

                    return true;
                })
            );
    }

    public canActivateChild(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        return this.canActivate(next, state);
    }
}
