import { createSelector, createFeatureSelector } from '@ngrx/store';
import { IStateShared, IOrderType } from '../interface';

const state = createFeatureSelector<IStateShared, IOrderType[]>('orderTypes');

export const getOrderTypesForLocation = (locationNo: number) => createSelector(
    state,
    orderTypes => orderTypes.find(obj => obj.locationNo === locationNo)
);

export const getOrderTypesForAllLocations = createSelector(
    state,
    orderTypes => orderTypes
);

export const isDownloadingAnyOrderTypes = createSelector(
    state,
    orderTypes => orderTypes.some(obj => obj.isDownloading === true)
);
