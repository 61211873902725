import { Injectable, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Store, select } from '@ngrx/store';

import * as actions from '@shared/state/actions';

import * as Tokens from '@shared/core/tokens';
import * as State from '@shared/state/interface';
import * as Utils from '@shared/core/utils';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { LocationsMapper } from '@shared/core/mappers/locations.shared.mapper';

@Injectable({
    providedIn: 'root',
})
export class LocationsService {
    constructor(
        @Inject(Tokens.CONFIG_TOKEN) public config: IConfig,
        public httpClient: HttpClient,
        public store: Store<State.IStateShared>,
        public router: Router,
    ) {
    }

    public getLocationsWithOnlineOrdering(customParams: APICommon.ITerminalsOnlineOrderingLocationsGetParams = {}):
    Observable<APICommon.IOnlineOrderingLocationBusinessModel[]> {
        const defaultParams: APICommon.ITerminalsOnlineOrderingLocationsGetParams = {
            duration: APICommon.ORDERING_TIME_INFO_DURATION.DAY
        };

        const params: HttpParams = new HttpParams({
            fromObject: ({ ...defaultParams, ...customParams } as any)
        });

        return this.httpClient
            .get<APIv3.TerminalsGetOnlineOrderingLocations.Responses.$200>(`${this.config.api.base}/terminals/onlineOrderingLocations`, { params })
            .pipe(
                map((response: APIv3.TerminalsGetOnlineOrderingLocations.Responses.$200) => LocationsMapper.mapGetLocationsWithOnlineOrdering(response))
            );
    }

    public requestLocations(): void {
        this.store.dispatch(actions.LocationsRequest({
            venueNo: this.config.venue ? this.config.venue.id : null,
            dateToCheck: Utils.Dates.getLocalISOFormatDate(new Date(), false), /* Without Z - will not return TODAY if true */
            duration: APICommon.ORDERING_TIME_INFO_DURATION.MONTH,
        }));
    }
}
