import { createReducer, on, Action } from '@ngrx/store';
import * as actions from './free-products-images.actions';
import { IFreeProductImage } from './free-products-images.interface';

const initialState: IFreeProductImage[] = [];

export const freeProductsImagesReducerFn = createReducer<IFreeProductImage[]>(
    initialState,
    on(
        actions.FreeProductImageRequest,
        (state, action) => {
            let found: boolean = false;
            const recalculatedState: IFreeProductImage[] = state.reduce((acc, item) => {
                if (item.ProductId === action.productId) {
                    found = true;
                    return [
                        ...acc,
                        {
                            ...item,
                            isDownloading: true,
                            hasSucceeded: false,
                            hasFailed: false,
                        }
                    ];
                }
                return [
                    ...acc,
                    item,
                ];
            }, []);

            return found ? recalculatedState : [
                ...state,
                {
                    ProductId: action.productId,
                    isDownloading: true,
                    hasSucceeded: false,
                    hasFailed: false,
                    downloadedDate: null,
                    data: null,
                }
            ];
        }
    ),
    on(
        actions.FreeProductImageSuccessRequest,
        (state, action) => state.map(obj => {
            if (obj.ProductId === action.productId) {
                return {
                    ...obj,
                    isDownloading: false,
                    hasSucceeded: true,
                    hasFailed: false,
                    downloadedDate: new Date().getTime(),
                    data: action.payload || null,
                };
            }

            return obj;
        })
    ),
    on(
        actions.FreeProductImageErrorRequest,
        (state, action) => state.map(obj => {
            if (obj.ProductId === action.productId) {
                return {
                    ...obj,
                    isDownloading: false,
                    hasSucceeded: false,
                    hasFailed: true,
                };
            }

            return obj;
        })
    ),
);

export function freeProductsImagesReducer(state: IFreeProductImage[] | undefined, action: Action) {
    return freeProductsImagesReducerFn(state, action);
}
