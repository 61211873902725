import { createReducer, on, Action } from '@ngrx/store';
import * as actions from './google.actions';
import { IGoogle } from './google.interface';

const initialState: IGoogle = {
    places: {
        isDownloading: false,
        hasSucceeded: false,
        hasFailed: false,
        search: null,
        downloadedDate: null,
        data: null,
        hasInit: false,
    }
};

export const googleReducerFn = createReducer<IGoogle>(
    initialState,
    on(
        actions.GooglePlaceSearchRequest,
        (state, action) => ({
            ...state,
            places: {
                ...state.places,
                isDownloading: true,
                hasSucceeded: false,
                hasFailed: false,
                search: action.search,
            }
        })
    ),
    on(
        actions.GooglePlaceSearchSuccessRequest,
        (state, action) => ({
            ...state,
            places: {
                ...state.places,
                isDownloading: false,
                hasSucceeded: true,
                hasFailed: false,
                downloadedDate: new Date().getTime(),
                data: action.payload,
            }
        })
    ),
    on(
        actions.GooglePlaceSearchErrorRequest,
        (state, action) => ({
            ...state,
            places: {
                ...state.places,
                isDownloading: false,
                hasSucceeded: false,
                hasFailed: true,
            }
        })
    ),
    on(
        actions.GoogleInitSuccess,
        (state, action) => ({
            ...state,
            places: {
                ...state.places,
                hasInit: true,
            }
        })
    ),
);

export function googleReducer(state: IGoogle | undefined, action: Action) {
    return googleReducerFn(state, action);
}
