import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Store, select } from '@ngrx/store';

import * as selectors from '@shared/state/selectors';

import * as Services from '@shared/core/services';
import * as State from '@shared/state';

import { Observable } from 'rxjs';
import { take, tap, filter, withLatestFrom, map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class CheckoutGuard implements CanActivate {
    constructor(
        private _router: Router,
        private _onlineOrdersService: Services.OnlineOrdersService,
        private _store: Store<State.IStateShared>,
    ) { }

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean> | Promise<boolean> | boolean {
        return this._store
            .pipe(
                select(selectors.hasAppInitialized),
                filter(hasInitialized => hasInitialized === true),
                take(1),
                withLatestFrom(
                    this._store
                        .pipe(
                            select(selectors.canNavigateToCheckoutUnauthorized)
                        )
                ),
                map(([hasInitialized, canActivate]) => canActivate),
                tap(canNavigate => {
                    if (canNavigate) {
                        return this._onlineOrdersService.recalculateOrderAction();
                    }

                    this._router.navigate(['/']);
                }),
            );
    }
}
