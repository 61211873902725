import { createSelector, createFeatureSelector, MemoizedSelector } from '@ngrx/store';
import { IStateShared, IOrderTypeImage } from '../interface';

const state = createFeatureSelector<IStateShared, IOrderTypeImage[]>('orderTypesImages');

export const getOrderTypeImages = createSelector(
    state,
    orderTypesImages => orderTypesImages
);

export const getOrderTypeImage = (orderTypeId: number) => createSelector(
    state,
    orderTypesImages => orderTypesImages.find(image => image.Id === orderTypeId)
);
