import { Action, createAction, props } from '@ngrx/store';
import { ICart, ICartMenuFlow, ICartSimpleItem, ICartSimpleItemExtended, ICartMenuFlowExtended } from '../interface';

/* General */
export const CartPickupTimeUpdate = createAction(
    '[Cart] Update pickup time',
    props<{ modalId: number; locationNo: number; pickupTime: OLO.Ordering.IPickupTime; }>(),
);

export const CartSetup = createAction(
    '[Cart] Setup and validate active orders crap AOLO-289',
    props<{ modalId: number; locationNo: number; item: ICartMenuFlow | ICartSimpleItem; }>(),
);

export const CartSetupWithMultipleItems = createAction(
    '[Cart] Setup and validate active orders from reorder modal',
    (modalId: number, locationNo: number, menuFlows: ICartMenuFlow[] = [], simpleItems: ICartSimpleItem[] = []) => ({ modalId, locationNo, menuFlows, simpleItems })
);

export const CartTransferItemRequest = createAction(
    '[Cart] Transfer item request',
    props<{ modalId: number; locationNo: number; item: ICartMenuFlow | ICartSimpleItem; }>(),
);

export const CartActiveOrderContinue = createAction(
    '[Cart] Active order - continue',
    props<{ modalId: number; }>(),
);

export const CartActiveOrderStartNew = createAction(
    '[Cart] Active order - start new order',
    props<{ modalId: number; locationNo: number; }>(),
);

export const CartLoad = createAction(
    '[Cart] Load cart contents from browser storage',
    props<{ cart: ICart; }>(),
);

// export const CartSetPendingClear = createAction('[Cart] Set pending clear'); /* DO WE NEED THAT? */

export const CartSetLocationNo = createAction(
    '[Cart] Set locationNo',
    (locationNo: number = null) => ({ locationNo }),
);

export const CartSetOnlineMenu = createAction(
    '[Cart] Set online menu time',
    props<{ onlineMenu: APICommon.IOnlineMenuResponseModel; }>(),
);

export const CartSetPickupTime = createAction(
    '[Cart] Set pickup time',
    (pickupTime: OLO.Ordering.IPickupTime = null) => ({ pickupTime })
);

export const CartReset = createAction('[Cart] Restore entire cart to initial state');

export const CartRemoveAllItems = createAction('[Cart] Remove all products and menuflows');

export const CartEditItem = createAction(
    '[Cart] Edit item',
    props<{ item: ICartMenuFlow | ICartSimpleItem; }>(),
);

/* Products */
export const CartSimpleItemAdd = createAction(
    '[Cart] Add simple item',
    props<{ locationNo: number; item: ICartSimpleItem & ICartSimpleItemExtended; }>(),
);

export const CartSimpleItemUpdate = createAction(
    '[Cart] Update simple item props/values',
    props<{ item: ICartSimpleItem; }>(),
);

export const CartSimpleItemDecrement = createAction(
    '[Cart] Decrement simple item',
    (tempItemId: number, changeValue: number = 1) => ({ tempItemId, changeValue }),
);

export const CartSimpleItemIncrement = createAction(
    '[Cart] Increment simple item',
    (tempItemId: number, changeValue: number = 1) => ({ tempItemId, changeValue }),
);

export const CartSimpleItemRemove = createAction(
    '[Cart] Remove simple item',
    props<{ tempItemId: number; }>(),
);


/* Menu flows */
export const CartMenuFlowAdd = createAction(
    '[Cart] Add menu flow item',
    props<{ locationNo: number; item: ICartMenuFlowExtended; }>(),
);

export const CartMenuFlowAddWithMerge = createAction(
    '[Cart] Add menu flow item but check if there is any identical in cart' /* AOLO-282 */,
    props<{ locationNo: number; item: ICartMenuFlowExtended; }>(),
);

export const CartMenuFlowUpdate = createAction(
    '[Cart] Update menu flow props/values',
    props<{ item: ICartMenuFlowExtended; }>(),
);

export const CartMenuFlowDecrement = createAction(
    '[Cart] Decrement menu flow item',
    (tempId: number, changeValue: number = 1) => ({ tempId, changeValue }),
);

export const CartMenuFlowIncrement = createAction(
    '[Cart] Increment menu flow item',
    (tempId: number, changeValue: number = 1) => ({ tempId, changeValue }),
);

export const CartMenuFlowRemove = createAction(
    '[Cart] Remove menu flow item',
    props<{ tempId: number; }>(),
);
