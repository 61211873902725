import { createSelector, createFeatureSelector } from '@ngrx/store';
import { IStateShared, IAvailablePickup } from '../interface';

const state = createFeatureSelector<IStateShared, IAvailablePickup[]>('availablePickups');

export const getAvailablePickupTimesForLocation = (locationNo: number) => createSelector(
    state,
    availablePickups => availablePickups.find(obj => obj.locationNo === locationNo)
);

export const getAvailablePickupTimesForAllLocations = createSelector(
    state,
    availablePickups => availablePickups
);

export const hasAlreadyCalculatedPickupTimesForSomeLocations = createSelector(
    state,
    availablePickups =>
        availablePickups.some(item => item.calculatedDate !== null)
);

export const isCalculatingAvailablePickups = createSelector(
    state,
    availablePickups => availablePickups.some(obj => obj.isCalculating === true)
);
