import { IConvergeSettingsResponse } from '@shared/state';

export class ConvergePaymentProviderMapper {
    public static mapConvergeSettingsToAuthModel(convergeSettings: APIv3.ConvergeSettingsResponse): IConvergeSettingsResponse {
        return convergeSettings ? {
            ApiUrl: convergeSettings.ApiUrl,
            MechantId: convergeSettings.MechantId,
            UserId: convergeSettings.UserId,
            Pin: convergeSettings.Pin,
            VendorId: convergeSettings.VendorId,
            SessionToken: convergeSettings.SessionToken ? {
                SessionToken: convergeSettings.SessionToken.SessionToken
            } : null,
        } : null;
    }
}
