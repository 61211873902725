import { createAction, props } from '@ngrx/store';
import { IModal, MODAL_ANIMATION, IModalSwap, MODAL_TYPE } from '../interface';

export const ModalRequestClose = createAction(
    '[Modal] Request close modal',
    (modalId: number = null) => ({ modalId }),
);

export const ModalOpen = createAction(
    '[Modal] Open',
    (modal: IModal) => {
        return {
            modal: {
                ...modal,
                id: modal.id || Math.floor(Math.random() * 10000) + new Date().getTime()
            }
        };
    }
);

export const ModalClose = createAction(
    '[Modal] Close',
    props<{ id: number }>(),
);

export const ModalCloseAll = createAction(
    '[Modal] Close all',
    (typesExcludedFromClosing: MODAL_TYPE[] = [], animation: MODAL_ANIMATION = MODAL_ANIMATION.OUT) => ({ typesExcludedFromClosing, animation })
);

export const ModalAnimate = createAction(
    '[Modal] Animate',
    (id: number, animation: MODAL_ANIMATION = MODAL_ANIMATION.IN) => ({ id, animation })
);

/* Can be handeled on app level */
export const ModalBackgroundClicked = createAction('[Modal] Background clicked');

export const ModalSetLoading = createAction(
    '[Modal] Set loading',
    (id: number, isLoading: boolean = false) => ({ id, isLoading }),
);

export const ModalSwap = createAction(
    '[Modal] Swap',
    (modalId: number, details: IModalSwap = {}) => ({ modalId, details })
);
