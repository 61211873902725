export class Devices {
    public static isMobile(): boolean {
        /* https://coderwall.com/p/i817wa/one-line-function-to-detect-mobile-devices-with-javascript */
        return (typeof window.orientation !== 'undefined') || (navigator.userAgent.indexOf('IEMobile') !== -1);
    }

    public static isIOS(): boolean {
        /* https://stackoverflow.com/questions/29001977/safari-in-ios8-is-scrolling-screen-when-fixed-elements-get-focus */
        const iDevices: string[] = [
            'iPad Simulator',
            'iPhone Simulator',
            'iPod Simulator',
            'iPad',
            'iPhone',
            'iPod'
        ];
        while (iDevices.length) {
            if (navigator.platform === iDevices.pop()) {
                return true;
            }
        }
        return false;
    }

    public static isIE(): boolean {
        const ua: string = window.navigator.userAgent;

        const msie: number = ua.indexOf('MSIE ');
        if (msie > 0) {
            // IE 10 or older => return version number
            return !!parseInt(ua.substring(msie + 5, ua.indexOf('.', msie)), 10);
        }

        const trident: number = ua.indexOf('Trident/');
        if (trident > 0) {
            // IE 11 => return version number
            let rv = ua.indexOf('rv:');
            return !!parseInt(ua.substring(rv + 3, ua.indexOf('.', rv)), 10);
        }

        // const edge: number = ua.indexOf('Edge/');
        // if (edge > 0) {
        //     // Edge (IE 12+) => return version number
        //     return !!parseInt(ua.substring(edge + 5, ua.indexOf('.', edge)), 10);
        // }

        // other browser
        return false;
    }
}
