import { createReducer, on, Action } from '@ngrx/store';
import * as actions from './history-orders.actions';
import { IHistoryOrderState, IOnlineOrderStateItemObj } from './history-orders.interface';

const initialState: IHistoryOrderState = {
    isDownloading: false,
    hasSucceeded: false,
    hasFailed: false,
    downloadedDate: null,
    orders: []
};

export const historyOrdersReducerFn = createReducer(
    initialState,
    on(
        actions.HistoryOrdersReset,
        (state, action) => ({
            isDownloading: false,
            hasSucceeded: false,
            hasFailed: false,
            downloadedDate: null,
            orders: [],
        })
    ),
    on(
        actions.HistoryOrdersRequest,
        (state, action) => ({
            ...state,
            isDownloading: true,
            hasSucceeded: false,
            hasFailed: false,
        })
    ),
    on(
        actions.HistoryOrdersSuccessRequest,
        (state, action) => {
            const mapIds = action.payload.map(order => order.Id);
            const oldToUpdate = [];
            const newToAdd = [];
            for (let i = 0, j = mapIds.length; i < j; i++) {
                if (state.orders.find(order => order.OrderId === mapIds[i])) {
                    oldToUpdate.push(mapIds[i]);
                } else {
                    newToAdd.push(mapIds[i]);
                }
            }

            return {
                ...state,
                isDownloading: false,
                hasSucceeded: true,
                hasFailed: false,
                downloadedDate: new Date().getTime(),
                orders: [
                    ...state.orders.map(order => {
                        if (oldToUpdate.includes(order.OrderId)) {
                            return {
                                ...order,
                                data: action.payload.find(obj => obj.Id === order.OrderId),
                                isDownloading: false,
                                hasSucceeded: true,
                                hasFailed: false,
                                downloadedDate: new Date().getTime(),
                                OrderId: order.OrderId,
                            };
                        }
                        return order;
                    }),
                    ...newToAdd.map(id => ({
                        OrderId: id,
                        isDownloading: false,
                        hasSucceeded: true,
                        hasFailed: false,
                        downloadedDate: new Date().getTime(),
                        data: action.payload.find(obj => obj.Id === id),
                    }))
                ]
            };
        }
    ),
    on(
        actions.HistoryOrdersErrorRequest,
        (state, action) => ({
            ...state,
            isDownloading: false,
            hasSucceeded: false,
            hasFailed: true,
        })
    ),

    /* Single */
    on(
        actions.HistoryOrderRequest,
        (state, action) => {
            const foundOrder: IOnlineOrderStateItemObj = state.orders.find(order => order.OrderId === action.orderId);

            if (!foundOrder) {
                return {
                    ...state,
                    orders: [
                        ...state.orders,
                        {
                            OrderId: action.orderId,
                            isDownloading: true,
                            hasSucceeded: false,
                            hasFailed: false,
                            data: null,
                        }
                    ]
                };
            }

            return {
                ...state,
                orders: state.orders.map(order => {
                    if (order.OrderId === action.orderId) {
                        return {
                            ...order,
                            isDownloading: true,
                            hasSucceeded: false,
                            hasFailed: false,
                        };
                    }

                    return order;
                })
            };
        }
    ),
    on(
        actions.HistoryOrderSuccessRequest,
        (state, action) => ({
            ...state,
            orders: state.orders.map(order => {
                if (order.OrderId === action.orderId) {
                    return {
                        ...order,
                        isDownloading: false,
                        hasSucceeded: true,
                        hasFailed: false,
                        downloadedDate: new Date().getTime(),
                        data: action.payload
                    };
                }

                return order;
            })
        })
    ),
    on(
        actions.HistoryOrderErrorRequest,
        (state, action) => ({
            ...state,
            orders: state.orders.map(order => {
                if (order.OrderId === action.orderId) {
                    return {
                        ...order,
                        isDownloading: false,
                        hasSucceeded: false,
                        hasFailed: true,
                    };
                }
                return order;
            })
        })
    ),

);

export function historyOrdersReducer(state: IHistoryOrderState | undefined, action: Action) {
    return historyOrdersReducerFn(state, action);
}
