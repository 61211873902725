import { createReducer, on, Action } from '@ngrx/store';
import { IProductRestrictions } from '@shared/state';
import * as actions from './product-restrictions.actions';

const initialState: IProductRestrictions = {
    isDownloading: false,
    hasSucceeded: false,
    hasFailed: false,
    LocationNo: null,
    DateToCheck: null,
    data: null
};

export const productRestrictionsReducerFn = createReducer(
    initialState,
    on(
        actions.ProductRestrictionsRequest,
        (state, action) => {
            return {
                ...state,
                isDownloading: true,
                hasSucceeded: false,
                hasFailed: false,
                LocationNo: action.params.locationNo,
                DateToCheck: action.params.dateToCheck,
                data: null
            };
        }
    ),
    on(
        actions.ProductRestrictionsSuccessRequest,
        (state, action) => {
            return {
                ...state,
                isDownloading: false,
                hasSucceeded: true,
                hasFailed: false,
                data: action.payload
            };
        }
    ),

    on(
        actions.ProductRestrictionsErrorRequest,
        (state, action) => {
            return {
                ...state,
                isDownloading: false,
                hasSucceeded: true,
                hasFailed: false,
                LocationNo: null,
                DateToCheck: null,
                data: null
            };
        }
    ),
);

export function productRestrictionsReducer(state: IProductRestrictions | undefined, action: Action) {
    return productRestrictionsReducerFn(state, action);
}
