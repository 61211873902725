export class Scroll {
    public static keys = { 37: 1, 38: 1, 39: 1, 40: 1 };

    public static preventDefault(e: Event) {
        e = e || window.event;

        if (e.preventDefault) {
            e.preventDefault();
        }

        e.returnValue = false;
    }

    public static preventDefaultForScrollKeys(e) {
        if (Scroll.keys[e.keyCode]) {
            Scroll.preventDefault(e);
            return false;
        }
    }

    public static disableScroll() {
        if (window.addEventListener) {
            window.addEventListener('DOMMouseScroll', Scroll.preventDefault, false);
        }
        window.onwheel = Scroll.preventDefault; // modern standard
        window.onmousewheel = (document as any).onmousewheel = this.preventDefault; // older browsers, IE
        window.ontouchmove = Scroll.preventDefault; // mobile
        document.onkeydown = Scroll.preventDefaultForScrollKeys;
    }

    public static enableScroll() {
        if (window.removeEventListener) {
            window.removeEventListener('DOMMouseScroll', Scroll.preventDefault, false);
        }

        window.onmousewheel = (document as any).onmousewheel = null;
        window.onwheel = null;
        window.ontouchmove = null;
        document.onkeydown = null;
    }

    public static get documentHeight(): number {
        return Math.max(document.body.scrollHeight, document.body.offsetHeight, document.documentElement.clientHeight, document.documentElement.scrollHeight, document.documentElement.offsetHeight);
    }

    public static smoothScrollTo(destination: number | HTMLElement, duration: number = 800, easing: string = 'easeInOutCubic', callback?: () => any) {
        /* https://pawelgrzybek.com/page-scroll-in-vanilla-javascript/ */
        const easings = {
            linear(t) {
                return t;
            },
            easeInQuad(t) {
                return t * t;
            },
            easeOutQuad(t) {
                return t * (2 - t);
            },
            easeInOutQuad(t) {
                return t < 0.5 ? 2 * t * t : -1 + (4 - 2 * t) * t;
            },
            easeInCubic(t) {
                return t * t * t;
            },
            easeOutCubic(t) {
                return (--t) * t * t + 1;
            },
            easeInOutCubic(t) {
                return t < 0.5 ? 4 * t * t * t : (t - 1) * (2 * t - 2) * (2 * t - 2) + 1;
            },
            easeInQuart(t) {
                return t * t * t * t;
            },
            easeOutQuart(t) {
                return 1 - (--t) * t * t * t;
            },
            easeInOutQuart(t) {
                return t < 0.5 ? 8 * t * t * t * t : 1 - 8 * (--t) * t * t * t;
            },
            easeInQuint(t) {
                return t * t * t * t * t;
            },
            easeOutQuint(t) {
                return 1 + (--t) * t * t * t * t;
            },
            easeInOutQuint(t) {
                return t < 0.5 ? 16 * t * t * t * t * t : 1 + 16 * (--t) * t * t * t * t;
            }
        };
        let timeout: boolean = false;
        const start: number = window.pageYOffset;
        const startTime: number = 'now' in window.performance ? performance.now() : new Date().getTime();
        const windowHeight: number = window.innerHeight || document.documentElement.clientHeight || document.getElementsByTagName('body')[0].clientHeight;

        let documentHeight: number = Scroll.documentHeight;
        let destinationOffset: number = typeof destination === 'number' ? destination : destination.offsetTop;
        let destinationOffsetToScroll: number = Math.round(documentHeight - destinationOffset < windowHeight ? documentHeight - windowHeight : destinationOffset);

        /* AOLO-174 FIX 1. */
        setTimeout(() => {
            timeout = true;
        }, duration);

        function scroll() {
            if (timeout) {
                return typeof callback === 'function' ? callback() : undefined;
            }

            const now: number = 'now' in window.performance ? performance.now() : new Date().getTime();
            const time: number = Math.min(1, ((now - startTime) / duration));
            const timeFunction: any = easings[easing](time);

            window.scroll(0, Math.ceil((timeFunction * (destinationOffsetToScroll - start)) + start));

            /*
                AOLO-174 FIX 2.
                I assume there might have been an issue pageYOffset values on some devices,
                which were floats instead of integers.
                To make sure I've added 'stop range' and timeout lock to prevent crazy behaviour.
            */
            const rangeMin: number = Math.floor(window.pageYOffset) - 1;
            const rangeMax: number = Math.ceil(window.pageYOffset) + 1;
            const targetDestination: number = Math.floor(destinationOffsetToScroll);

            if (targetDestination <= rangeMax && targetDestination >= rangeMin || timeout) {
                if (callback) {
                    callback();
                }
                return;
            }

            requestAnimationFrame(scroll);
        }

        if ('requestAnimationFrame' in window === false) {
            window.scroll(0, destinationOffsetToScroll);
            if (callback) {
                callback();
            }
            return;
        }

        scroll();
    }

    public static smoothScrollInnerElement(hostElement: HTMLElement, targetElement: HTMLElement | number, compensation: number = 0, duration = 1000): void {
        /* https://codepen.io/rebosante/pen/eENYBv */
        const easings = {
            easeInOutQuad(t, b, c, d) {
                t /= d / 2;
                if (t < 1) return c / 2 * t * t + b;
                t--;
                return -c / 2 * (t * (t - 2) - 1) + b;
            }
        };

        let startY: number = hostElement.scrollTop,
            startX: number = hostElement.scrollLeft,
            topOffset: number = typeof targetElement === 'number' ? targetElement as number : (targetElement as HTMLElement).offsetTop,
            changeY: number = topOffset - startY + compensation,
            changeX: number = typeof targetElement === 'number' ? 0 - startX + compensation : (targetElement as HTMLElement).offsetLeft - startX + compensation,
            currentTime: number = 0,
            increment: number = 20,
            timeout: boolean = false;

        setTimeout(() => {
            timeout = true;
        }, duration);

        function scroll() {
            if (timeout) return;

            currentTime += increment;

            if (typeof hostElement.scrollTo === 'function') {

                const posX: number = easings.easeInOutQuad(currentTime, startX, changeX, duration);
                const posY: number = easings.easeInOutQuad(currentTime, startY, changeY, duration);

                hostElement.scrollTo(posX, posY);


                if (currentTime < duration && timeout === false) {
                    setTimeout(scroll, increment);
                }

            } else {
                hostElement.scrollTop = topOffset;
                timeout = true;
            }
        }

        scroll();
    }

}
