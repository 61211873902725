import { Component } from '@angular/core';
import { trigger, transition, useAnimation } from '@angular/animations';

import * as animations from '@shared/core/animations';
import * as Services from '@shared/core/services';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    animations: [
        trigger('routeAnimations', [
            transition('* <=> *', [
                useAnimation(animations.fadeInOut)
            ])
        ]),
    ],
})
export class AppComponent {
    constructor(
        private _initService: Services.InitService,
    ) {
        this._initService.init();
    }
}
