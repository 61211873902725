import { createSelector, createFeatureSelector } from '@ngrx/store';
import { IGeolocation, IStateShared } from '../interface';

const state = createFeatureSelector<IStateShared, IGeolocation>('geolocation');

export const getGeolocationState = createSelector(
    state,
    geolocation => geolocation
);

export const getGeolocationCoords = createSelector(
    state,
    geolocation => geolocation?.data
);
