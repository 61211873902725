/*
    These controllers contain extended selectors and actions
    for components only!.
    These shouldn't be used by any services, guards, effects, interceptors, pipes, resolvers, validators, directives.
    If you think action from a controller should be used in one of these above, move this action to service,
    and reimplement it in controller and other service.
*/

import { Injectable } from '@angular/core';

import { AppSettingsController } from './appSettings';
import { CartController } from './cart';
import { CollectionTypeController } from './collectionType';
import { CreditCardsController } from './creditCards';
import { FiltersController } from './filters';
import { GeolocationController } from './geolocation';
import { GoogleController } from './google';
import { HistoryOrdersController } from './historyOrders';
import { ImagesController } from './images';
import { IngredientsController } from './ingredients';
import { LocationsController } from './locations';
import { LoyaltyController } from './loyalty';
import { MembersController } from './members';
import { MenuFlowsController } from './menuFlows';
import { ModalsController } from './modals';
import { OnlineMenuController } from './onlineMenu';
import { OnlineOrdersController } from './onlineOrders';
import { PaymentController } from './payment';
import { ProductRestrictionsController } from './productRestrictions';
import { RoutesController } from './routes';
import { TransactionsController } from './transactions';
import { WizzardController } from './wizzard';

@Injectable({
    providedIn: 'root'
})
export class Controllers {
    constructor(
        public appSettings: AppSettingsController,
        public cart: CartController,
        public collectionType: CollectionTypeController,
        public creditCards: CreditCardsController,
        public filters: FiltersController,
        public geolocation: GeolocationController,
        public google: GoogleController,
        public historyOrders: HistoryOrdersController,
        public images: ImagesController,
        public ingredients: IngredientsController,
        public locations: LocationsController,
        public loyalty: LoyaltyController,
        public members: MembersController,
        public menuFlows: MenuFlowsController,
        public modals: ModalsController,
        public onlineMenu: OnlineMenuController,
        public onlineOrders: OnlineOrdersController,
        public payment: PaymentController,
        public productRestrictions: ProductRestrictionsController,
        public routes: RoutesController,
        public transactions: TransactionsController,
        public wizzard: WizzardController,
    ) { }
}
