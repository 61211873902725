import * as State from '../../state';

export class MenuFlows {
    public static extractType(tagsArr: APICommon.ITagModel[]): State.MODAL_TYPE {
        let modalType: State.MODAL_TYPE = 'standard-menu-flow'; /* no tag */

        if (tagsArr) {
            const tags: string[] = tagsArr.map(tag => tag.Name);

            switch (true) {
                case tags.includes('Enhanced Item'):
                    modalType = 'enhanced-menu-flow';
                    break;
                case tags.includes('Step Through Item'):
                    modalType = 'step-through-menu-flow';
                    break;
                case tags.includes('Combined Pages Menu Flow'):
                    modalType = 'combined-pages-menu-flow';
                    break;
            }
        }

        return modalType;
    }
}
