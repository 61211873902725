import { NgModule, ErrorHandler } from '@angular/core';
import { ModuleWithProviders } from '@angular/core';
import { HTTP_INTERCEPTORS } from '@angular/common/http';

/*
** Common modules for all apps
*/
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { DevModule } from './core/components/dev';
/*
** Custom providers (services, guards, resolvers)
*/
import * as Interceptors from '@shared/core/interceptors';
import * as Tokens from '@shared/core/tokens';

@NgModule({
    imports: [
        CommonModule,
        HttpClientModule,
        DevModule,
    ],
    exports: [
        DevModule
    ],
})
export class SharedProvidersModule {
    static forRoot(dependency?: {
        data: IConfig;
        environment: IEnvironment;
        buildEnvironment: IEnvironment;
    }): ModuleWithProviders<SharedProvidersModule> {
        return {
            ngModule: SharedProvidersModule,
            providers: [
                {
                    provide: Tokens.CONFIG_TOKEN,
                    useValue: dependency.data
                },
                {
                    provide: Tokens.ENV_TOKEN,
                    useValue: dependency.environment
                },
                {
                    provide: Tokens.ENV_BUILD_TOKEN,
                    useValue: dependency.buildEnvironment
                },
                {
                    provide: HTTP_INTERCEPTORS,
                    useClass: Interceptors.ApiInterceptor,
                    multi: true
                },
                {
                    provide: ErrorHandler,
                    useClass: Interceptors.ErrorsHandlerSharedService,
                },
            ],
        };
    }
}
