import { Inject, Injectable } from '@angular/core';
import { Store, select } from '@ngrx/store';

import * as selectors from '@shared/state/selectors';
import * as actions from '@shared/state/actions';

import * as State from '@shared/state';
import * as Tokens from '@shared/core/tokens';
import * as Utils from '@shared/core/utils';

import { Observable, of } from 'rxjs';
import { delay, map, switchMap } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class GoogleController {
    constructor(
        @Inject(Tokens.CONFIG_TOKEN) private _config: IConfig,
        private _store: Store<State.IStateShared>,
    ) { }

    public getAutocompleteSuggestion(search: string): void {
        this._store.dispatch(actions.GooglePlaceSearchRequest({ search }));
    }

    public getAutocompleteResults$(): Observable<Array<APICommon.IGooglePlaceDetails>> {
        return this._store
            .pipe(
                select(selectors.getGooglePlacesResults),
                switchMap(results => this._store
                    .pipe(
                        select(selectors.getGeolocationCoords),
                        map(coords => {
                            const userCoords = Utils.Geolocation.extractCoords(coords, this._config?.google?.maps?.defaults?.center);
                            if(!userCoords) {
                                return results;
                            }

                            return results && results.sort((placeA, placeB) => {
                                const distanceA = Utils.Geolocation.getDistanceFromTo(userCoords, Utils.Geolocation.convertCoords(placeA?.geometry)) || 0;
                                const distanceB = Utils.Geolocation.getDistanceFromTo(userCoords, Utils.Geolocation.convertCoords(placeB?.geometry)) || 0;

                                return distanceA - distanceB;
                            }) || results;
                        })
                    ))
            );
    }

    public isAutocompleteEnabled$(): Observable<boolean> {
        return this._store
            .pipe(
                select(
                    selectors.getGeolocationState
                ),
                switchMap((state) => this._store
                    .pipe(
                        select(
                            selectors.isGoogleInit
                        ),
                        map(hasInitialized => hasInitialized === true && state?.hasSucceeded === true && state?.data !== null)
                    ))
            );
    }

    public isGoogleInit$(): Observable<boolean> {
        return this._store
            .pipe(
                select(selectors.isGoogleInit),
                delay(1000)
            );
    }
}
