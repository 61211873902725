import { Injectable, Inject } from '@angular/core';
import { Effect, Actions, ofType } from '@ngrx/effects';
import { Action, Store, select } from '@ngrx/store';

import * as actions from '../actions';
import * as selectors from '../selectors';

import * as Services from '@shared/core/services';
import * as Tokens from '@shared/core/tokens';

import * as StateModels from '../interface';

import { Observable, of, never, iif, timer } from 'rxjs';
import { switchMap, audit, withLatestFrom, map, take, combineLatest, filter } from 'rxjs/operators';

@Injectable()
export class PickupTimeValidateEffects {
    /*
        This will validate cart itself comparing to current date.
        - check cart.pickupTime orderTimeout and pickupTime if user hasn't spent too much time ordering,
        - check cart.onlineMenu dates and compare it to current date,
        - check if it's TODAY if there was anything in the cart from other days,

        Check it only if there is anything in the cart.
    */

    @Effect() public validateCartPickupTimeAndOnlineMenuTime$: Observable<Action> = this._actions$
        .pipe(
            ofType(
                actions.ROUTER_NAVIGATION,
                actions.WizzardValidate,
                actions.CartLoad,
                actions.CartSetLocationNo,
                actions.CartSetOnlineMenu,
                actions.CartEditItem,
                actions.CartMenuFlowAdd,
                actions.CartMenuFlowDecrement,
                actions.CartMenuFlowIncrement,
                actions.CartMenuFlowRemove,
                actions.CartMenuFlowUpdate,
                actions.CartSimpleItemAdd,
                actions.CartSimpleItemDecrement,
                actions.CartSimpleItemIncrement,
                actions.CartSimpleItemRemove,
                actions.CartSimpleItemUpdate
            ),
            audit(() => timer(10)),
            withLatestFrom(
                this._store.pipe(
                    select(selectors.getCart)
                ),
                this._store
                    .pipe(
                        select(selectors.isCollectionTypeDineIn(this._config))
                    )
            ),
            switchMap(([action, cartObj, isDineIn]) => {
                return iif(
                    () => cartObj.itemsMenuFlow.length === 0 && cartObj.itemsSimple.length === 0,
                    never(),
                    this.isPickupOrderTimeValid$()
                        .pipe(
                            switchMap((isValid) => {
                                return iif(
                                    () => isValid === true || isValid === false && cartObj.pickupTime?.IsAsap === true && isDineIn === true,
                                    never(),
                                    of(cartObj)
                                        .pipe(
                                            withLatestFrom(
                                                this._store.pipe(
                                                    select(selectors.getAllModals)
                                                )
                                            ),
                                            switchMap(([cart, modals]) => {
                                                /* Scenario when cart pickup time is invalid */
                                                console.error('Cart pickup time isn\'t valid any more!');

                                                this._modalsService.removeURLQueryParams();

                                                if (modals.length > 0) {
                                                    this._modalsService.closeAll(['alert'], StateModels.MODAL_ANIMATION.NONE); /* Close all modals except alerts */

                                                    this._modalsService.show({
                                                        type: 'alert',
                                                        animate: StateModels.MODAL_ANIMATION.NONE,
                                                        params: {
                                                            title: 'Your cart is empty',
                                                            body: `Some items you have added to the cart are no longer available. Pickup time has changed.`,
                                                            showButton: true,
                                                        }
                                                    });
                                                }

                                                return [
                                                    actions.CartReset(),
                                                    actions.AvailablePickupsCalculateRequest({ locationNo: cart.locationNo }), /* Generate new pickup times */
                                                    actions.WizzardUnmountAll(),
                                                ];
                                            })
                                        )
                                );
                            })
                        )
                );
            })
        );

    constructor(
        @Inject(Tokens.CONFIG_TOKEN) private _config: IConfig,
        private _actions$: Actions,
        private _store: Store<StateModels.IStateShared>,
        private _modalsService: Services.ModalsService,
        private _pickupsService: Services.PickupsService,
    ) { }

    public isPickupOrderTimeValid$(date: Date = new Date()): Observable<boolean> {
        return this._store
            .pipe(
                select(selectors.getCart),
                combineLatest(
                    this._store
                        .pipe(
                            select(selectors.getOrderingTimeInfoByCartLocation),
                            filter(obj => obj !== undefined && obj !== null),
                            take(1)
                        )
                ),
                take(1),
                map(([cart, orderingTimeInfo]) => this._pickupsService.validateSelectedPickupTimeObjForOnlineMenu(date, cart.pickupTime, cart.onlineMenu, orderingTimeInfo)),
            );
    }
}
