/* https://px5.docs.apiary.io/#introduction/authentication */
import { Injectable, Inject } from '@angular/core';

import { HttpClient } from '@angular/common/http';

import * as Tokens from '@shared/core/tokens';
import * as Utils from '@shared/core/utils';

import { Observable, throwError, of } from 'rxjs';
import { catchError, map, take } from 'rxjs/operators';
import { IPaymentExpressCardIdResponse, IPaymentExpressSettingsResponse } from '@shared/state';
import { PaymentExpressPaymentProviderMapper } from '@shared/core/mappers';

@Injectable({
    providedIn: 'root',
})
export class PaymentExpressPaymentProviderService {
    constructor(
        @Inject(Tokens.CONFIG_TOKEN) public config: IConfig,
        public httpClient: HttpClient,
    ) {
    }

    public getCardDetails(params: PPPaymentExpress.ICardIdParams): Observable<IPaymentExpressCardIdResponse> {
        const customParams: any = { ...params };
        delete customParams.sessionToken;

        return this.httpClient
            .get<APIv3.PaymentExpressCardIdResponse>(
            `${Utils.HTTP.switchApi(this.config.api.base)}/Payments/paymentExpress/session/${params.sessionToken}${Utils.HTTP.object2string(customParams)}`
        )
            .pipe(map((response: APIv3.PaymentExpressCardIdResponse) => PaymentExpressPaymentProviderMapper.mapGetCardDetails(response)));
    }

    public requestCardToken(locationNo: number = null, defaultSettings: IPaymentExpressSettingsResponse = null): Observable<IPaymentExpressSettingsResponse> {
        if (!locationNo) {
            if (!defaultSettings) return throwError('No default settings provided for PaymentExpress payment provider');

            return of(defaultSettings)
                .pipe(
                    take(1)
                );
        }

        return this.getSettingsForLocation(locationNo);
    }

    public getSettingsForLocation(locationNo: number): Observable<IPaymentExpressSettingsResponse> {
        return this.httpClient
            .get<APIv3.PaymentExpressSettingsResponse>(`${Utils.HTTP.switchApi(this.config.api.base)}/Payments/paymentExpress/settings/${locationNo}`)
            .pipe(
                map((response: APIv3.PaymentExpressSettingsResponse) => PaymentExpressPaymentProviderMapper.mapGetSettingsForLocation(response)),
                catchError(ex => {
                    console.error('LocationNo not provided', ex);

                    return throwError(ex);
                })
            );
    }
}
