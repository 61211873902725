import { createReducer, on, Action } from '@ngrx/store';
import * as actions from './loyalty-programs.actions';
import { ILoyaltyProgram } from './loyalty-programs.interface';

const initialState: ILoyaltyProgram[] = [];

export const loyaltyProgramsReducerFn = createReducer<ILoyaltyProgram[]>(
    initialState,
    on(
        actions.LoyaltyProgramsReset,
        () => ([])
    ),
    on(
        actions.LoyaltyProgramsRequest,
        (state, action) => ({
            ...state,
            isDownloading: true,
            hasSucceeded: false,
            hasFailed: false,
        })
    ),
    on(
        actions.LoyaltyProgramsSuccessRequest,
        (state, action) => ({
            ...state,
            isDownloading: false,
            hasSucceeded: true,
            hasFailed: false,
            downloadedDate: Date.now(),
            data: action.payload
        })
    ),
    on(
        actions.LoyaltyProgramsErrorRequest,
        (state, action) => ({
            ...state,
            isDownloading: false,
            hasSucceeded: false,
            hasFailed: true,
        })
    ),
);

export function loyaltyProgramsReducer(state: ILoyaltyProgram[] | undefined, action: Action) {
    return loyaltyProgramsReducerFn(state, action);
}
