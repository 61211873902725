import { Injectable, Inject } from '@angular/core';
import { Action, Store, select } from '@ngrx/store';
import { Effect, Actions, ofType } from '@ngrx/effects';

import * as actions from '../actions';
import * as selectors from '../selectors';

import * as Tokens from '@shared/core/tokens';

import * as StateModels from '../interface';

import { Observable } from 'rxjs';
import { switchMap, withLatestFrom, skipWhile } from 'rxjs/operators';

@Injectable()
export class TopBarEffects {
    @Effect() public onRouteChange$: Observable<Action> = this._actions$
        .pipe(
            ofType(actions.ROUTER_NAVIGATION),
            withLatestFrom(
                this._store.pipe(
                    select(selectors.getCurrentRoute),
                    skipWhile(route => route === null),
                ),
                (action, route) => route
            ),
            withLatestFrom(
                this._store.pipe(
                    select(selectors.routeIsLocationDetailsPage(this._config))
                )
            ),
            switchMap(([route, isOnLocationPage]) => {
                /*
                    TODO:
                    Cover more cases and show different back buttons
                */

                let showOrderMore: boolean = /(sign-in|sign-up|account\/profile|checkout)/gi.test(route.url);
                let showCartIcon: boolean = !(/(checkout)/gi.test(route.url) || route.urlAfterRedirects === '/checkout/summary');
                return [
                    actions.TopBarSetBackToStoreIconVisibility({ isVisible: showOrderMore }),
                    actions.TopBarSetBackToLandingIconVisibility({ isVisible: isOnLocationPage }),
                    actions.TopBarSetCartIconVisibility({ isVisible: showCartIcon }),
                ];
            })
        );


    constructor(
        @Inject(Tokens.CONFIG_TOKEN) private _config: IConfig,
        private _actions$: Actions,
        private _store: Store<StateModels.IStateShared>,
    ) { }
}
