import { Injectable } from '@angular/core';
import { Action, Store, select } from '@ngrx/store';
import { Effect, Actions, ofType } from '@ngrx/effects';

import * as actions from '../actions';
import * as selectors from '../selectors';

import * as State from '@shared/state/interface';
import * as Services from '@shared/core/services';
import * as Utils from '@shared/core/utils';

import * as StateModels from '../interface';

import { Observable, of, never } from 'rxjs';
import { mergeMap, map, catchError, switchMap, filter, take } from 'rxjs/operators';

@Injectable()
export class MenuFlowDefaultActivationsEffects {
    @Effect() public requestDefaultActivationOnMenuFlowDetailsForCartEditItem$: Observable<Action> = this._actions$
        .pipe(
            ofType(
                actions.CartEditItem
            ),
            switchMap((action) => {
                if (!action.item.MenuFlowId) return never();

                return this._store
                    .pipe(
                        select(selectors.getCartOnlineMenuItemById(action.item.MenuFlowId)),
                        take(1),
                        switchMap(cartOnlineMenu => {
                            const requireDefaultActivation: boolean = cartOnlineMenu.Tags ? Utils.Items.requireDefaultActivation(cartOnlineMenu) : true;
                            if (!requireDefaultActivation) return never();

                            return of(
                                actions.MenuFlowDefaultActivationRequest({ menuFlowId: action.item.MenuFlowId })
                            );
                        })
                    );
            })
        );


    @Effect() public requestDefaultActivationOnMenuFlowDetailsSuccessRequest$: Observable<Action> = this._actions$
        .pipe(
            ofType(
                actions.MenuFlowsDetailsSuccessRequest
            ),
            switchMap(action => {
                return this._store
                    .pipe(
                        select(selectors.getOnlineMenuItemById(action.payload.MenuFlowId)),
                        filter(onlineMenuItem => onlineMenuItem !== null),
                        take(1),
                        switchMap(onlineMenu => {
                            const requireDefaultActivation: boolean = onlineMenu.Tags ? Utils.Items.requireDefaultActivation(onlineMenu) : true;
                            if (!requireDefaultActivation) return never();

                            return of(
                                actions.MenuFlowDefaultActivationRequest({ menuFlowId: action.menuFlowId })
                            );
                        })
                    );
            })
        );

    @Effect() public onMenuFlowDefaultActivationsRequest$: Observable<Action> = this._actions$
        .pipe(
            ofType(actions.MenuFlowDefaultActivationRequest),
            mergeMap(({ menuFlowId }) =>
                this._menuFlowsService.getDefaultActivation(menuFlowId)
                    .pipe(
                        map(payload => actions.MenuFlowDefaultActivationSuccessRequest({ menuFlowId, payload })),
                        catchError(ex => of(actions.MenuFlowDefaultActivationErrorRequest({ menuFlowId, ex })))
                    )
            )
        );

    constructor(
        private _actions$: Actions,
        private _menuFlowsService: Services.MenuFlowsService,
        private _store: Store<StateModels.IStateShared>,
    ) { }

}
