import { Dates } from './dates.utils';
import { DateTimeRounder } from './date-time-rounder.utils';
import { LocatioOpenStatus } from './location-open-status';

import * as moment from 'moment';
import * as Models from '@shared/core/models';

export class Pickups {
    public static createTimeObj(
        isSchedule: boolean,
        forDate: Date,
        edgeDate: Date,
        index: number = null,
        isAsap: boolean = false,
        displayFormat: string = 'ddd, D MMM',
    ): OLO.Ordering.IPickupTime {
        const currentTime: Date = new Date(new Date().setSeconds(0, 0));

        const IsToday: boolean = Dates.datesDiffInDays(forDate, currentTime) === 0;
        const DayNo: number = Dates.datesDiffInDays(forDate, currentTime);

        return new Models.PickupModels().generate({
            Id: forDate.getTime(),
            Index: index,
            Name: Pickups.createPickupLabelName(isSchedule, 'common', forDate, isAsap, displayFormat),
            ShortName: `${Dates.hoursFromDate(forDate, true)}`,
            Date: new Date(forDate.getTime()),
            MinutesFromNow: Math.floor((forDate.getTime() - currentTime.getTime()) / (60 * 1000)),
            IsAsap: isAsap,

            Hour: `${Dates.hoursFromDate(forDate, false)}:00`, /* For comapring dates with online order */
            DateLocalISO: `${Dates.getLocalISOFormatDate(new Date(forDate.getTime()))}`,
            /* Local time iso string - similar stuff gets returned from our API so it will be usefull to compare this date with api dates */

            PlaceOrderTimeout: edgeDate ? new Date(edgeDate.getTime()) : null, /* For checking if user hasn't spent too much time placing an order */
            IsToday,
            DayNo,
        });
    }

    public static overrdrivePickupTimeObjToAsap(pickupTime: OLO.Ordering.IPickupTime): OLO.Ordering.IPickupTime {
        let pickupTimeObj = pickupTime;

        if (pickupTimeObj.IsAsap) {
            const asapDate: Date = new Date(new Date().setSeconds(0, 0) + pickupTime.MinutesFromNow * 60 * 1000);
            pickupTimeObj = new Models.PickupModels().generate({
                Id: asapDate.getTime(),
                Index: -1,
                Name: Pickups.createPickupLabelName(false, 'common', asapDate, true),
                ShortName: `${Dates.hoursFromDate(asapDate, true)}`,
                Date: new Date(asapDate.getTime()),
                MinutesFromNow: pickupTime.MinutesFromNow,
                IsAsap: true,

                Hour: `${Dates.hoursFromDate(asapDate, false)}:00`, /* For comapring dates with online order */
                DateLocalISO: `${Dates.getLocalISOFormatDate(new Date(asapDate.getTime()))}`,
                /* Local time iso string - similar stuff gets returned from our API so it will be usefull to compare this date with api dates */

                PlaceOrderTimeout: new Date(pickupTime.PlaceOrderTimeout.getTime()), /* For checking if user hasn't spent too much time placing an order */
                IsToday: Dates.datesDiffInDays(new Date(), asapDate) === 0,
                DayNo: Dates.datesDiffInDays(new Date(), asapDate),
            });
        }

        return pickupTimeObj;
    }

    public static isDateInFutureOrdersTimeRange(
        date: Date | string,
        futureOrdersMinDay: number = null,
        futureOrdersMaxDay: number = null,
    ): boolean {
        if (futureOrdersMinDay === null || futureOrdersMaxDay === null) return false;
        if (!date) return false;
        date = typeof date === 'string' ? Dates.createDate(date) : date as Date;

        const diff: number = Dates.datesDiffInDays(new Date(), date);

        return diff >= futureOrdersMinDay && diff <= futureOrdersMaxDay;

    }

    public static isFuturePickupTimeValid(
        pickupTime: OLO.Ordering.IPickupTime,
        timeInfo: APICommon.ILocationOrderingTimeInfoModel,
        futureOrdersMinDay: number = null,
        futureOrdersMaxDay: number = null,
    ) {
        /* Check if selected pickup time is in range of open +1 - close-1 hours */
        const startDate: Date = new Date(Dates.createDate(timeInfo.Date.split('T')
            .map((obj, index) => !index ? obj : timeInfo.OpeningTime)
            .join('T')).getTime() + 1 * 60 * 1000);
        const endDate: Date = new DateTimeRounder(Dates.createDate(timeInfo.Date.split('T')
            .map((obj, index) => !index ? obj : timeInfo.ClosingTime)
            .join('T')), 'down').roundByHour();

        const targetDate = Dates.createDate(pickupTime?.DateLocalISO || Dates.getLocalISOFormatDate(Dates.createDate(null)));

        const isInLocationTimeRange: boolean = Pickups.isDateInFutureOrdersTimeRange(targetDate, futureOrdersMinDay, futureOrdersMaxDay);

        return targetDate >= startDate && targetDate <= endDate && isInLocationTimeRange === true;
    }

    public static datesMatchByDayCallback(dateToCompare: string): (obj: any) => boolean {
        return (obj) => obj?.Date?.split('T')[0] === dateToCompare.split('T')[0];
    }

    public static getFilteredOrderingTimeInfo(location: APICommon.IOnlineOrderingLocationBusinessModel): APICommon.LocationOrderingTimeInfoExtendedModel[] {
        const now = new Date();

        const min = location.FutureOrderingMinDaysAhead;
        const max = location.FutureOrderingMaxDaysAhead;

        //
        //  Min = null or/and Max = null  -> No future ordering
        //  Min = 0 and Max = 0  -> Only today
        //  Min = 0 and Max >0  -> today and other days
        //  Min >= 1 and Max >=1  -> today not available and can order for future days only
        //
        const isFutureOrderingAllowed = min !== null && max !== null;
        const isTodayAllowed = min === 0 || min === null;

        const arr = location.OrderingTimeInfo?.reduce((acc, timeInfo, index) => {
            const openStatus: LocatioOpenStatus = new LocatioOpenStatus(timeInfo);
            if (!openStatus.isOpen()) {
                return acc;
            }

            const isToday: boolean = Dates.isToday(timeInfo.Date);

            switch (true) {
                case isToday && isTodayAllowed:
                    /* Min = 0 and Max = 0  -> Only today */
                    return [
                        ...acc,
                        timeInfo,
                    ];
                case isToday && !isTodayAllowed:
                    /* Min = 0 and Max = 0  -> Only today */
                    return acc;
                case !isToday && isFutureOrderingAllowed && Pickups.isDateInFutureOrdersTimeRange(timeInfo.Date, min, max):
                    /* Min = 0 and Max >0  -> today and other days */
                    /* Min >= 1 and Max >=1  -> today not available and can order for future days only */
                    return [
                        ...acc,
                        timeInfo,
                    ];
                default:
                    /* Min = null or/and Max = null  -> No future ordering */
                    return acc;
            }

        }, [] as APICommon.ILocationOrderingTimeInfoModel[]) || [];


        return arr.map(obj => ({
            ...obj,
            IsOpen: new LocatioOpenStatus(obj).isOpen(),
        }));
    }

    public static calcInitialTimesObj(
        locationNo: number,
        asapPickupMins: number,
        openingHours: APICommon.ILocationOrderingTimeInfoModel[],
        orderTimeoutBufferMins: number,
        startBufferMins: number,
        date: Date = new Date(),
    ): OLO.Ordering.IPickupForLocation {
        if (orderTimeoutBufferMins === undefined || orderTimeoutBufferMins === null ||
            startBufferMins === undefined || startBufferMins === null
        ) {
            throw new Error('OrderTimeoutBufferMins and startBufferMins must be provided');
        }

        const localDate: string = date instanceof Date ? Dates.getLocalISOFormatDate(date) : date;
        const openingHourObj: APICommon.ILocationOrderingTimeInfoModel = openingHours?.find(day => {
            const byDate = day.Date ? day.Date.split('T')[0] === localDate.split('T')[0] : false;
            const byDayOfWeek = day.DayOfWeek === date.getDay();

            return byDate || byDayOfWeek;
        });
        if (!openingHourObj) {
            /* No today ordering - location might be available in further days */
            return null;
        }

        const openingDateRaw: string = localDate.replace(/T.+/, ''); // openingHourObj.Date.replace(/T.+/, '');
        const totalMinimumPickupMins: number = asapPickupMins < startBufferMins ? startBufferMins : asapPickupMins; /* Approved by Karolina on 2nd April 2019 on RingCentral */

        let startTime: Date;
        let closeTime: Date;
        let minimumPickupTimeForAsap: Date = new Date(new Date(date.getTime()).setSeconds(0, 0)); /* CHECK FOR NEAREST OPEN TIME IF IS CLOSED NOW */
        let minimumPickupTime: Date;
        let maximumPickupTime: Date;

        /* Comparing type */
        /* IOS FIX? AOLO-462 */
        const arrDate: number[] = openingDateRaw.split('-').map(d => +d);
        const arrHoursOpen: number[] = openingHourObj.OpeningTime.split(':').map(d => +d);
        const arrHoursClose: number[] = openingHourObj.ClosingTime.split(':').map(d => +d);

        startTime = new Date(arrDate[0], arrDate[1] - 1, arrDate[2], arrHoursOpen[0], arrHoursOpen[1], arrHoursOpen[2]);
        closeTime = new Date(arrDate[0], arrDate[1] - 1, arrDate[2], arrHoursClose[0], arrHoursClose[1], arrHoursClose[2]);

        minimumPickupTime = new Date(new Date(date.getTime() + (totalMinimumPickupMins * 60 * 1000)).setSeconds(0, 0));
        maximumPickupTime = new Date(new Date(new Date(closeTime.getTime() - ((orderTimeoutBufferMins + asapPickupMins) * 60 * 1000))).setSeconds(0, 0));

        if (minimumPickupTime >= maximumPickupTime) {
            minimumPickupTime = new Date(maximumPickupTime.getTime());
        }


        /* AOLO-215 */
        const diff: number = startTime.getTime() - date.getTime();
        if (diff >= totalMinimumPickupMins) {
            /* add asapiPickupTimeMins to openHours */
            minimumPickupTime = new Date(new Date(startTime.getTime() + (asapPickupMins * 60 * 1000)).setSeconds(0, 0));
        }

        /* AOLO-215 */
        const diff2: number = closeTime.getTime() - date.getTime();
        if (diff2 >= totalMinimumPickupMins) {
            maximumPickupTime = new Date(new Date(new Date(closeTime.getTime()/*  - (asapPickupMins * 60 * 1000) */)).setSeconds(0, 0));
        }

        /* ASAP Check if location is open to adjust asap time */
        const locationOpenHour: number = Dates.createHoursIntFromDate(openingHourObj.OpeningTime);
        const locationCloseHour: number = Dates.createHoursIntFromDate(openingHourObj.ClosingTime);
        const nowHour: number = Dates.createHoursIntFromDate(date);
        const isOpen: boolean = nowHour >= locationOpenHour && nowHour <= locationCloseHour;
        const locationWillBeOpenToday: boolean = locationOpenHour > nowHour;
        const locationIsClosing = (minimumPickupTimeForAsap.getTime() + (totalMinimumPickupMins) * 60 * 1000) > maximumPickupTime.getTime();

        if (locationIsClosing === true && locationWillBeOpenToday === false) {
            return null;
        }

        if (!isOpen) {
            if (!locationWillBeOpenToday) {
                return null;
            }
            minimumPickupTimeForAsap = new Date(startTime.getTime() + asapPickupMins * 60 * 1000);
        }

        return {
            DayOfWeek: openingHourObj.DayOfWeek,
            DayName: Dates.getDayName(openingHourObj.DayOfWeek),
            LocationNo: locationNo,
            Date: localDate,
            IsOpen: isOpen,
            OpeningTime: startTime,
            ClosingTime: closeTime,
            MinimumPickupTimeForAsap: minimumPickupTimeForAsap,
            MinimumPickupTime: minimumPickupTime,
            MaximumPickupTime: maximumPickupTime,
        };
    }

    public static generatePickupTimesList(params: OLO.Ordering.IGeneratePickupsParams): OLO.Ordering.IPickupTime[] {
        const defaults: OLO.Ordering.IGeneratePickupsParams = {
            location: null,
            asapPickupMins: 0,
            openingHours: null,
            orderTimeoutBufferMins: 0,
            startBufferMins: 0,
            nextTick: 15,
            schedule: false,
        };

        const opts = {
            ...defaults,
            ...params,
        };
        if (opts.asapPickupMins === undefined || opts.asapPickupMins === null || !opts.openingHours) return [];
        const isFutureOrderingOnly = opts.schedule === true && opts.location !== null
            && opts.location.FutureOrderingMaxDaysAhead !== null
            && opts.location.FutureOrderingMinDaysAhead !== null
            && opts.location.FutureOrderingMinDaysAhead !== 0;
        if (isFutureOrderingOnly) return [];

        /* ASAP - AOLO-248 */
        const now = new Date();
        const initialAsapPickupValue = Pickups.calcInitialTimesObj(opts.location?.LocationNo || 0, opts.asapPickupMins, opts.openingHours, opts.orderTimeoutBufferMins, 0, now);
        if (!initialAsapPickupValue) return [];

        let asapPickup: Date = initialAsapPickupValue.MinimumPickupTimeForAsap;

        let arr: OLO.Ordering.IPickupTime[] = [];
        arr.push({
            Id: asapPickup.getTime(),
            Index: 1,
            Name: opts.schedule ? Pickups.createPickupLabelName(opts.schedule, 'common', asapPickup, true, opts.displayFormat) : `${Dates.hoursFromDate(asapPickup, true)}`,
            ShortName: `${Dates.hoursFromDate(asapPickup, true)}`,
            Date: asapPickup,
            MinutesFromNow: opts.asapPickupMins,
            IsAsap: initialAsapPickupValue.IsOpen, /* THIS WAS CHANGED! */
            Hour: `${Dates.hoursFromDate(asapPickup, false)}:00`,
            DateLocalISO: `${Dates.getLocalISOFormatDate(new Date(asapPickup.getTime()))}`,
            PlaceOrderTimeout: new Date(asapPickup.getTime() + opts.orderTimeoutBufferMins * 60 * 1000),
            IsToday: Dates.datesDiffInDays(initialAsapPickupValue.Date, now) === 0,
            DayNo: Dates.datesDiffInDays(initialAsapPickupValue.Date, now),
        });

        /* Later today */
        const initialLaterPickupValue = Pickups.calcInitialTimesObj(
            opts.location?.LocationNo || 0,
            opts.asapPickupMins,
            opts.openingHours,
            opts.orderTimeoutBufferMins,
            opts.startBufferMins,
            now);
        let nextLaterPickup: Date = new DateTimeRounder(initialLaterPickupValue.MinimumPickupTime, 'up').roundByQuarter();
        let nextLaterPlaceOrderTimeout: Date = new Date(nextLaterPickup.getTime() - ((opts.startBufferMins - opts.orderTimeoutBufferMins) * 60 * 1000));
        /* Timeout to place an order for selected pickup time */

        if (nextLaterPickup > initialLaterPickupValue.MaximumPickupTime) return arr;

        while (nextLaterPickup < initialLaterPickupValue.MaximumPickupTime) {

            if (nextLaterPickup > initialLaterPickupValue.MinimumPickupTimeForAsap) {
                arr.push(Pickups.createTimeObj(opts.schedule, nextLaterPickup, nextLaterPlaceOrderTimeout, arr.length + 1));
            }


            nextLaterPickup = new Date(nextLaterPickup.setMinutes(nextLaterPickup.getMinutes() + opts.nextTick));
            nextLaterPlaceOrderTimeout = new Date(nextLaterPlaceOrderTimeout.setMinutes(nextLaterPlaceOrderTimeout.getMinutes() + opts.nextTick));
        }

        return arr;
    }

    public static createPeriodObject(obj: APICommon.ILocationOrderingTimeInfoModel, format: string = 'ddd, D MMM', prefixes: boolean = true): OLO.Ordering.IPeriod {
        const normalizeDate = (date: string) => date.split('T')
            .map((key, index) => index === 0 ? key : /* `12:00:00.000` */obj?.ClosingTime ? obj.ClosingTime + '.000' : '12:00:00.000')
            .join('T');
        const now: Date = new Date();
        const newDate: string = normalizeDate(obj.Date);
        const Id = Dates.datesDiffInDays(now, newDate);

        let Name: string = '';
        if (prefixes && Id < 2) {
            if (Id === 0) {
                Name = 'Today, ';
            } else {
                Name = 'Tomorrow, ';
            }
        }

        Name += moment(Dates.createDate(newDate)).format(format);

        return {
            Id: Id + 1,
            DayName: Dates.getDayName(obj.DayOfWeek),
            DayOfWeek: obj.DayOfWeek,
            Date: newDate,
            IsToday: Id === 0,
            Name,
        };
    }

    public static generatePickupTimesFutureList(period: OLO.Ordering.IPeriod, params: OLO.Ordering.IGeneratePickupsParams): OLO.Ordering.IPickupTime[] {
        const now = new Date();
        const defaults: OLO.Ordering.IGeneratePickupsParams = {
            location: null,
            openingHours: null,
            orderTimeoutBufferMins: 60,
            startBufferMins: 60,
            nextTick: 60,
            displayFormat: 'ddd, D MMM',
        };

        const futureDefaults = {
            orderTimeoutBufferMins: 60,
            startBufferMins: 60,
            nextTick: 60,
        };

        const opts: OLO.Ordering.IGeneratePickupsParams = {
            ...defaults,
            ...params,
        };

        if (!opts.openingHours) return [];
        const openingHours: APICommon.ILocationOrderingTimeInfoModel = params.openingHours.find(obj => obj.Date.split('T')[0] === period.Date.split('T')[0]);
        let endDate: Date = Dates.createDate(period.Date.split('T').map((obj, index) => !index ? obj : openingHours.ClosingTime)
            .join('T'));
        const isToday: boolean = Dates.isToday(endDate);

        const startDate: Date = new Date(Dates.createDate(period.Date.split('T').map((obj, index) => !index ? obj : openingHours.OpeningTime)
            .join('T')).getTime() + (
            isToday ? opts.startBufferMins : futureDefaults.startBufferMins
        ) * 60 * 1000);

        if (openingHours.ClosingTime && openingHours.ClosingTime.split(':')[1] === '59') {
            endDate = new Date(endDate.getTime() + 1 * 60 * 60 * 1000);
        }

        let nextDate: Date = isToday ?
            new DateTimeRounder(startDate, 'up').roundByQuarter() :
            new DateTimeRounder(startDate, 'down').roundByHour();

        const arr: OLO.Ordering.IPickupTime[] = [];
        if (nextDate > endDate) return arr;

        while (nextDate.getTime() < endDate.getTime()) {
            arr.push(Pickups.createTimeObj(
                opts.schedule,
                nextDate,
                null,
                arr.length + 1,
                false,
                opts.displayFormat,
            ));

            const nextTick: number = Dates.isToday(nextDate) ? opts.nextTick : futureDefaults.nextTick;
            nextDate = new Date(nextDate.setMinutes(nextDate.getMinutes() + nextTick));
        }

        return arr.filter(obj => obj.Date > now);
    }

    public static createPickupLabelName(
        isSchedule: boolean,
        type: OLO.Types.PICKUP_LABEL_TYPES,
        date: Date | string,
        isAsap: boolean = false,
        format: string = 'ddd, D MMM',
        asapMinutesFromNow: number = null,
    ): string {
        if (!format) {
            format = 'ddd, D MMM';
        }
        if (typeof date === 'string') {
            date = Dates.createDate(date);
        }
        if (!(date instanceof Date)) {
            date = new Date();
            isAsap = true;
        }
        const currentTime: Date = new Date(new Date().setSeconds(0, 0));
        const daysDiff: number = Dates.datesDiffInDays(date, currentTime);
        let minutesFromNow: number = Math.floor(((date.getTime() || 0) - (currentTime.getTime() || 0)) / (60 * 1000));
        if (minutesFromNow < 0) {
            minutesFromNow = 0;
        }

        if (asapMinutesFromNow !== null) {
            minutesFromNow = asapMinutesFromNow;
        }

        let label: string = `${Dates.hoursFromDate(date, true)}`;
        if (!isSchedule) {
            if (isAsap) {
                switch (true) {
                    case type === 'common':
                        return 'ASAP';
                    case type === 'checkoutBox':
                        return `in ${minutesFromNow}mins`;
                    case type === 'location':
                        return minutesFromNow ? `ASAP (${minutesFromNow}mins)` : 'ASAP';
                    case type === 'filter':
                        return 'Now';
                }
            } else {
                switch (true) {
                    case type === 'common':
                        return `at ${label}`;
                    case type === 'checkoutBox':
                        return `at ${label}`;
                    case type === 'location':
                        return `${label}`;
                    case type === 'filter':
                        return `At ${label}`;
                }
            }

            return label;
        }

        if (type === 'orderConfirmation') {
            switch (true) {
                case daysDiff === 0:
                    return `Today at ${label}`;
                case daysDiff === 1:
                    return `Tomorrow at ${label}`;
                case daysDiff < 7:
                    return `${Dates.getDayName(date.getDay())} at ${label}`;
                default:
                    return `${moment(date).format(format || 'Do of D MMM')} at ${label}`;
            }
        }

        if (type === 'common') {
            switch (true) {
                case daysDiff === 0:
                    return `Today ${isAsap ? 'ASAP' : 'at ' + label}`;
                case daysDiff === 1:
                    return `Tomorrow at ${label}`;
                case daysDiff < 7:
                    return `This ${Dates.getDayName(date.getDay())} at ${label}`;
                default:
                    return `${moment(date).format(format || 'ddd, D MMM')} at ${label}`;
            }
        }

        if (type === 'checkoutBox') {
            switch (true) {
                case daysDiff === 0:
                    return `today ${isAsap ? `in ${minutesFromNow} min` : 'at ' + label}`;
                case daysDiff === 1:
                    return `tomorrow at ${label}`;
                case daysDiff < 7:
                    return `${Dates.getDayName(date.getDay())} at ${label}`;
                default:
                    return `${moment(date).format(format || 'ddd, D MMM')} at ${label}`;
            }
        }

        if (type === 'location') {
            switch (true) {
                case daysDiff === 0:
                    return `Today ${isAsap ? minutesFromNow ? `ASAP (${minutesFromNow}min)` : 'ASAP' : 'at ' + label}`;
                case daysDiff === 1:
                    return `Tomorrow at ${label}`;
                case daysDiff < 7:
                    return `${Dates.getDayName(date.getDay())} at ${label}`;
                default:
                    return `${moment(date).format(format || 'ddd, D MMM')} at ${label}`;
            }
        }

        if (type === 'filter') {
            switch (true) {
                case daysDiff === 0:
                    return isAsap ? 'Now' : `${label} today`;
                case daysDiff === 1:
                    return `${label} tomorrow`;
                case daysDiff < 7:
                    return `${label} ${Dates.getDayName(date.getDay())}`;
                default:
                    return `${label} ${moment(date).format(format || 'ddd, D MMM')}`;
            }
        }

        return 'undefined case';
    }

}
