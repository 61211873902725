import { createAction, props } from '@ngrx/store';

export const VenueImagesRequest = createAction(
    '[Venue images] Request',
    (ids: number[] = [], imageType: OLO.Enums.IMAGE_TYPE, width: number = 1000, height: number = 1000, ) => ({ ids, imageType, width, height })
);

export const VenueImagesSuccessRequest = createAction(
    '[Venue images] Success request',
    props<{ imageType: OLO.Enums.IMAGE_TYPE; ids: number[]; payload: APICommon.IImageUrlModel[]; }>(),
);

export const VenueImagesErrorRequest = createAction(
    '[Venue images] Error request',
    props<{ imageType: OLO.Enums.IMAGE_TYPE; ids: number[]; ex?: any; }>(),
);
