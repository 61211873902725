import { createReducer, on, Action } from '@ngrx/store';
import * as actions from './loyalty-introduction-pages.actions';
import { ILoyaltyIntroductionPages } from './loyalty-introduction-pages.interface';

const initialState: ILoyaltyIntroductionPages = {
    isDownloading: false,
    hasSucceeded: false,
    hasFailed: false,
    downloadedDate: null,
    data: null,
};

export const loyaltyIntroductionPagesReducerFn = createReducer<ILoyaltyIntroductionPages>(
    initialState,
    on(
        actions.LoyaltyIntroductionPagesRequest,
        (state, action) => ({
            ...state,
            isDownloading: true,
            hasSucceeded: false,
            hasFailed: false,
        })
    ),
    on(
        actions.LoyaltyIntroductionPagesSuccessRequest,
        (state, action) => ({
            ...state,
            isDownloading: false,
            hasSucceeded: true,
            hasFailed: false,
            downloadedDate: new Date().getTime(),
            data: [...action.payload]
        })
    ),
    on(
        actions.LoyaltyIntroductionPagesErrorRequest,
        (state, action) => ({
            ...state,
            isDownloading: false,
            hasSucceeded: false,
            hasFailed: true,
        })
    ),
);

export function loyaltyIntroductionPagesReducer(state: ILoyaltyIntroductionPages | undefined, action: Action) {
    return loyaltyIntroductionPagesReducerFn(state, action);
}
