export class Colors {
    public static isValidHex(hex: string): boolean {
        return /^#([A-Fa-f0-9]{3,4}){1,2}$/.test(hex);
    }

    public static getChunksFromString(string: string, chunkSize: number): RegExpMatchArray {
        return string.match(new RegExp(`.{${chunkSize}}`, 'g'));
    }

    public static convertHexUnitTo256(hexStr: string): number {
        return parseInt(hexStr, 16);
        // return parseInt(hexStr.repeat(2 / hexStr.length), 16);
    }

    public static getAlphafloat(a: number, alpha: number = undefined) {
        if (typeof a !== 'undefined') {
            return a / 256;
        }

        if (typeof alpha !== 'undefined') {
            if (1 < alpha && alpha <= 100) {
                return alpha / 100;
            }

            if (0 <= alpha && alpha <= 1) {
                return alpha;
            }
        }
        return 1;
    }

    public static hexToRGBA(hex: string, alpha: number = undefined): string {
        try {
            if (!Colors.isValidHex(hex)) throw new Error('Invalid HEX');

            const chunkSize: number = Math.floor((hex.length - 1) / 3);
            const hexArr: RegExpMatchArray = Colors.getChunksFromString(hex.slice(1), chunkSize);
            const [a, r, g, b]: number[] = hexArr.map(Colors.convertHexUnitTo256);

            return `rgba(${r}, ${g}, ${b}, ${Colors.getAlphafloat(a, alpha)})`;

        } catch (ex) {
            console.error('hexToRGBA', ex);
            return hex;
        }
    }
}
