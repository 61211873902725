import { Action, createAction, props } from '@ngrx/store';
import { IAlertMessage } from './alert-messages.interface';

export const AlertMessageOpen = createAction(
    '[Alert message] Open',
    props<{ message: IAlertMessage }>(),
);

export const AlertMessageClose = createAction(
    '[Alert message] Close',
    props<{ id: number }>(),
);

export const AlertMessageEdit = createAction(
    '[Alert message] Edit',
    (id: number, options: IAlertMessage = {}) => ({ id, options })
);

export const AlertMessageSetBulk = createAction(
    '[Alert message] set bulk',
    (messages: IAlertMessage[] = []) => ({ messages }),
);
