import { Injectable } from '@angular/core';
import { Action, Store, select } from '@ngrx/store';
import { Effect, Actions, ofType } from '@ngrx/effects';

import * as selectors from '../selectors';
import * as actions from '../actions';
import * as Services from '@shared/core/services';

import * as StateModels from '../interface';

import { Observable, of, never } from 'rxjs';
import { mergeMap, map, catchError, switchMap, take } from 'rxjs/operators';

@Injectable()
export class OrderTypesImagesEffects {
    @Effect() public onSuccessOrderTypeRequestImages$: Observable<Action> = this._actions$
        .pipe(
            ofType(
                actions.OrderTypesSuccessRequest
            ),
            switchMap(action => {
                return this._store
                    .pipe(
                        select(selectors.getOrderTypeImages),
                        take(1),
                        switchMap(images => {
                            const filterOut = action.payload.reduce((acc, orderType) => {
                                const imageExists = images.some(obj => obj.Id === orderType.Id && obj.data !== null && obj.isDownloading === false);
                                if (imageExists) return acc;

                                return [...acc, orderType.Id];
                            }, [] as number[]);

                            if (filterOut.length === 0) return never();

                            return of(
                                actions.OrderTypesImagesRequest({
                                    params: { width: 150, height: 150 },
                                    orderTypesIds: filterOut,
                                })
                            );
                        })
                    );
            })
        );

    @Effect() public requestImagesForOrderTypes$: Observable<Action> = this._actions$
        .pipe(
            ofType(
                actions.OrderTypesImagesRequest
            ),
            mergeMap(action => this._imagesService.getImageForOrderType(action.params, ...action.orderTypesIds)
                .pipe(
                    map(payload => actions.OrderTypesImagesSuccessRequest({
                        params: action.params,
                        payload,
                        orderTypesIds: action.orderTypesIds
                    })),
                    catchError(ex => {
                        console.error(`Error requestImagesForOrderTypes`, ex);
                        return of(
                            actions.OrderTypesImagesErrorRequest({
                                params: action.params,
                                ex,
                                orderTypesIds: action.orderTypesIds
                            })
                        );
                    })
                )
            )
        )
        ;

    constructor(
        private _actions$: Actions,
        private _imagesService: Services.ImagesService,
        private _store: Store<StateModels.IStateShared>,
    ) { }

}
