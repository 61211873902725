import { Injectable, Inject } from '@angular/core';
import { Store, select, Action } from '@ngrx/store';
import { Actions, Effect, ofType } from '@ngrx/effects';

import * as actions from '../actions';
import * as selectors from '../selectors';
import { CollectionTypeParams } from './collection-type.models';

import * as Utils from '@shared/core/utils';
import * as Services from '@shared/core/services';
import * as Tokens from '@shared/core/tokens';

import * as StateModels from '../interface';

import { Observable, never, of } from 'rxjs';
import { switchMap, withLatestFrom, auditTime, filter, take } from 'rxjs/operators';


@Injectable()
export class CollectionTypesEffects {
    private _dineInBuzzerId: number = this._config.collectionTypes?.dineIn?.dineInBuzzer?.orderTypeId || null;
    private _dineInTableNoId: number = this._config.collectionTypes?.dineIn?.dineInTable?.orderTypeId || null;
    private _buzzerImg: string = this._config.collectionTypes.dineIn?.dineInBuzzer?.modalIcon || null;
    private _tableImg: string = this._config.collectionTypes.dineIn?.dineInTable?.modalIcon || null;
    private _buzzerPreTitle: string = this._config.collectionTypes.dineIn?.dineInBuzzer?.modalPreTitle || null;
    private _tablePreTitle: string = this._config.collectionTypes.dineIn?.dineInTable?.modalPreTitle || null;
    private _buzzerDescription: string[] = this._config.collectionTypes.dineIn?.dineInBuzzer?.modalDescription || null;
    private _tableDescription: string[] = this._config.collectionTypes.dineIn?.dineInTable?.modalDescription || null;
    private _tableDescriptionSelected: string[] = this._config.collectionTypes.dineIn?.dineInTable?.modalDescriptionSelected || null;

    @Effect() public onChangeLocationSelectDefaultOrderTypeForLocation$: Observable<Action> = this._actions$
        .pipe(
            ofType(actions.CurrentLocationSet),
            withLatestFrom(
                this._store
                    .pipe(
                        select(selectors.getCurrentLocationNo)
                    ),
                this._store
                    .pipe(
                        select(selectors.getCollectionType)
                    ),
                this._store
                    .pipe(
                        select(selectors.isCollectionTypeDineIn(this._config))
                    )
            ),
            switchMap(([action, currentLocationNo, collection, isDineIn]) => this._store
                .pipe(
                    select(selectors.getCollectionTypesListForLocation(currentLocationNo, this._config)),
                    filter(obj => obj !== null),
                    take(1),
                    switchMap(availableCollectionsForLocation => {
                        let defaultCollectionTypeToSet = availableCollectionsForLocation?.find(obj => obj.OrderTypeId === collection.orderTypeId);
                        if (isDineIn) {
                            let foundDineInTypeInLocation = availableCollectionsForLocation?.find(obj => obj.GroupType === OLO.Enums.COLLECTION_TYPE.DINE_IN);
                            defaultCollectionTypeToSet = foundDineInTypeInLocation || defaultCollectionTypeToSet;
                        }

                        const collectionTypeParamObj: CollectionTypeParams = {
                            orderTypeId: null,
                            address: null,
                            tableNo: null,
                        };

                        if (defaultCollectionTypeToSet) {
                            collectionTypeParamObj.orderTypeId = defaultCollectionTypeToSet.OrderTypeId;
                        }

                        if (!defaultCollectionTypeToSet) {
                            if (availableCollectionsForLocation?.length) {
                                const firstAvailableCollectionInLocation = availableCollectionsForLocation[0];
                                collectionTypeParamObj.orderTypeId = firstAvailableCollectionInLocation.OrderTypeId;
                            }
                        }

                        if (collection.orderTypeId === collectionTypeParamObj.orderTypeId) return never();

                        return of(
                            actions.SetCollectionType(collectionTypeParamObj)
                        );
                    })
                ))
        );

    @Effect() public onCustomOrderTypeSelectedUpdateValuesInCollectionState$: Observable<Action> = this._actions$
        .pipe(
            ofType(
                actions.OnlineOrderTypeUpdateValues
            ),
            withLatestFrom(
                this._store
                    .pipe(
                        select(selectors.getSelectedOrderType)
                    )
            ),
            filter(([action, orderType]) => orderType !== null),
            switchMap(([action, orderType]) => {
                let arr: Action[] = [
                    actions.PatchOrderTypeIdCollectionTypeValue({ orderTypeId: orderType.Id }),
                ];

                if (this._isTableNoDineInType(orderType.Id) || this._isBuzzerDineInType(orderType.Id)) {
                    arr.push(
                        actions.PatchTableNoCollectionTypeValue({ tableNo: orderType.Details[0]?._Value || null }),
                    );
                } else {
                    arr = [
                        actions.SetCollectionType(new CollectionTypeParams(OLO.Enums.COLLECTION_TYPE.PICKUP))
                    ];
                }

                return arr;
            })
        );

    @Effect() public saveValuesInLocalStorage$: Observable<Action> = this._actions$
        .pipe(
            ofType(
                actions.PatchOrderTypeIdCollectionTypeValue,
                actions.PatchAddressCollectionTypeValue,
                actions.PatchTableNoCollectionTypeValue,
                actions.SetCollectionType
            ),
            auditTime(10),
            withLatestFrom(
                this._store
                    .pipe(
                        select(selectors.getCollectionType)
                    )
            ),
            switchMap(([action, state]) => {
                Utils.Storage.set(OLO.Enums.USER_STORAGE.COLLECTION_TYPE, state);

                return never();
            })
        );

    @Effect() public onCollectionTypeSet$: Observable<Action> = this._actions$
        .pipe(
            ofType(
                actions.SetCollectionType,
            ),
            withLatestFrom(
                this._store
                    .pipe(
                        select(selectors.getCurrentLocationNo)
                    ),
                this._store
                    .pipe(
                        select(selectors.routeIsLocationDetailsPage(this._config))
                    ),
            ),
            switchMap(([{ orderTypeId, tableNo, address }, locationNo, isLocationDetailsPage]) => {
                const isOnLocationDetailsPage: boolean = locationNo && isLocationDetailsPage;
                if (isOnLocationDetailsPage) {
                    if (this._isOrderTypeDineIn(orderTypeId)) {
                        this._showDineInModal(orderTypeId, locationNo, tableNo || null);
                    }
                }

                return never();
            })
        );

    @Effect() public onCurrentLocationEnterCheckDineInQueryParams$: Observable<Action> = this._actions$
        .pipe(
            ofType(
                actions.CurrentLocationSet
            ),
            auditTime(1000),
            withLatestFrom(
                this._store
                    .pipe(
                        select(selectors.routeIsLocationDetailsPage(this._config))
                    ),
                this._store
                    .pipe(
                        select(selectors.getCurrentRouteQueryParams)
                    ),
            ),
            filter(([{ locationNo }, isLocationDetailsPage, queryParams]) => isLocationDetailsPage === true && location !== null),
            switchMap(([{ locationNo }, isLocationDetailsPage, queryParams]) => this._store
                .pipe(
                    select(selectors.getCollectionTypesListForLocation(locationNo, this._config)),
                    filter(c => c !== null),
                    take(1),
                    withLatestFrom(
                        this._store
                            .pipe(
                                select(selectors.isDineInModalOpen)
                            )
                    ),
                    switchMap(([availableCollections, isDineInModalOpen]) => {
                        if (isDineInModalOpen) {
                            return never();
                        }
                        if (!queryParams?.orderTypeId) {
                            const foundActiveForLocation = availableCollections?.find(obj => obj.IsActive);
                            if (!foundActiveForLocation && availableCollections?.length) {
                                return [
                                    actions.PatchOrderTypeIdCollectionTypeValue({ orderTypeId: availableCollections[0]?.OrderTypeId })
                                ];
                            }

                            return never();
                        }
                        const orderTypeId: number = +queryParams.orderTypeId;
                        const foundDineInCollection = availableCollections
                            .find(obj => obj.GroupType === OLO.Enums.COLLECTION_TYPE.DINE_IN);

                        if (!foundDineInCollection || foundDineInCollection.OrderTypeId !== orderTypeId) {
                            return never();
                        }
                        this._store.dispatch(actions.PatchOrderTypeIdCollectionTypeValue({ orderTypeId }));

                        this._showDineInModal(orderTypeId, locationNo, queryParams?.tableNo || null);

                        return never();
                    })
                ))
        );

    private _showDineInModal(orderTypeId: number, locationNo: number, tableNo: string = null): void {
        this._modalsService.show({
            type: 'dine-in',
            locationNo,
            params: {
                tableNo,
                buzzer: this._isBuzzerDineInType(orderTypeId),
                img: this._serveDineInImageBasedOnOrderType(orderTypeId),
                preTitle: this._getDineInPreTitle(orderTypeId),
                description: this._getDineInDescription(orderTypeId, tableNo),
            }
        });
    }

    private _getDineInPreTitle(orderTypeId: number): string {
        if (this._isBuzzerDineInType(orderTypeId)) return this._buzzerPreTitle;
        if (this._isTableNoDineInType(orderTypeId)) return this._tablePreTitle;

        return null;
    }

    private _getDineInDescription(orderTypeId: number, tableNo: string = null): string[] {
        if (this._isBuzzerDineInType(orderTypeId)) return this._buzzerDescription;
        if (this._isTableNoDineInType(orderTypeId)) return tableNo ? this._tableDescriptionSelected : this._tableDescription;

        return null;
    }

    private _serveDineInImageBasedOnOrderType(orderTypeId: number | string): string {
        if (this._isBuzzerDineInType(orderTypeId)) {
            return this._buzzerImg;
        }

        if (this._isTableNoDineInType(orderTypeId)) {
            return this._tableImg;
        }

        return null;
    }

    private _isBuzzerDineInType(orderTypeId: number | string): boolean {
        if (!this._dineInBuzzerId) return false;

        return +orderTypeId === this._dineInBuzzerId;
    }

    private _isTableNoDineInType(orderTypeId: number | string): boolean {
        if (!this._dineInTableNoId) return false;

        return +orderTypeId === this._dineInTableNoId;
    }

    private _isOrderTypeDineIn(orderTypeId: number): boolean {
        if (!orderTypeId) return false;
        if (this._config.collectionTypes?.dineIn?.enabled === true) {
            return orderTypeId === this._dineInBuzzerId || orderTypeId === this._dineInTableNoId;
        }

        return false;
    }

    constructor(
        @Inject(Tokens.CONFIG_TOKEN) private _config: IConfig,
        private _actions$: Actions,
        private _store: Store<StateModels.IStateShared>,
        private _modalsService: Services.ModalsService,
    ) { }
}
