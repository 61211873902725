import { createAction, props } from '@ngrx/store';
import { ILoyaltyProgramAppAssignmentModel } from '@shared/state';

export const LoyaltyProgramsRequest = createAction(
    '[Loyalty Programs] Request  programs',
);

export const LoyaltyProgramsSuccessRequest = createAction(
    '[Loyalty Programs] Success request  programs',
    props<{ payload: ILoyaltyProgramAppAssignmentModel[]; }>(),
);

export const LoyaltyProgramsErrorRequest = createAction(
    '[Loyalty Programs] Error request  programs',
    props<{ ex?: any; }>(),
);

export const LoyaltyProgramsReset = createAction(
    '[Loyalty Programs] Reset',
);

