import { createAction, props } from '@ngrx/store';

export const OnlineMenuProductsImagesRequest = createAction(
    '[Online menu products images] Request images for online menu page products',
    props<{ params: APICommon.IImagesGetParams; pageId: number; }>(),
);

export const OnlineMenuProductsImagesSuccessRequest = createAction(
    '[Online menu products images] Success request images for online menu page products',
    props<{ pageId: number; payload: APICommon.IImageUrlModel[]; }>(),
);

export const OnlineMenuProductsImagesErrorRequest = createAction(
    '[Online menu products images] Error request images for online menu page products',
    props<{ pageId: number; ex?: any; }>(),
);
