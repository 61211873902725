import { Injectable, Inject } from '@angular/core';
import { Store, select, Action } from '@ngrx/store';
import { Actions, Effect, ofType } from '@ngrx/effects';

import * as actions from '../actions';
import * as selectors from '../selectors';

import * as Tokens from '@shared/core/tokens';
import * as State from '@shared/state/interface';
import * as Services from '@shared/core/services';

import * as StateModels from '../interface';

import { Observable, of, forkJoin } from 'rxjs';
import { catchError, map, switchMap, take, filter, withLatestFrom, delay, mergeMap } from 'rxjs/operators';
import { IExecutePaymentModel } from '@shared/state/interface';


@Injectable()
export class PaymentsDemoEffects {
    @Effect() public __DEMO__stepCreateOrderFor$: Observable<Action> = this._actions$
        .pipe(
            ofType(
                actions.__DEMO__PaymentStepCreateOrder,
            ),
            withLatestFrom(
                this._store.pipe(select(selectors.__DEMO__canPostOnlineOrder))
            ),
            switchMap(([action, canPostOnlineOrder]) => {
                if (!canPostOnlineOrder) {
                    return this._error('Unable to create order - insufficient data');
                }

                return [
                    actions.OnlineOrderClearPostOrderRequestFlags(),
                    actions.OnlineOrderCreateRequest(),
                    actions.__DEMO__PaymentStepPay(),
                ];
            })
        );

    @Effect() public __DEMO__stepPay$: Observable<Action> = this._actions$
        .pipe(
            ofType(
                actions.__DEMO__PaymentStepPay
            ),
            switchMap(action => this._store
                .pipe(
                    select(selectors.getOnlineOrderState),
                    filter(orderState => orderState.createRequest.isCreating === false),
                    take(1),
                    withLatestFrom(
                        this._store.pipe(select(selectors.getOnlineOrder))
                    ),
                    switchMap(([orderState, onlineOrder]) => {
                        if (orderState.createRequest.hasFailed) {
                            return this._error('Unable to create order');
                        }

                        return this._store
                            .pipe(
                                select(selectors.isCartLocationsPickupsCalculating),
                                filter(isCalculating => isCalculating === false),
                                take(1),
                                switchMap(calculated => {
                                    const OrderId: number = onlineOrder.Id;
                                    const PaymentMethod: IExecutePaymentModel = {
                                        Amount: onlineOrder.TotalGrossValue,
                                        PaymentAccountId: null,
                                        Token: 'demo-mode-on',
                                        SetOrderAsValidatedOnSuccess: true,
                                        PaymentProvider: OLO.Enums.PAYMENT_PROVIDER.CONVERGE
                                    };

                                    return this._paymentsService.pay(OrderId, PaymentMethod)
                                        .pipe(
                                            map(({ TransactionId }) => actions.__DEMO__PaymentStepPaymentStatusCheck({ TransactionId, OrderId })),
                                            catchError(ex => this._error('Payment failed', ex))
                                        );
                                })
                            );


                    })
                ))
        );

    @Effect() public __DEMO__stepPaymentStatusCheck$: Observable<Action> = this._actions$
        .pipe(
            ofType(
                actions.__DEMO__PaymentStepPaymentStatusCheck
            ),
            mergeMap(action => this._paymentsService.getPaymentStatus(action.TransactionId)
                .pipe(
                    delay(1000),
                    switchMap(payload => of(actions.PaymentStepComplete({ OrderId: action.OrderId, payload }))),
                    catchError(ex => this._error('Payment status check failed', ex))
                ))
        );

    private _error(error: string = '', ex: any = null): Array<Action> {
        console.error('DEMO Payment error:', error, ex);

        return [
            actions.OnlineOrderStateReset(),
            actions.PaymentStepFailed(error)
        ];
    }

    constructor(
        private _actions$: Actions,
        private _paymentsService: Services.PaymentsService,
        private _store: Store<StateModels.IStateShared>,
    ) { }
}
