import { createReducer, on, Action } from '@ngrx/store';
import * as actions from './loyalty-messages.actions';
import { ILoyaltyMessages } from './loyalty-messages.interface';

const initialState: ILoyaltyMessages = {
    isDownloading: false,
    hasSucceeded: false,
    hasFailed: false,
    downloadedDate: null,
    data: null,
};

export const loyaltyMessagesReducerFn = createReducer<ILoyaltyMessages>(
    initialState,
    on(
        actions.LoyaltyMessagesRequest,
        (state, action) => ({
            ...state,
            isDownloading: true,
            hasSucceeded: false,
            hasFailed: false,
        })
    ),
    on(
        actions.LoyaltyMessagesSuccessRequest,
        (state, action) => ({
            ...state,
            isDownloading: false,
            hasSucceeded: true,
            hasFailed: false,
            downloadedDate: new Date().getTime(),
            data: [...action.payload]
        })
    ),
    on(
        actions.LoyaltyMessagesErrorRequest,
        (state, action) => ({
            ...state,
            isDownloading: false,
            hasSucceeded: false,
            hasFailed: true,
        })
    ),
);

export function loyaltyMessagesReducer(state: ILoyaltyMessages | undefined, action: Action) {
    return loyaltyMessagesReducerFn(state, action);
}
