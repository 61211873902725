import { Injectable, Inject } from '@angular/core';

import * as Tokens from '@shared/core/tokens';
import * as actions from '@shared/state/actions';
import { Store } from '@ngrx/store';
import * as State from '@shared/state/interface';

@Injectable({
    providedIn: 'root'
})
export class GoogleApiService {
    public mapsScript!: HTMLScriptElement;

    constructor(
        @Inject(Tokens.CONFIG_TOKEN) public config: IConfig,
        public store: Store<State.IStateShared>,
    ) {
    }

    public get mapsApiKey(): string {
        return this.config.google?.maps?.apiKey || null;
    }

    public get mapsLibraries(): string[] {
        return this.config.google?.maps?.libraries || [];
    }

    public appendMapsScript(APIKey): HTMLScriptElement {
        const key = APIKey || this.mapsApiKey;

        if (!key) return null;

        let libs: string = '';

        if (this.mapsLibraries.length) {
            libs = '&libraries=' + this.mapsLibraries.join(',');
        }

        this.mapsScript = document.createElement('script');
        this.mapsScript.src = `//maps.googleapis.com/maps/api/js?key=${key}${libs}`;
        this.mapsScript.onload = () => {
            this.store.dispatch(actions.GoogleInitSuccess());
        };

        document.body.appendChild(this.mapsScript);

        return this.mapsScript;
    }
}
