import { Injectable, Inject } from '@angular/core';
import { Store, Action, select } from '@ngrx/store';
import { Actions, ofType, Effect } from '@ngrx/effects';

import * as actions from '../actions';
import * as selectors from '../selectors';

import * as StateModels from '../interface';

import * as Utils from '@shared/core/utils';
import * as Tokens from '@shared/core/tokens';

import { Observable, of } from 'rxjs';
import { switchMap, combineLatest, take, filter } from 'rxjs/operators';

@Injectable()
export class AvailablePickupsEffects {
    @Effect() public onLocationSuccessRequestDataCalculate$: Observable<Action> = this._actions$
        .pipe(
            ofType(actions.LocationsSuccessRequest),
            switchMap(({ payload }) => {
                return payload.map(obj => actions.AvailablePickupsCalculateRequest({ locationNo: obj.LocationNo }));
            })
        );

    /* This effect is triggered from orderingTimeInfo effects when data is downloaded after successful locations request  */
    @Effect() public calculateAvailablePickupOptionsForLocation: Observable<Action> = this._actions$
        .pipe(
            ofType(actions.AvailablePickupsCalculateRequest),
            switchMap(action =>
                of(action.locationNo)
                    .pipe(
                        combineLatest(
                            this._store.pipe(
                                select(selectors.getOrderingTimeInfoByLocationNo(action.locationNo)),
                                filter(obj => obj !== undefined && obj !== null),
                                take(1)
                            ),
                            this._store.pipe(
                                select(
                                    selectors.getOrderInfoCompleteForLocationByDate(action.locationNo) /* FIX THIS any */
                                ),
                                filter(obj => obj !== undefined && obj.hasSucceeded === true),
                                take(1)
                            ),
                            this._store
                                .pipe(
                                    select(selectors.getLocationDetails(action.locationNo)),
                                    filter(location => location !== null && location !== undefined),
                                    take(1)
                                )
                        ),
                        switchMap(([locationNo, openingHours, orderInfo, location]) => {
                            return of(actions.AvailablePickupsCalculateSuccessRequest({
                                locationNo,
                                payload: Utils.Pickups.generatePickupTimesList(
                                    {
                                        location,
                                        asapPickupMins: orderInfo.MinimumPickupTime,
                                        openingHours,
                                        orderTimeoutBufferMins: this._config.collectionTypes.pickup.orderTimeoutBufferMins,
                                        startBufferMins: this._config.collectionTypes.pickup.startBufferMins,
                                        nextTick: this._config.collectionTypes.pickup.nextTick,
                                        schedule: this._config.futureOrders === true,
                                    }
                                )
                            }));
                        }),
                    )
            )
        );

    constructor(
        @Inject(Tokens.CONFIG_TOKEN) private _config: IConfig,
        private _actions$: Actions,
        private _store: Store<StateModels.IStateShared>,
    ) { }
}
