import { ActionReducer, Action } from '@ngrx/store';
import * as StateModels from '../interface';

import * as actions from './dev.actions';

export function __devMetaReducer(reducer: ActionReducer<StateModels.IStateShared>): ActionReducer<StateModels.IStateShared> {
    return function newReducer(state: StateModels.IStateShared, action: Action): StateModels.IStateShared {

        switch (action.type) {
            case '[__DevSetState] Set state':
                return {
                    ...state,
                    ...(action as any).state,
                };

            default: {
                return reducer(state, action);
            }
        }
    };
}
