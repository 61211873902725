import { createSelector, createFeatureSelector, MemoizedSelector } from '@ngrx/store';
import { IStateShared, ICollectionType } from '../interface';
import * as Models from '@shared/core/models';

const state = createFeatureSelector<IStateShared, ICollectionType>('collectionType');

export const getCollectionType = createSelector(
    state,
    collectionType => collectionType
);

export const getCollectionTypesList = (config: IConfig) => createSelector(
    state,
    collectionType => {
        const list: Models.CollectionTypeItem[] = [];

        if (config.collectionTypes?.pickup?.enabled === true) {
            list.push(new Models.CollectionTypeItem(
                1,
                'Pick up',
                OLO.Enums.COLLECTION_TYPE.PICKUP,
                collectionType?.orderTypeId === OLO.Enums.COLLECTION_TYPE.PICKUP,
                OLO.Enums.COLLECTION_TYPE.PICKUP,
            )
            );
        }

        if (config.collectionTypes?.delivery?.enabled === true) {
            list.push(new Models.CollectionTypeItem(
                2,
                'Delivery',
                config.collectionTypes.delivery.orderTypeId,
                collectionType?.orderTypeId === config.collectionTypes.delivery.orderTypeId,
                OLO.Enums.COLLECTION_TYPE.DELIVERY
            ));
        }

        return list;
    }
);

export const isCollectionTypeDelivery = (config: IConfig) => createSelector(
    state,
    collectionType => {
        return collectionType?.orderTypeId === config?.collectionTypes?.delivery?.orderTypeId
            && typeof config?.collectionTypes?.delivery?.orderTypeId === 'number';
    },
);

export const isCollectionTypeDineIn = (config: IConfig) => createSelector(
    state,
    collectionType => {
        if (!collectionType?.orderTypeId) return false;

        return collectionType?.orderTypeId === config?.collectionTypes?.dineIn?.dineInBuzzer?.orderTypeId
            || collectionType?.orderTypeId === config?.collectionTypes?.dineIn?.dineInTable?.orderTypeId;
    }
);

export const getSelectedCollectionTypeGroupId = (config: IConfig) => createSelector(
    state,
    isCollectionTypeDelivery(config),
    isCollectionTypeDineIn(config),
    (collectionType, isDelivery, isDineIn) => {
        if (isDelivery) return OLO.Enums.COLLECTION_TYPE.DELIVERY;
        if (isDineIn) return OLO.Enums.COLLECTION_TYPE.DINE_IN;
        if (collectionType?.orderTypeId === OLO.Enums.COLLECTION_TYPE.PICKUP) return OLO.Enums.COLLECTION_TYPE.PICKUP;

        return null;
    }
);
