import { CreditCards } from '@shared/core/utils/credit-cards.utils';
import { Model } from '../model';

export class CreditCard extends Model<OLO.Members.IMemberCreditCardDetails> {
    constructor(
        paymentProvider: OLO.Enums.PAYMENT_PROVIDER,
        number: string,
        type: OLO.Enums.CREDIT_CARD_TYPES,
        expiryDate: string,
        token: string,
        isDefault: boolean,
        saveCard: boolean,
        validationStatus: OLO.Types.VALIDATION_STATUS,
        LocationNo: number,
        id: number,
        fatZebraToken: OLO.Members.NullableFatZebraTokenVerification,
        adyenPaymentData: Adyen.PaymentData,
        stripePaymentData: OLO.CreditCards.StripeResponsePaymentMethod,
    ) {
        super();

        this._model.PaymentProvider = paymentProvider;
        this._model.ExpirationDate = CreditCards.dateToApiFormat(expiryDate);
        this._model.CardType = type;
        this._model.Token = token;
        this._model.Id = id;
        this._model.IsDefault = isDefault;
        this._model.SaveAwait = saveCard;
        this._model.ValidationStatus = validationStatus;
        this._model.LocationNo = LocationNo;
        this._model.FatZebraToken = fatZebraToken;
        this._model.AdyenPaymentData = adyenPaymentData;
        this._model.StripePaymentData = stripePaymentData;

        if (number && number.substring) {
            this._model.DisplayName = this._model.NiceName = number.substring(number.length - 4);
        }
    }
}
