import { createAction, props } from '@ngrx/store';
import { IOnlineOrderDetailedBusinessModel } from '@shared/state';

export const HistoryOrdersReset = createAction('[History order] Reset data');

/* Bulks */
export const HistoryOrdersRequest = createAction(
    '[History order] Request bulk online orders data',
    (params: APICommon.IOnlineOrdersGetOrdersParams) => ({ params })
);

export const HistoryOrdersSuccessRequest = createAction(
    '[History order] Success request bulk online orders data',
    props<{ params: APICommon.IOnlineOrdersGetOrdersParams; payload: IOnlineOrderDetailedBusinessModel[]; }>(),
);

export const HistoryOrdersErrorRequest = createAction(
    '[History order] Error request bulk online orders data',
    props<{ params: APICommon.IOnlineOrdersGetOrdersParams; ex?: any; }>(),
);

/* Single */
export const HistoryOrderRequest = createAction(
    '[History order] Request single online order data',
    props<{ orderId: number; }>(),
);

export const HistoryOrderSuccessRequest = createAction(
    '[History order] Success request single online order data',
    props<{ orderId: number; payload: IOnlineOrderDetailedBusinessModel; }>(),
);

export const HistoryOrderErrorRequest = createAction(
    '[History order] Error request single online order data',
    props<{ orderId: number; ex?: any; }>(),
);
