import { IOnlineOrderUrlBusinessModel } from '@shared/state';

export class OnlineOrderUrlModel implements IOnlineOrderUrlBusinessModel {
    public UrlType: APICommon.ORDER_URL_TYPE = APICommon.ORDER_URL_TYPE.ORDER_CONFIRMATION;

    constructor(
        public OrderId: number,
        public Url: string,
    ) { }
}
