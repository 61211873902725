import { Injectable, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { Store, select } from '@ngrx/store';

import * as selectors from '@shared/state/selectors';

import * as State from '@shared/state';
import * as Tokens from '@shared/core/tokens';
import * as Services from '@shared/core/services';
import * as Utils from '@shared/core/utils';

import { Observable, Subject, Subscription } from 'rxjs';
import { map, distinct, distinctUntilChanged, auditTime, filter, combineLatest, withLatestFrom, switchMap, take, tap, pairwise } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class ModalsController {
    constructor(
        private _store: Store<State.IStateShared>,
    ) { }

    public get modalsList$(): Observable<State.IModal[]> {
        return this._store.pipe(
            select(selectors.getAllModals),
            distinctUntilChanged()
        );
    }

    public get isAnyModalVisible$(): Observable<boolean> {
        return this.modalsList$
            .pipe(
                map(modals => modals.length > 0),
            );
    }

    public get isClosingAllModals$(): Observable<boolean> {
        return this._store.pipe(
            select(selectors.isLastModalHiding),
            distinctUntilChanged(),
        );
    }
}
