import { Injectable, Inject } from '@angular/core';
import { Store, select, Action } from '@ngrx/store';
import { Actions, Effect, ofType } from '@ngrx/effects';

import * as actions from '../actions';
import * as selectors from '../selectors';

import * as State from '@shared/state/interface';
import * as Utils from '@shared/core/utils';
import * as Services from '@shared/core/services';
import * as Tokens from '@shared/core/tokens';

import * as StateModels from '../interface';

import { Observable, of, forkJoin } from 'rxjs';
import { switchMap, catchError, map, withLatestFrom, mergeMap } from 'rxjs/operators';


@Injectable()
export class LoyaltyIntroductionPagesEffects {
    @Effect() public requestIntroductionPages$: Observable<Action> = this._actions$
        .pipe(
            ofType(
                actions.LoyaltyIntroductionPagesRequest
            ),
            switchMap(() => this._loyaltyAppService.apiGetLoyaltyIntroductionPages()
                .pipe(
                    map(response => actions.LoyaltyIntroductionPagesSuccessRequest({ payload: response.Items.sort((a, b) => a.DisplayIndex - b.DisplayIndex) })),
                    catchError(ex => {
                        console.error('LoyaltyPagesRequest error', ex);
                        return of(actions.LoyaltyIntroductionPagesErrorRequest({ ex }));
                    })
                )
            )
        );

    constructor(
        @Inject(Tokens.CONFIG_TOKEN) private _config: IConfig,
        private _actions$: Actions,
        private _store: Store<StateModels.IStateShared>,
        private _loyaltyAppService: Services.LoyaltyAppService,
    ) { }
}
