import { createReducer, on, Action } from '@ngrx/store';
import { IIngredient } from './ingredients.interface';
import * as actions from './ingredients.actions';

const initialState: IIngredient[] = [];

export const ingredientsReducerFn = createReducer(
    initialState,
    on(
        actions.IngredientsRequest,
        (state, action) => {
            const newToConcat: IIngredient[] = [];

            action.productIds.forEach(productId => {
                if (state.find(ingredient => ingredient.ProductId === productId && ingredient.LocationNo === action.locationNo)) {
                    return;
                }

                newToConcat.push({
                    ProductId: productId,
                    LocationNo: action.locationNo,
                    isDownloading: true,
                    hasSucceeded: false,
                    hasFailed: false,
                    data: null,
                });
            });

            return [
                ...state.map((modifier: IIngredient) => {
                    if (action.productIds.find(productId => productId === modifier.ProductId && modifier.LocationNo === action.locationNo)) {
                        return {
                            ...modifier,
                            isDownloading: true,
                            hasSucceeded: false,
                            hasFailed: false,
                        };
                    }

                    return modifier;
                }),
                ...newToConcat,
            ];
        }
    ),
    on(
        actions.IngredientsSuccessRequest,
        (state, action) => state.map((modifier: IIngredient) => {
            if (!(action.productIds.includes(modifier.ProductId) && modifier.LocationNo === action.locationNo)) {
                return modifier;
            }
            const foundIngredientObj = action.payload.find(ingredient => ingredient.ProductID === modifier.ProductId && ingredient._LocationNo === modifier.LocationNo);

            return {
                ...modifier,
                data: { ...foundIngredientObj },
                isDownloading: false,
                hasSucceeded: true,
                hasFailed: false,
            };

        })
    ),
    on(
        actions.IngredientsErrorRequest,
        (state, action) => state.map((modifier: IIngredient) => {
            if (action.productIds.find(productId => productId === modifier.ProductId && modifier.LocationNo === action.locationNo)) {
                return {
                    ...modifier,
                    isDownloading: false,
                    hasSucceeded: false,
                    hasFailed: true,
                };
            }

            return modifier;
        })
    ),
);

export function ingredientsReducer(state: IIngredient[] | undefined, action: Action) {
    return ingredientsReducerFn(state, action);
}
