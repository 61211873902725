import { createSelector, createFeatureSelector } from '@ngrx/store';
import { IStateShared, IModal, MODAL_ANIMATION } from '../interface';

const state = createFeatureSelector<IStateShared, IModal[]>('modals');

export const getAllModals = createSelector(
    state,
    (modals: IModal[]) => modals
);

export const isLastModalHiding = createSelector(state, (modals: IModal[]) => {
    const count: number = modals.length;
    if (count !== 1) { return false; }

    return modals[0] !== undefined && modals[0].animate === MODAL_ANIMATION.OUT;
});

export const getModalById = (modalId: number) => createSelector(state, modals => {
    return modals.find(modal => modal.id === modalId);
});

export const isDineInModalOpen = createSelector(
    state,
    modals => {
        return modals.some(modal => modal.type === 'dine-in');
    }
);
