import { createSelector, createFeatureSelector, MemoizedSelector } from '@ngrx/store';
import { IStateShared, ILoyaltyIntroductionPageImage } from '../interface';

const state = createFeatureSelector<IStateShared, ILoyaltyIntroductionPageImage[]>('loyaltyIntroductionPagesImages');

export const getLoyaltyIntroductionPagesImages = createSelector(
    state,
    loyaltyIntroductionPagesImages => loyaltyIntroductionPagesImages,
);

export const getLoyaltyIntroductionPageImage = (pageId: number) => createSelector(
    state,
    loyaltyIntroductionPagesImages => loyaltyIntroductionPagesImages.find(obj => obj.PageId === pageId)
);
