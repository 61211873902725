import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import * as Tokens from '@shared/core/tokens';
import { Observable, from, forkJoin, timer } from 'rxjs';
import { take, switchMap, map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class GooglePlacesService {
    private _googlePlacesAutocomplete!: google.maps.places.AutocompleteService;
    private _googlePlaces!: google.maps.places.PlacesService;
    private _map!: google.maps.Map;
    private _sessionToken!: google.maps.places.AutocompleteSessionToken;

    constructor(
        @Inject(Tokens.CONFIG_TOKEN) public config: IConfig,
        public httpClient: HttpClient,
    ) { }

    private _initGoogleServices(): void {
        if (!this._googlePlacesAutocomplete && google) {
            this._map = new google.maps.Map(document.createElement('div'));
            this._googlePlacesAutocomplete = new google.maps.places.AutocompleteService();
            this._googlePlaces = new google.maps.places.PlacesService(this._map);
            this._sessionToken = new google.maps.places.AutocompleteSessionToken();
        }
    }

    public placeDetails(requestData: google.maps.places.PlaceDetailsRequest): Observable<APICommon.IGooglePlaceResult> {
        this._initGoogleServices();

        return from(
            new Promise<APICommon.IGooglePlaceResult>((resolve, reject) => {
                this._googlePlaces.getDetails(requestData, (place, status) => {
                    if (status !== google.maps.places.PlacesServiceStatus.OK) return reject(status);

                    resolve({
                        ...place,
                        _parent_id: requestData.placeId,
                    });
                });
            })).pipe(
            take(1)
        );
    }

    public placesSearch(input: string): Observable<Array<google.maps.places.AutocompletePrediction>> {
        this._initGoogleServices();

        /**
         * Worth reading https://developers.google.com/places/web-service/autocomplete#place_types
         */
        let types = [];

        if (this.config.collectionTypes?.delivery?.enabled === true) {
            types = ['address'];
        } else {
            types = ['(regions)'];
        }

        return from(
            new Promise<Array<google.maps.places.AutocompletePrediction>>((resolve, reject) => {
                this._googlePlacesAutocomplete.getPlacePredictions({
                    input,
                    types,
                    sessionToken: this._sessionToken,
                    componentRestrictions: this._componentRestrictions
                }, (payload, status) => {
                    if (status !== google.maps.places.PlacesServiceStatus.OK) {
                        return reject(status);
                    }
                    resolve(payload);
                });
            })).pipe(
            take(1)
        );
    }

    public placesSearchWithDetails(input: string): Observable<APICommon.IGooglePlaceDetails[]> {
        /* https://developers.google.com/maps/documentation/javascript/places */
        return this.placesSearch(input)
            .pipe(
                switchMap(payload => forkJoin(payload.map((obj, index) => timer((index + 30))
                    .pipe(
                        switchMap(() => this.placeDetails({
                            placeId: obj.place_id,
                            sessionToken: this._sessionToken,
                            fields: [
                                'address_component',
                                'adr_address',
                                'business_status',
                                'formatted_address',
                                'geometry',
                                'name',
                                'place_id',
                                'plus_code',
                                'type',
                                'utc_offset_minutes',
                                'vicinity'
                            ]
                        }))
                    )

                ))
                    .pipe(
                        map(detailsArr => payload.map(obj => {
                            const placeDetails = detailsArr?.find(d => d._parent_id === obj.place_id);

                            return ({
                                Id: obj.place_id,
                                Name: obj.description,
                                ...obj,
                                details: placeDetails,
                                geometry: placeDetails?.geometry?.location?.toJSON() || null
                            } as APICommon.IGooglePlaceDetails);
                        }))
                    ))
            );
    }

    private get _componentRestrictions(): APICommon.IGoogleComponentRestrictions {
        return this.config.google?.maps?.componentRestrictions || (this.config.localization?.country ? { country: this.config.localization?.country } : null);
    }
}
