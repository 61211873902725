import { createSelector, createFeatureSelector } from '@ngrx/store';
import { IStateShared, IVenuesImages } from '../interface';
import * as Utils from '@shared/core/utils';

const state = createFeatureSelector<IStateShared, IVenuesImages>('venuesImages');

export const getAllVenueImages = createSelector(
    state,
    venuesImages => venuesImages
);

export const getVenueImage = (imageType: OLO.Enums.IMAGE_TYPE, venueId: number) => createSelector(
    state,
    venuesImages => {
        let targetImageType: string = Utils.Images.toImageTypeString(imageType);

        return venuesImages[targetImageType].find(obj => obj.Id === venueId);
    }
);
