import { Injectable, Inject } from '@angular/core';
import { Action, Store, select } from '@ngrx/store';
import { Effect, Actions, ofType } from '@ngrx/effects';

import * as actions from '../actions';
import * as selectors from '../selectors';
import * as StateModels from '../interface';

import * as Services from '@shared/core/services';
import * as Utils from '@shared/core/utils';
import * as Tokens from '@shared/core/tokens';

import { Observable, of, never, } from 'rxjs';
import { switchMap, take, distinct, withLatestFrom, filter, map } from 'rxjs/operators';

@Injectable()
export class CurrentLocationEffects {
    @Effect() public onDineInOrderTypeSetSelectAsapPickupTime$: Observable<Action> = this._actions$
        .pipe(
            ofType(
                actions.SetCollectionType
            ),
            withLatestFrom(
                this._store
                    .pipe(
                        select(selectors.isCollectionTypeDineIn(this._config))
                    )
            ),
            filter(([action, isDineIn]) => isDineIn === true),
            switchMap(([action]) => this._store
                .pipe(
                    select(selectors.getCurrentLocationNo),
                    filter(locationNo => locationNo !== null),
                    take(1),
                    switchMap(locationNo => this._store
                        .pipe(
                            select(selectors.getAvailablePickupTimesForLocation(locationNo)),
                            filter(pickups => pickups !== undefined && pickups.data !== null),
                            take(1),
                            switchMap(pickups => {
                                const asapPickupForCurrentLocation = pickups?.data?.find(obj => obj.IsAsap === true && obj.IsToday === true);
                                if (!asapPickupForCurrentLocation) {
                                    console.error(`Unable to get asapi pickup time for current location: ${locationNo}`);

                                    return never();
                                }

                                return of(actions.CurrentLocationPickupTimeSet(asapPickupForCurrentLocation));
                            })
                        ))
                ))
        );

    @Effect() public onCartPickupTimeUpdateForceUpdateCurrentLocationPickupTime$: Observable<Action> = this._actions$
        .pipe(
            ofType(
                actions.CartPickupTimeUpdate
            ),
            switchMap(action => of(actions.CurrentLocationPickupTimeSet(action.pickupTime)))
        );

    @Effect({ dispatch: false }) public removeLocalStorageDataOnReset$: Observable<never> = this._actions$
        .pipe(
            ofType(
                actions.CurrentLocationReset
            ),
            switchMap(action => {
                Utils.Storage.remove(OLO.Enums.USER_STORAGE.CURRENT_PICKUP_TIME as unknown as string);
                Utils.Storage.remove(OLO.Enums.USER_STORAGE.CURRENT_LOCATION as unknown as string);
                Utils.Storage.remove(OLO.Enums.USER_STORAGE.CURRENT_LOCATION_UPDATE_DATA as unknown as string);

                return never();
            })
        );

    @Effect({ dispatch: false }) public savePickupTimeInLocalStorage$: Observable<never> = this._actions$
        .pipe(
            ofType(
                actions.CurrentLocationPickupTimeSet,
                actions.CurrentLocationFiltersPickupTimeSync,
            ),
            switchMap(({ pickupTime }) => {
                if (!pickupTime) {
                    Utils.Storage.remove(OLO.Enums.USER_STORAGE.CURRENT_PICKUP_TIME as unknown as string);

                    return never();
                }

                const obj = {
                    ...pickupTime,
                    Date: pickupTime.Date.getTime(),
                    PlaceOrderTimeout: pickupTime.Date.getTime(),
                };

                Utils.Storage.set(OLO.Enums.USER_STORAGE.CURRENT_PICKUP_TIME as unknown as string, obj);

                return never();
            })
        );

    @Effect({ dispatch: false }) public onLocationSetSaveItToLocalStorage$: Observable<Action> = this._actions$
        .pipe(
            ofType(
                actions.CurrentLocationSet
            ),
            distinct(action => action.locationNo),
            switchMap(action => {
                Utils.Storage.set(OLO.Enums.USER_STORAGE.CURRENT_LOCATION as unknown as string, action.locationNo);

                return never();
            })
        );

    constructor(
        @Inject(Tokens.CONFIG_TOKEN) private _config: IConfig,
        private _actions$: Actions,
        private _store: Store<StateModels.IStateShared>,
    ) { }

}
