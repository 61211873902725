import { Injectable, Inject } from '@angular/core';
import { Action, Store } from '@ngrx/store';
import { Effect, Actions, ofType } from '@ngrx/effects';

import * as actions from '../actions';
import * as Services from '@shared/core/services';
import * as Tokens from '@shared/core/tokens';

import * as StateModels from '../interface';

import { Observable, of, from, never } from 'rxjs';
import { map, catchError, switchMap } from 'rxjs/operators';

@Injectable()
export class GeolocationEffects {
    @Effect() public requestGeolocationCoordsBasedOnLocationMode$: Observable<Action> = this._actions$
        .pipe(
            ofType(
                actions.SetApplicationMode
            ),
            switchMap(action => {
                const canRequestCoords: boolean = this._config.geolocation === true && action.mode === OLO.Enums.APP_MODE.LOCATION;
                if (!canRequestCoords) return never();

                return of(actions.geolocationPositionRequest());
            })
        );

    @Effect() public getUserGeolocation$: Observable<Action> = this._actions$
        .pipe(
            ofType(
                actions.geolocationPositionRequest
            ),
            switchMap(action =>
                from(this._geolocationService.getUserCoords())
                    .pipe(
                        map(payload => actions.geolocationPositionSuccessRequest({ payload })),
                        catchError(ex => {
                            console.error('Geolocation - not permitted', ex);
                            return of(actions.geolocationPositionErrorRequest({ ex }));
                        })
                    )
            )
        );

    constructor(
        @Inject(Tokens.CONFIG_TOKEN) private _config: IConfig,
        private _actions$: Actions,
        private _store: Store<StateModels.IStateShared>,
        private _geolocationService: Services.GeolocationService,
    ) { }

}
