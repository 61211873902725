import { Injectable } from '@angular/core';

import { Subject } from 'rxjs';

@Injectable()
export class ScrollService {
    private _updateListener: Subject<boolean> = new Subject();

    constructor() {}

    public requestUpdate(): void {
        this._updateListener.next(true);
    }

    public get onUpdate(): Subject<boolean> {
        return this._updateListener;
    }
}
