import { Action, createAction, props } from '@ngrx/store';
import { IReorderDetails, ICartSimpleItem, ICartMenuFlow } from '../interface';

export const ReorderSetup = createAction(
    '[Reorder] Setup',
    props<{ orderId: number; locationNo: number; modalId?: number }>()
);

export const ReorderUnmount = createAction(
    '[Reorder] Unmount',
    props<{ orderId?: number }>()
);

export const ReorderCalculateRequest = createAction(
    '[Reorder] Request calculate for reorder',
    props<{ orderId: number; locationNo: number; pickupTime: OLO.Ordering.IPickupTime }>(),
);

export const ReorderCalculateSuccessRequest = createAction(
    '[Reorder] Success request calculate for reorder',
    props<{ orderId: number; locationNo: number; pickupTime: OLO.Ordering.IPickupTime; payload: IReorderDetails }>(),
);

export const ReorderCalculateErrorRequest = createAction(
    '[Reorder] Error request calculate for reorder',
    props<{ orderId: number; locationNo: number; pickupTime: OLO.Ordering.IPickupTime; ex?: any }>(),
);

export const ReorderSelectItem = createAction(
    '[Reorder] Select item',
    props<{ orderId: number; item: ICartSimpleItem | ICartMenuFlow }>(),
);

export const ReorderDeselectItem = createAction(
    '[Reorder] Deselect item',
    props<{ orderId: number; item: ICartSimpleItem | ICartMenuFlow }>(),
);

export const ReorderDeselectAll = createAction(
    '[Reorder] Deselect all',
    props<{ orderId: number }>(),
);
