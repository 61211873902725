import { createSelector, createFeatureSelector, MemoizedSelector } from '@ngrx/store';
import { IStateShared, IMemberCardBarcodeImage } from '../interface';

const state = createFeatureSelector<IStateShared, IMemberCardBarcodeImage[]>('memberCardBarcodesImages');

export const getMemberCardBarcodesImages = createSelector(
    state,
    memberCardBarcodesImages => memberCardBarcodesImages,
);

export const getMemberCardBarcodeImage = (memberId: number, barcodeType: OLO.Enums.BARCODE_SYMBOLOGY = OLO.Enums.BARCODE_SYMBOLOGY.CODE_QR) => createSelector(
    state,
    memberCardBarcodesImages => memberCardBarcodesImages.find(obj => obj.memberId === memberId && obj.barcodeType === barcodeType)
);
