import { Storage } from './storage.utils';

export class Redirect {
    public static async setRedirect(): Promise<boolean> {
        Storage.set(OLO.Enums.SESSION_STORAGE.REDIRECT, '1', 'sessionStorage');
        return true;
    }

    public static async unsetRedirect(): Promise<boolean> {
        Storage.remove(OLO.Enums.SESSION_STORAGE.REDIRECT, 'sessionStorage');
        return true;
    }

    public static isRedirecting(): boolean {
        const r: string = Storage.getItem(OLO.Enums.SESSION_STORAGE.REDIRECT, 'sessionStorage');
        return r && r === '1' ? true : false;
    }
}
