import { createAction, props } from '@ngrx/store';
import { IMenuFlowDetailsModel } from '@shared/state';

export const MenuFlowsDetailsRequest = createAction(
    '[Menu flows] Request details by MenuFlowId and LocationNo',
    props<{ menuFlowId: number; locationNo: number; }>(),
);

export const MenuFlowsDetailsSuccessRequest = createAction(
    '[Menu flows] Success request details by MenuFlowId and LocationNo',
    props<{ menuFlowId: number; locationNo: number; payload: IMenuFlowDetailsModel; }>(),
);

export const MenuFlowsDetailsErrorRequest = createAction(
    '[Menu flows] Error request details by MenuFlowId and LocationNo',
    props<{ menuFlowId: number; locationNo: number; ex?: any; }>(),
);
