import { createSelector, createFeatureSelector, MemoizedSelector } from '@ngrx/store';
import { IStateShared, IFreeProductImage } from '../interface';

const state = createFeatureSelector<IStateShared, IFreeProductImage[]>('freeProductsImages');

export const getFreeProductsImagesImages = createSelector(
    state,
    freeProductsImages => freeProductsImages,
);

export const getFreeProductsImageImage = (productId: number) => createSelector(
    state,
    freeProductsImages => freeProductsImages.find(obj => obj.ProductId === productId) || null
);
