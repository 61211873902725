import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class GUIDGuard implements CanActivate {
    constructor(
        private _router: Router,
    ) { }

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean> | Promise<boolean> | boolean {
        return route.queryParams.hasOwnProperty('guid') ? true : this._router.navigate(['/404'], { queryParams: null }).then(() => false);
    }
}
