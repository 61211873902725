import { Injectable } from '@angular/core';
import { Action } from '@ngrx/store';
import { Actions, Effect, ofType } from '@ngrx/effects';

import * as actions from '../actions';

import * as Services from '@shared/core/services';

import { Observable, of } from 'rxjs';
import { switchMap, catchError, map } from 'rxjs/operators';


@Injectable()
export class GoogleEffects {
    @Effect() public onPlaceSearch$: Observable<Action> = this._actions$
        .pipe(
            ofType(
                actions.GooglePlaceSearchRequest
            ),
            switchMap(({ search }) =>
                this._googlePlacesService.placesSearchWithDetails(search)
                    .pipe(
                        map(payload => actions.GooglePlaceSearchSuccessRequest({ search, payload })),
                        catchError(ex => {
                            console.error('Unable to search places', ex);

                            return of(actions.GooglePlaceSearchErrorRequest({ search, ex }));
                        })
                    )
            )
        );

    constructor(
        private _actions$: Actions,
        private _googlePlacesService: Services.GooglePlacesService,
    ) { }
}
