import { Injectable, Inject } from '@angular/core';
import { Store, select } from '@ngrx/store';

import * as selectors from '@shared/state/selectors';
import * as actions from '@shared/state/actions';

import * as State from '@shared/state';
import * as Tokens from '@shared/core/tokens';
import * as Services from '@shared/core/services';

import { Observable } from 'rxjs';
import { map, filter, combineLatest, switchMap, take, withLatestFrom } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class LoyaltyController {
    constructor(
        @Inject(Tokens.CONFIG_TOKEN) private _config: IConfig,
        private _store: Store<State.IStateShared>,
        private _modalsService: Services.ModalsService,
        private _loyaltyAppService: Services.LoyaltyAppService,
    ) { }

    public getLoyaltyMessages$(): Observable<State.ILoyaltyMessages> {
        return this._store
            .pipe(
                select(selectors.getLoyaltyMessages)
            );
    }

    // this.image$ = this.controllers.loyalty.getMemberLoyaltyCardBarcode$(this.memberId, this.barcodeType)
    public getMemberLoyaltyCardBarcode$(memberId: number, barcodeType: OLO.Enums.BARCODE_SYMBOLOGY = OLO.Enums.BARCODE_SYMBOLOGY.CODE_QR):
    Observable<State.IMemberCardBarcodeImage> {
        return this._store
            .pipe(
                select(selectors.getMemberState),
                switchMap(state => this._store
                    .pipe(
                        select(selectors.getMemberCardBarcodeImage(memberId || (state.data && state.data.MemberId), barcodeType))
                    ))
            );
    }

    public getLoyaltyHeaderText(): string[] {
        return this._config.ui.loyalty.heroText || [];
    }

    public createAccountWithMemberCard(): void {
        if (this._config.memberCard) {
            this._store.dispatch(actions.MemberAuthorizationSetStep({ step: OLO.Enums.AUTH_STEP.REGISTER_CARD }));
        } else {
            this._store.dispatch(actions.MemberAuthorizationSetStep({ step: OLO.Enums.AUTH_STEP.LOGIN }));
        }
        this._modalsService.show({
            type: 'auth',
        });
    }

    public openModalForReward(freeProductId: number): void {
        this._modalsService.show({
            type: 'reward',
            productId: freeProductId,
        });
    }

    public showMemberCardButton$(): Observable<boolean> {
        return this._store
            .pipe(
                select(selectors.isMemberAuthorizedJWT),
                withLatestFrom(
                    this._store
                        .pipe(
                            select(selectors.getMemberState)
                        )
                ),
                map(([isAuthorized, state]) => !!(isAuthorized && state.data?.CardNumber))
            );
    }

    public openModalForMemberCard(memberId: number): void {
        this._modalsService.show({
            type: 'member-card',
            memberId,
            feel: 'brand-accent'
        });
    }

    public checkForLoyaltyIntroductionPages(): void {
        this._store
            .pipe(
                select(selectors.getLoyaltyIntroductionPages),
                take(1)
            ).subscribe(pages => {
                if (pages.data || pages.isDownloading) return;

                this._loyaltyAppService.requestLoyaltyIntroductionPages();
            });
    }

    public isLoadingLoyaltyIntroductionPages$(): Observable<boolean> {
        return this._store
            .pipe(
                select(selectors.getLoyaltyIntroductionPages),
                map(pages => pages.isDownloading),
            );
    }

    public loyaltyIntroductionPagesPromotionModel$(): Observable<Array<APICommon.IPromotionItem>> {
        return this._store
            .pipe(
                select(selectors.getLoyaltyIntroductionPages),
                combineLatest(
                    this._store
                        .pipe(
                            select(selectors.getLoyaltyIntroductionPagesImages)
                        )
                ),
                map(([pages, images]) => pages.data && pages.data.reduce((acc, page) => {
                    const img: State.ILoyaltyIntroductionPageImage = images.find(obj => obj.PageId === page.Id);
                    const model: APICommon.IPromotionItem = {
                        Id: page.Id,
                        Title: page.Title,
                        Description: page.Text,
                        ImgURL: null
                    };

                    if (img && img.data) {
                        model.ImgURL = img.data;
                    }

                    return [
                        ...acc,
                        model,
                    ];
                }, []) || null)
            );
    }

    public getCurrentMemberLoyaltyProgramProducts$(): Observable<OLO.Ordering.ILoyaltyProgramProductItemModel[]> {
        return this._store
            .pipe(
                select(selectors.getLoyaltyProgramProductItemsForCurrentMember),
            );
    }

    public getCurrentMemberLoyaltyFreeProductsGrouped$(): Observable<OLO.Ordering.ILoyaltyFreeProductItemModel[]> {
        return this._store
            .pipe(
                select(selectors.getLoyaltyFreeProductsForCurrentMemberGrouped)
            );
    }

    public isLoadingLoyaltyInfoForMember$(): Observable<boolean> {
        return this._store
            .pipe(
                select(selectors.isLoadingLoyaltyInfoForCurrentMember),
            );
    }

    public getRewardsForMember$(): Observable<APICommon.IMemberFreeProductModel[]> {
        return this._store
            .pipe(
                select(selectors.freeValidProducts),
                map(items => items ? items.sort((a, b) => {
                    switch (true) {
                        case new Date(a.DateIssued) < new Date(b.DateIssued):
                            return 1;
                        case new Date(a.DateIssued) > new Date(b.DateIssued):
                            return -1;
                        default:
                            return 0;
                    }
                }) : items)
            );
    }
    /* *ngIf="!(loading$ | async) && ((rewards$ | async)?.length === 0 || !(rewards$ | async))" */
    public showNoRewardsInfo$(): Observable<boolean> {
        return this.getRewardsForMember$()
            .pipe(
                withLatestFrom(
                    this.isLoadingLoyaltyInfoForMember$(),
                ),
                map(([rewards, isLoading]) => isLoading === false && rewards && rewards.length === 0)
            );
    }
    public getRewardsTotalQuantity$(): Observable<number> {
        return this.getRewardsForMember$()
            .pipe(
                map(rewards => rewards && rewards.length || 0)
            );
    }

    public getFreeProduct$(freeProductId: number): Observable<APICommon.IMemberFreeProductModel> {
        return this._store
            .pipe(
                select(selectors.getFreeProduct(freeProductId))
            );
    }

    public getFreeProductTitle$(freeProductId: number): Observable<string> {
        return this.getFreeProduct$(freeProductId)
            .pipe(
                map(product => !product ? null : product.OnlineName || product.POSDisplay)
            );
    }

    public currentMemberHasLoyaltyCard$(): Observable<boolean> {
        return this._store
            .pipe(
                select(selectors.isMemberAuthorizedJWT),
                combineLatest(
                    this._store
                        .pipe(
                            select(selectors.getMemberState)
                        )
                ),
                map(([isAuthorized, state]) =>
                    !!(!(this._config.appMode === IAppMode.ORDERING_ONLY)
                        && isAuthorized
                        && state.data?.CardNumber))
            );
    }

    public showMemberCardCode(modalId: number = null, barcodeType: OLO.Enums.BARCODE_SYMBOLOGY = OLO.Enums.BARCODE_SYMBOLOGY.CODE_QR): void {
        this._store
            .pipe(
                select(selectors.getMemberState),
                filter(state => state.data !== null),
                take(1),
                switchMap(state => this._store
                    .pipe(
                        select(selectors.getMemberCardBarcodeImage(state.data.MemberId)),
                        take(1),
                        map(memberCardImage => {
                            if (!state.data.CardNumber) return null;

                            if (!memberCardImage || !memberCardImage.isDownloading && !memberCardImage.data) {
                                this._store.dispatch(actions.MemberCardBarcodesImagesRequest({
                                    memberId: state.data.MemberId,
                                    barcodeType,
                                }));
                            }

                            return state.data.MemberId;
                        })
                    )),
            ).subscribe(memberId => {
                if (!memberId) {
                    console.warn('MemberCardNumber is not assigned to current user');

                    return;
                }
                this._modalsService.show({
                    type: 'member-card',
                    memberId,
                    id: modalId,
                    feel: 'brand-accent'
                });
            });
    }
}
