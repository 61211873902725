import { createSelector, createFeatureSelector, MemoizedSelector } from '@ngrx/store';
import { IStateShared, IDietaryTagImage } from '../interface';

const state = createFeatureSelector<IStateShared, IDietaryTagImage[]>('dietaryTagsImages');

export const getDietaryTagImages = createSelector(
    state,
    dietaryTagsImages => dietaryTagsImages
);

export const getDietaryTagImage = (dietaryTagId: number) => createSelector(
    state,
    dietaryTagsImages => dietaryTagsImages.find(image => image.Id === dietaryTagId)
);

export const getDietaryTagsByTags = (tagParams: APICommon.ITagModel[]): MemoizedSelector<IStateShared, IDietaryTagImage[]> => createSelector(
    state,
    dietaryTagsImages => dietaryTagsImages.reduce((acc, tagImg) => {
        const tag = tagParams.find(obj => obj.Id === tagImg.Id);
        if (tag) {
            return [...acc, { ...tagImg, Name: tag.Name }];
        }

        return acc;
    }, [])
);
