import { Component, OnInit, ViewChild, ElementRef, AfterViewInit, Inject } from '@angular/core';

import * as Utils from '@shared/core/utils';
import * as Tokens from '@shared/core/tokens';
import { DevService } from '../dev.shared.service';

import { Observable } from 'rxjs';

@Component({
    selector: 'shared-dev-console',
    templateUrl: './dev-console.shared.component.html',
    styleUrls: ['./dev-console.shared.component.scss']
})
export class DevConsoleComponent implements OnInit, AfterViewInit {
    private _container: HTMLElement;
    public logs$: Observable<any>;

    @ViewChild('content') public content: ElementRef;

    constructor(
        @Inject(Tokens.ENV_BUILD_TOKEN) public envBuildToken: IEnvironment,
        public devService: DevService,
    ) { }

    ngOnInit(): void {
        this.logs$ = this.devService.getLogsData$();
    }

    ngAfterViewInit(): void {
        if (this.isDev && this.content) {
            this._container = this.content.nativeElement;
        }
    }

    public get isDev(): boolean {
        return this.envBuildToken.production === false || Utils.Storage.getItem(OLO.Enums.USER_STORAGE.DEV_MODE) === '1';
    }
}
