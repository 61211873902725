import { IPaymentExpressCardIdResponse, IPaymentExpressSettingsResponse } from '@shared/state';

export class PaymentExpressPaymentProviderMapper {
    public static mapGetCardDetails(cardDetails: APIv3.PaymentExpressCardIdResponse): IPaymentExpressCardIdResponse {
        return cardDetails ? {
            CardId: cardDetails.CardId,
            NiceName: cardDetails.NiceName
        } : null;
    }

    public static mapGetSettingsForLocation(settingsForLocation: APIv3.PaymentExpressSettingsResponse): IPaymentExpressSettingsResponse {
        return settingsForLocation ? {
            Url: settingsForLocation.Url,
            SessionToken: settingsForLocation.SessionToken
        } : null;
    }
}
