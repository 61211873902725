import { ActionReducer, Action } from '@ngrx/store';
import * as StateModels from '../interface';

export function resetMetaReducer(reducer: ActionReducer<StateModels.IStateShared>): ActionReducer<StateModels.IStateShared> {
    return function (state: StateModels.IStateShared, action: Action): StateModels.IStateShared {
        switch (action.type) {
            case 'RESET':
                return reducer(undefined, action);

            default: {
                return reducer(state, action);
            }
        }
    };
}
