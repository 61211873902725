import { Injectable, Inject, Injector } from '@angular/core';
import { Store } from '@ngrx/store';
import {
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor,
    HttpErrorResponse,
} from '@angular/common/http';

import * as Utils from '@shared/core/utils';
import * as Tokens from '@shared/core/tokens';
import * as Services from '@shared/core/services';
import * as actions from '@shared/state/actions';
import { IStateShared } from '@shared/state';

import { Observable, throwError, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

@Injectable()
export class ApiInterceptor implements HttpInterceptor {
    private _jwtService: Services.JWTService;
    private _inflightAuthRequest: Observable<OLO.Authorization.IJWTokenObject> = null;

    constructor(
        @Inject(Tokens.CONFIG_TOKEN) private _config: IConfig,
        private _injector: Injector,
        private _store: Store<IStateShared>,
    ) { }

    private _handleNext(next: HttpHandler, request: HttpRequest<any>, addClientAppSignature: boolean = false): Observable<HttpEvent<any>> {
        const clientAppSignature = addClientAppSignature ?
            request.clone({
                headers: request.headers.set('clientAppSignature', Utils.HTTP.getClientAppSignature(request, this._config.api.key))
            }) : request;

        return next.handle(clientAppSignature)
            .pipe(
                map((res) => {
                    this._store.dispatch(actions.AppSettingsSetOnlineStatus({ online: true }));

                    return res;
                }),
                catchError((ex: HttpErrorResponse) => {
                    console.log(ex);
                    if (ex.status === 0) {
                        console.warn('Connection status: Offline!');
                        this._store.dispatch(actions.AppSettingsSetOnlineStatus({ online: false }));
                    } else {
                        this._store.dispatch(actions.AppSettingsSetOnlineStatus({ online: true }));
                    }

                    return throwError(ex);
                })
            );
    }

    private _handleUnauthorizedExeption(): Observable<null> {

        this._store.dispatch(
            actions.MemberSignOut({ redirect: '/', resetCart: false })
        );

        return of(null);
    }

    public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        this._jwtService = this._injector.get(Services.JWTService);

        switch (true) {
            case request.url.match(/\/api\/v\d\//i) === null:
                return this._handleNext(next, request)
                    .pipe(
                        catchError(ex => {
                            if (ex.status === 403) {
                                return this._handleUnauthorizedExeption();
                            }

                            return throwError(ex);
                        })
                    );


            case request.headers.get('authExempt') === 'true':
                return this._handleNext(next, request, true)
                    .pipe(
                        catchError(ex => {
                            if (ex.status === 403) {
                                return this._handleUnauthorizedExeption();
                            }

                            return throwError(ex);
                        })
                    );


            case request.url.match(/\/api\/v3\//i) !== null:
                this._inflightAuthRequest = this._jwtService.getCurrentTokens();

                return this._inflightAuthRequest
                    .pipe(
                        switchMap((tokens: OLO.Authorization.IJWTokenObject) => {
                            const withJWTRequest = request.clone({
                                headers: request.headers
                                    .set('Authorization', `Bearer ${Utils.HTTP.cleanHeaders(`${tokens.AccessToken}`)}`)
                                    .set('ClientAppKey', `${Utils.HTTP.cleanHeaders(`${this._config.api.key}`)}`)
                            });

                            return this._handleNext(next, withJWTRequest, true);
                        }),
                        catchError(ex => {
                            if (ex.hasOwnProperty('status') && ex.status !== 401 && ex.status !== 403) return throwError(ex);

                            const withJWTRequest = request.clone({
                                headers: request.headers
                                    .set('ClientAppKey', `${Utils.HTTP.cleanHeaders(`${this._config.api.key}`)}`)
                            });

                            return this._handleNext(next, withJWTRequest, true);

                        })
                    );

            default:
                return this._handleNext(next, request);
        }

    }
}
