import { createSelector, createFeatureSelector, MemoizedSelector } from '@ngrx/store';
import { IStateShared, ILocationsImages } from '../interface';
import * as Utils from '@shared/core/utils';

const state = createFeatureSelector<IStateShared, ILocationsImages>('locationsImages');

export const getAllLocationImages = createSelector(
    state,
    locationsImages => locationsImages
);

export const getLocationImage = (imageType: OLO.Enums.IMAGE_TYPE, locationNo: number): MemoizedSelector<IStateShared, OLO.Common.IImageStateObj> => createSelector(
    state,
    locationsImages => {
        let targetImageType: string = Utils.Images.toImageTypeString(imageType);

        return locationsImages[targetImageType].find(obj => obj.Id === locationNo);
    }
);
