export class XML {
    public static stringToXmlDoc(xmlString: string): Document | Element {
        if (xmlString && typeof xmlString === 'string' && DOMParser) {
            try {
                const doc: Document | Element = (new DOMParser()).parseFromString(xmlString, `text/xml`);
                return doc;
            } catch (ex) {
                console.error('XMLSERVICE stringToXmlDoc: ', ex);
                return null;
            }
        }

        return null;
    }

    public static xml2json(xml: Document | Element): any {
        if (!xml) { return null; }
        /* https://stackoverflow.com/a/20861541 */
        try {
            let obj = {};
            if (xml.children.length > 0) {
                for (let i = 0; i < xml.children.length; i++) {
                    const item = xml.children.item(i);
                    const nodeName = item.nodeName;

                    if (typeof (obj[nodeName]) === `undefined`) {
                        obj[nodeName] = XML.xml2json(item);
                    } else {
                        if (typeof (obj[nodeName].push) === `undefined`) {
                            const old = obj[nodeName];

                            obj[nodeName] = [];
                            obj[nodeName].push(old);
                        }
                        obj[nodeName].push(XML.xml2json(item));
                    }
                }
            } else {
                obj = xml.textContent;
            }
            return obj;
        } catch (ex) {
            console.error('XMLSERVICE xml2json: ', ex);
            return null;
        }
    }

    public static xmlString2Json(xmlString: string): any {
        return XML.xml2json(XML.stringToXmlDoc(xmlString));
    }
}
