import { createSelector, createFeatureSelector } from '@ngrx/store';
import { IStateShared, ITopBar } from '../interface';

const state = createFeatureSelector<IStateShared, ITopBar>('topBar');

export const isTopBarSticky = createSelector(
    state,
    topBar => topBar.isSticky
);

export const isCartVisible = createSelector(
    state,
    topBar => topBar.isCartVisible
);

export const isBackToLandingVisible = createSelector(
    state,
    topBar => topBar.isBackToLandingVisible
);

export const isBackToStoreVisible = createSelector(
    state,
    topBar => topBar.isBackToStoreVisible
);
