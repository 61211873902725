// https://stripe.com/docs/payments/accept-a-payment-synchronously
import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import * as Stripe from '@stripe/stripe-js';

import * as Tokens from '@shared/core/tokens';

import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { IStripeSettingsResponse } from '@shared/state';
import { StripePaymentProviderMapper } from '@shared/core/mappers';


@Injectable({
    providedIn: 'root'
})
export class StripePaymentProviderService {
    private _instance: Stripe.Stripe;
    private _elements: Stripe.StripeElements;
    private _htmlElementsRegistry: Stripe.StripeHtmlElementsRegistry = {};

    private _scriptElem: HTMLScriptElement;

    private _commonStyles: Stripe.StripeElementStyle = {
        base: {
            fontSize: '16px',
        },
        invalid: {
            color: 'red',
            iconColor: 'red',
        }
    };

    constructor(
        @Inject(Tokens.CONFIG_TOKEN) public config: IConfig,
        public httpClient: HttpClient,
    ) {
    }

    public requestConfig(locationNo: number): Observable<IStripeSettingsResponse> {
        if (!locationNo) {
            return throwError('No locationNo provided for Stripe payment provider');
        }

        return this._getSettingsForLocation(locationNo);
    }

    protected _getSettingsForLocation(locationNo: number): Observable<IStripeSettingsResponse> {
        return this.httpClient
            .get<APIv3.PaymentsGetStripeSettings.Responses.$200>(`${this.config.api.base}/Payments/stripe/settings/${locationNo}`)
            .pipe(
                map(response => StripePaymentProviderMapper.mapStripePaymentGetSettingsForLocation(response)),
                catchError(ex => {
                    console.error('LocationNo not provided', ex);

                    return throwError(ex);
                })
            );
    }

    public async addHtmlElementsToDOM(): Promise<boolean> {
        return this._addScriptElement();
    }

    public setupForm(config: IStripeSettingsResponse, targetHtmlElements: Stripe.StripeTargetElementsList, options: Stripe.ElementsConfiguration = {}): Stripe.StripeInitResult {
        if(this._elements || this._instance) {
            this.destroyForm();
        }

        this._instance = window.Stripe(config.ApiKey, {
            locale: this.config.localization.country.toLowerCase() as any,
        });
        this._elements = this._instance.elements();

        for(const fieldType in targetHtmlElements) {
            this._htmlElementsRegistry[fieldType] = this._elements.create(fieldType as any, {
                ...options[fieldType],
                style: {
                    ...this._commonStyles,
                    ...options[fieldType]?.style
                }
            });

            this._htmlElementsRegistry[fieldType].mount(targetHtmlElements[fieldType]);
        }

        return {
            registry: this._htmlElementsRegistry,
            stripe: this._instance,
        };
    }

    public destroyForm(): void {
        this._instance = null;
        this._elements = null;
        this._htmlElementsRegistry = {};
    }

    protected async _addScriptElement(): Promise<boolean> {
        if (this._scriptElem) return null;

        return new Promise((resolve, reject) => {
            this._scriptElem = document.createElement('script');
            this._scriptElem.src = 'https://js.stripe.com/v3/';
            this._scriptElem.crossOrigin = 'anonymous';

            this._scriptElem.onload = () => resolve(true);
            this._scriptElem.onerror = () => reject('Unable to load stripe script element');

            document.body.appendChild(this._scriptElem);
        });

    }

    protected _removeScriptElement(): void {
        if (!this._scriptElem) return;

        document.body.removeChild(this._scriptElem);
        this._scriptElem = null;
    }

}
