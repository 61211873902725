import { createReducer, on, Action } from '@ngrx/store';
import * as actions from './geolocation.actions';

import { IGeolocation } from './geolocation.interface';

const initialState: IGeolocation = {
    isRequesting: false,
    hasSucceeded: false,
    hasFailed: false,
    data: null,
};

export function geolocationReducer(state: IGeolocation | undefined, action: Action) {
    return createReducer(
        initialState,
        on(
            actions.geolocationPositionRequest,
            (state, action) => ({
                ...state,
                isRequesting: true,
                hasSucceeded: false,
                hasFailed: false,
            })
        ),
        on(
            actions.geolocationPositionSuccessRequest,
            (state, action) => ({
                ...state,
                isRequesting: false,
                hasSucceeded: true,
                hasFailed: false,
                data: action.payload,
            })
        ),
        on(
            actions.geolocationPositionErrorRequest,
            (state, action) => ({
                ...state,
                isRequesting: false,
                hasSucceeded: false,
                hasFailed: true,
            })
        ),
    )(state, action);
}
