import { Injectable, Inject } from '@angular/core';
import { Store, select, Action } from '@ngrx/store';
import { Actions, Effect, ofType } from '@ngrx/effects';

import * as actions from '../actions';
import * as selectors from '../selectors';

import * as Utils from '@shared/core/utils';
import * as Services from '@shared/core/services';
import * as Tokens from '@shared/core/tokens';

import * as StateModels from '../interface';

import { Observable, of, forkJoin } from 'rxjs';
import { switchMap, catchError, map, withLatestFrom, mergeMap } from 'rxjs/operators';


@Injectable()
export class LocationsImagesEffects {
    @Effect() public onSuccessLocationsRequestGetImages$: Observable<Action> = this._actions$
        .pipe(
            ofType(
                actions.LocationsSuccessRequest
            ),
            switchMap(action => {
                const width = Math.floor(window.innerWidth + (window.innerWidth * 0.1));
                const height = Math.floor(window.innerHeight + (window.innerHeight * 0.1));
                const webWidth: number = window.innerWidth > window.innerHeight ? window.innerWidth : null;
                const webHeight: number = window.innerWidth > window.innerHeight ? null : window.innerHeight;

                let thumbSizeRation: number = 3.2;

                if (window.innerWidth > 2000) {
                    thumbSizeRation = 4.2;
                }

                if (window.innerWidth < 1000) {
                    thumbSizeRation = 2.2;
                }

                if (window.innerWidth < 610) {
                    thumbSizeRation = 1.2;
                }

                return [
                    actions.LocationImagesRequest(action.payload.map(location => location.LocationNo), OLO.Enums.IMAGE_TYPE.ForList, Math.floor(width / thumbSizeRation), Math.floor(height / thumbSizeRation)),
                    actions.LocationImagesRequest(action.payload.map(location => location.LocationNo), OLO.Enums.IMAGE_TYPE.ForWeb, webWidth, webHeight)
                ];
            })
        );

    @Effect() public requestImagesForLocations$: Observable<Action> = this._actions$
        .pipe(
            ofType(
                actions.LocationImagesRequest
            ),
            withLatestFrom(
                this._store.pipe(
                    select(
                        selectors.getAllLocationImages
                    )
                )
            ),
            mergeMap(([action, images]) => {
                const { imageType, height, width, ids } = action;
                let targetImageType: string = Utils.Images.toImageTypeString(imageType);
                /*
                    Prevent from redownloading same image
                */
                const imagesFiltered = {
                    downloaded: [],
                    required: [],
                };

                ids.forEach(id => {
                    const img = images[targetImageType].find(obj => obj.Id === id);

                    if (img.data) {
                        imagesFiltered.downloaded.push(img);
                    } else {
                        imagesFiltered.required.push(img.Id);
                    }
                });

                if (imagesFiltered.downloaded.length) {
                    this._store.dispatch(actions.LocationImagesSuccessRequest({ imageType, ids: imagesFiltered.downloaded.map(img => img.Id), payload: imagesFiltered.downloaded }));
                }

                return this._imagesService.getImagesForLocationsByType({ imageType: imageType > 100 ? imageType / 100 : imageType, height, width }, ...imagesFiltered.required)
                    .pipe(
                        switchMap(payload => {
                            if (this._config.predownloadImages && this._config.predownloadImages.forLocations) {
                                // console.log('predownloading images for locations', this._config.predownloadImages);
                                return forkJoin(
                                    ...payload.map(img => this._imagesService.preloadImageInMemory(img.ImageUrl))
                                ).pipe(
                                    map(() => actions.LocationImagesSuccessRequest({ imageType, ids: imagesFiltered.required, payload })),
                                );
                            }

                            return of(actions.LocationImagesSuccessRequest({ imageType, ids: imagesFiltered.required, payload }));
                        }),
                        catchError(ex => of(actions.LocationImagesErrorRequest({ imageType, ids: imagesFiltered.required, ex })))
                    );
            }),
        );

    constructor(
        @Inject(Tokens.CONFIG_TOKEN) private _config: IConfig,
        private _actions$: Actions,
        private _store: Store<StateModels.IStateShared>,
        private _imagesService: Services.ImagesService,
    ) { }
}
