import { createSelector, createFeatureSelector } from '@ngrx/store';
import { IStateShared, ILoyaltyProductProgramItem } from '../interface';

const state = createFeatureSelector<IStateShared, ILoyaltyProductProgramItem>('loyaltyProductPrograms');

export const getLoyaltyProductPrograms = createSelector(
    state,
    loyaltyProductPrograms => loyaltyProductPrograms,
);

export const getAllValidLoyaltyPrograms = createSelector(
    state,
    loyaltyProductPrograms => {
        const now = new Date();

        return loyaltyProductPrograms.data?.reduce((acc, program) => {
            if (new Date(program.EndDate) <= now || new Date(program.StartDate) > now) return acc;

            return [
                ...acc,
                program
            ];
        }, [] as APICommon.IGetLoyaltyProductProgramBusinessModel[]) || null;
    }
);

export const isDownloadingAnyProgram = createSelector(
    state,
    loyaltyProductPrograms => loyaltyProductPrograms.isDownloading === true
);

export const hasRequestedLoyaltyProductPrograms = createSelector(
    state,
    loyaltyProductPrograms => loyaltyProductPrograms.data?.length > 0,
);
