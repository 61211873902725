import { Component, OnInit, AfterViewInit, Input, Inject, HostListener } from '@angular/core';
import { Store, select } from '@ngrx/store';

import * as Utils from '@shared/core/utils';
import * as Services from '@shared/core/services';
import * as State from '@shared/state';
import * as Tokens from '@shared/core/tokens';

import * as actions from '@shared/state/actions';
import * as selectors from '@shared/state/selectors';

import { Observable, Subscription, BehaviorSubject } from 'rxjs';
import { combineLatest, map, take } from 'rxjs/operators';

@Component({
    selector: 'shared-dev',
    templateUrl: './dev.shared.component.html',
    styleUrls: ['./dev.shared.component.scss'],
})
export class DevComponent implements OnInit, AfterViewInit {
    public readonly states = {
        HIDDEN: '0',
        MINIMIZED: '1',
        VISIBLE: '2',
    };

    public devBoxVisibility: 'is-hidden' | 'is-maximized' | 'is-minimized' = null;
    public state$: Observable<State.IStateShared>;
    public stateFilter$ = new BehaviorSubject('');
    public textFilter: string = '';

    @Input() public position = 'bl';
    @Input() public sticky: boolean = true;

    @HostListener('window:keypress', ['$event']) public specialKeyListener(e: KeyboardEvent): void {
        if (e.charCode === 96 && (e.target as HTMLElement).nodeName !== 'INPUT') {
            const current: string = this._getStore();
            if (this.states.VISIBLE === current) {
                this.hide();
            } else {
                this.maximize();
            }
        }
    }

    constructor(
        @Inject(Tokens.CONFIG_TOKEN) private _config: IConfig,
        @Inject(Tokens.ENV_BUILD_TOKEN) private _envBuildToken: IEnvironment,
        private _store: Store<State.IStateShared>,
        private _memberService: Services.MembersService,
        private _modalsService: Services.ModalsService,
    ) { }

    public get isDevmode(): boolean {
        return this._envBuildToken.production === false || Utils.Storage.getItem(OLO.Enums.USER_STORAGE.DEV_MODE) === '1';
    }

    /* Storage methods */
    private _getStore(): string {
        return Utils.Storage.getItem(OLO.Enums.USER_STORAGE.DEV_DEBUG_STATE);
    }

    public handleFilterChange($event) {
        Utils.Storage.set(OLO.Enums.USER_STORAGE.DEV_CONSOLE_FILTER, $event.target.value);
        this.stateFilter$.next($event.target.value);
    }

    public get autoRestoreState(): boolean {
        return Utils.Storage.getItem(OLO.Enums.USER_STORAGE.DEV_AUTORESTORE_STATE) === '1';
    }

    ngOnInit() {
        // console.log(this._envBuildToken);

        this.textFilter = Utils.Storage.getItem(OLO.Enums.USER_STORAGE.DEV_CONSOLE_FILTER) || '';
        this.stateFilter$.next(this.textFilter);
        this.state$ = this._store.select(selectors.getState)
            .pipe(
                combineLatest(this.stateFilter$),
                map(([state, filter]) => {
                    if (!filter) {
                        return state;
                    }
                    const filteredState = {};

                    for (const prop in state) {
                        if (prop.toLowerCase().includes(filter.toLowerCase())) {
                            filteredState[prop] = state[prop];
                        }
                    }

                    return filteredState;
                })
            );

        if (this.autoRestoreState) {
            Utils.Redirect.setRedirect();
        }

        switch (this._getStore()) {
            case this.states.VISIBLE:
                this.maximize();
                break;
            case this.states.MINIMIZED:
                this.minimize();
                break;
            default:
                this.hide();

                return;
        }
    }

    ngAfterViewInit() {


        if (this.autoRestoreState) {
            setTimeout(() => {
                this.restoreState();
            }, 10);

        }
    }

    public killJWT(): void {
        Utils.Storage.set(OLO.Enums.JWT.ACCESS_TOKEN_EXPIRES as unknown as string, new Date().getTime() - 999999);
        Utils.Storage.set(OLO.Enums.JWT.REFRESH_TOKEN_EXPIERS as unknown as string, new Date().getTime() - 100000);
    }

    public searchForAddress(): void {
        this._store.dispatch(actions.GooglePlaceSearchRequest({ search: 'Straussa 1' }));
    }

    /* Custom methods */
    public saveState() {
        Utils.Storage.set(OLO.Enums.USER_STORAGE.DEV_AUTORESTORE_STATE, '1');
        Utils.Redirect.setRedirect();
        this._store.dispatch(actions.StateSave());
    }

    public restoreState() {
        this._store.dispatch(actions.StateRestore({ setProps: null }));
        setTimeout(() => {
            this._store.dispatch(actions.StateSave());
        }, 10);
    }

    public deliveryNotAvailableModal(): void {
        this._store
            .pipe(
                select(selectors.getCurrentLocationNo),
                take(1)
            )
            .subscribe(locationNo => {
                this._modalsService.show({
                    type: 'delivery-not-available',
                    locationNo,
                });
            });
    }

    public openDeliveryAddressModal(): void {
        this._store
            .pipe(
                select(selectors.getCurrentLocationNo),
                take(1)
            )
            .subscribe(locationNo => {
                this._modalsService.show({
                    type: 'delivery-address',
                    locationNo,
                });
            });
    }

    public openTableNumberModal(): void {
        this._store
            .pipe(
                select(selectors.getCurrentLocationNo),
                take(1)
            )
            .subscribe(locationNo => {
                this._modalsService.show({
                    type: 'dine-in',
                    locationNo,
                    params: {
                        buzzer: false,
                        tableNo: 10,
                        img: this._config.collectionTypes.dineIn?.dineInTable?.modalIcon

                    }
                });
            });
    }

    public toggleDevAutoRestoreState(): void {
        if (this.autoRestoreState) {
            Utils.Redirect.unsetRedirect();
            Utils.Storage.remove(OLO.Enums.USER_STORAGE.DEV_AUTORESTORE_STATE);
        } else {
            Utils.Redirect.setRedirect();
            Utils.Storage.set(OLO.Enums.USER_STORAGE.DEV_AUTORESTORE_STATE, '1');
        }
    }

    public setLinkInfoStep(): void {
        this._store.dispatch(actions.MemberAuthorizationSetStep({ step: OLO.Enums.AUTH_STEP.ACCOUNT_LINKING_REWARDS_INFO }));
    }

    public payAsGuest() {
        const model: APICommon.IOnlineOrderPartialMember = {
            FirstName: 'Pawciu',
            LastName: 'Test',
            Email: `tro${new Date().getTime()}@test.pl`,
            MobileNumber: '6666666669',
            Prefix: '+1',
            MobilePhoneCountryId: 222,
        };

        this._store.dispatch(actions.OnlineOrderRecalculateRequest());

        return this._store.dispatch(actions.PaymentInitAramark({
            cardNo: '4000000000000002',
            expDate: '11-01-22',
            save: false,
        }, model));
    }


    public onFilter($event: string): void {
        if ($event) {
            Utils.Storage.set(OLO.Enums.USER_STORAGE.DEV_CONSOLE_FILTER, $event);
            this.stateFilter$.next($event);
        } else {
            Utils.Storage.remove(OLO.Enums.USER_STORAGE.DEV_CONSOLE_FILTER);
            this.stateFilter$.next('');
        }
    }


    /* Custom */
    public setupGuestDataAfterSignUp(memberId: number = 2149): void {
        this._memberService.getUserData()
            .subscribe(member => {
                this._store
                    .pipe(
                        select(selectors.getMemberState),
                        take(1),
                    ).subscribe(currentState => {

                        this._store.dispatch(actions.__DevSetState({
                            state: {
                                members: {
                                    ...currentState,
                                    signUp: {
                                        isSigningUp: false,
                                        hasSucceeded: true,
                                        hasFailed: false,
                                    },
                                    isGuestModeEnabled: true,
                                    accountId: memberId,
                                    data: member,
                                }
                            }
                        }));


                    });

            });

    }

    public setDeleteAccountStep(): void {
        this._modalsService.show({
            type: 'delete-profile'
        });
    }


    public initPaymentRedirectFlow(): void {
        Utils.Redirect.setRedirect();
        this._store.dispatch(actions.StateSave());

        setTimeout(() => {
            window.location.reload();
        });
    }


    public finishPaymentRedirectFlow(): void {
        Utils.Redirect.unsetRedirect()
            .then(() => {
                this._store.dispatch(actions.StateRestore({ setProps: null }));
            });
    }

    /* Common dev box methods */
    public normalize(): void {
        Utils.Storage.remove(OLO.Enums.USER_STORAGE.DEV_DEBUG_STATE);
        this.devBoxVisibility = null;
    }

    public hide(): void {
        Utils.Storage.set(OLO.Enums.USER_STORAGE.DEV_DEBUG_STATE, this.states.HIDDEN);
        this.devBoxVisibility = 'is-hidden';
    }

    public maximize(): void {
        Utils.Storage.set(OLO.Enums.USER_STORAGE.DEV_DEBUG_STATE, this.states.VISIBLE);
        this.devBoxVisibility = 'is-maximized';
    }

    public minimize(): void {
        this.normalize();
        Utils.Storage.set(OLO.Enums.USER_STORAGE.DEV_DEBUG_STATE, this.states.MINIMIZED);
        this.devBoxVisibility = 'is-minimized';
    }

}
