export function AutoUnsubscribe(...args) {
    return function (targetClass) {
        /* Update ngOnDestroy */
        let isNgOnDestroyDefined: boolean = targetClass.prototype.ngOnDestroy !== undefined && typeof targetClass.prototype.ngOnDestroy === 'function';


        if (!isNgOnDestroyDefined) {
            console.warn(`WARNING! No ngOnDestroy method detected in: "${targetClass.name}". Please add it to enanble AutoUnsubscribe`, this);
        }

        const originalFunction = targetClass.prototype.ngOnDestroy;
        Object.defineProperty(targetClass.prototype, 'ngOnDestroy', {
            value: function () {
                try {
                    for (const prop in this) {
                        if (this[prop] && this[prop].unsubscribe && typeof this[prop].unsubscribe === 'function') {
                            this[prop].unsubscribe();
                        }
                    }

                } catch (ex) {
                    console.error('Autounsubscribe error: ', ex);
                }

                if (typeof originalFunction === 'function') {
                    originalFunction.apply(this, arguments);
                }

            }
        });
    };
}
