import { createSelector, createFeatureSelector } from '@ngrx/store';
import { IStateShared, ILatestTransaction, ILoyaltyAppTransactionModel } from '../interface';

const state = createFeatureSelector<IStateShared, ILatestTransaction[]>('latestTransactions');

export const getLatestTransactions = createSelector(
    state,
    latestTransactions => latestTransactions,
);

export const getLatestTransactionsForMember = (memberId: number) => createSelector(
    state,
    latestTransactions => latestTransactions.find(obj => obj.memberId === memberId)
);
export const isDownloadingAnyTransaction = createSelector(
    state,
    latestTransactions => latestTransactions.some(obj => obj.isDownloading === true)
);

export const hasRequestedlatestTransactions = createSelector(
    state,
    latestTransactions => latestTransactions.length > 0,
);

export const getTransactionById = (transactionId: number) => createSelector(
    state,
    latestTransactions => latestTransactions.reduce<ILoyaltyAppTransactionModel>((acc, obj) => {
        if (acc || !obj.data) return acc;

        return obj.data.find(transaction => transaction.TransactionId === transactionId) || acc;
    }, null),
);
