import { Strings } from './strings.utils';
import CryptoJS from 'crypto-js';
// import { RequestOptions, Headers, Response } from '@angular/common/http'; /* For none authorized users */

import { Observable, fromEvent, merge } from 'rxjs';
import { map } from 'rxjs/operators';
import { HttpRequest } from '@angular/common/http';

export class HTTP {
    public static isOnline$(): Observable<boolean> {
        return merge(
            fromEvent(window, 'online'),
            fromEvent(window, 'offline'),
        ).pipe(
            map((event: Event) => event.type === 'online')
        );
    }

    // public static createUnauthorizedRequestOptions(headers?: string): RequestOptions {
    //     const options: RequestOptions = new RequestOptions({
    //         headers: new Headers()
    //     });

    //     if (headers && typeof headers === 'string') {
    //         options.headers.set('Authorization', `Key ${Strings.removeQuotesChars(headers)}`);
    //     }

    //     return options;
    // }

    public static switchApi(urlString: string): string {
        return urlString.replace('api/v1', 'api/v2');
    }

    public static object2string(rawParams: { [param: string]: any; }): string {
        const tempArr: string[] = [];

        Object.keys(rawParams).forEach((key: string) => {
            if (rawParams[key] instanceof Array) {

                for (const value of rawParams[key]) {
                    tempArr.push(`${key}=${encodeURIComponent(value)}`);
                }

            } else {
                tempArr.push(`${key}=${encodeURIComponent(rawParams[key])}`);
            }
        });

        return tempArr.length ? `?${tempArr.join('&')}` : '';
    }

    public static idsToStringParams(paramName: string, ids: number[]) {
        return ids.map(id => `${paramName}=${id}`).join('&');
    }

    public static cleanHeaders(value: string): string {
        return Strings.removeQuotesChars(value);
    }

    public static getClientAppSignature(req: HttpRequest<any>, clientAppKey: string): string {
        const api = '/api/';
        const content = req.body ? JSON.stringify(req.body) : '';
        const path = api + req.urlWithParams.split(api)[1];

        return CryptoJS.enc.Base64.stringify(
            CryptoJS.HmacSHA256(
                CryptoJS.enc.Utf8.parse([path, content].join('\n')),
                CryptoJS.enc.Utf8.parse(clientAppKey)
            ));
    }
}
