import { createSelector, createFeatureSelector } from '@ngrx/store';
import { IStateShared, ILocationsFilters } from '../interface';

const state = createFeatureSelector<IStateShared, ILocationsFilters>('locationsFilters');

export const getLocationFilters = createSelector(
    state,
    locationsFilters => locationsFilters
);

export const getLocationFiltersPickupMode = createSelector(
    state,
    locationsFilters => locationsFilters.pickupMode,
);

export const getLocationFilterSelectedPickupTime = createSelector(
    state,
    locationsFilters => {
        if (!locationsFilters.pickupTime) return null;

        return locationsFilters.pickupTime;
    }
);

export const getLocationFiltersSearchInput = createSelector(
    state,
    locationsFilters => locationsFilters?.search || null
);


export const getLocationFiltersAddress = createSelector(
    state,
    locationsFilters => locationsFilters?.address || null
);

export const getLocationFiltersGeocoder = createSelector(
    state,
    locationsFilters => locationsFilters?.geocoder || null
);

export const isSearchingByNearestPlace = createSelector(
    getLocationFiltersSearchInput,
    getLocationFiltersGeocoder,
    (term, geocoder) => !!(term && geocoder)
);
