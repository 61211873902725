import { Injectable } from '@angular/core';

import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class CartPopupService {
    public offset: number = 10;
    public coordsRegistry: Map<string, ClientRect | DOMRect> = new Map();

    public targetCoords$: BehaviorSubject<ClientRect> = new BehaviorSubject(null);

    public setTargetCoords(elem: HTMLElement): void {
        this.coordsRegistry.set('target', elem.getClientRects()[0]);
        this.targetCoords$.next(this.coordsRegistry.get('target'));
    }

    public unsetTargetCoords(): void {
        this.coordsRegistry.delete('target');

        this.targetCoords$.next(null);
    }

    public getTargetCoords(): ClientRect | DOMRect {
        return this.coordsRegistry.get('target') || null;
    }

}
