export interface IMetaTags {
    title?: string;
    keywords?: string;
    description?: string;
    author?: string;
}

export class Meta {
    public static async setTags(tags: IMetaTags = {}): Promise<boolean> {
        try {
            for (let tag in tags) {
                if (tag === 'title') {
                    let titleTag = document.querySelector('title');
                    if (!titleTag) {
                        titleTag = document.createElement('title');
                        document.head.appendChild(titleTag);
                    }

                    titleTag.innerHTML = tags[tag];
                } else {
                    let metaTag = document.querySelector(`meta[name="${tag}"]`);
                    if (!metaTag) {
                        metaTag = document.createElement('meta');
                        metaTag.setAttribute('name', name);
                        document.head.appendChild(metaTag);
                    }

                    metaTag.setAttribute('content', tags[tag]);
                }
            }

            return true;
        } catch (ex) {
            console.error(ex);
            return false;
        }
    }

    public static async clearTags(...tags: ('title' | 'keywords' | 'description' | 'author')[]): Promise<boolean> {
        try {

            for (let val in tags) {
                let tag;

                if (val === 'title') {
                    tag = document.querySelector('title');
                } else {
                    tag = document.querySelector(`meta[name="${val}"]`);
                }

                if (tag) {
                    if (val === 'title') {
                        tag.innerHTML = '';
                        return;
                    }

                    tag.setAttribute('content', '');
                }
            }

            return true;

        } catch (ex) {
            console.error(ex);
            return false;
        }
    }

    public static fixTitle(value: string, separator: string = ' - '): Promise<void> {
        return new Promise(resolve => {
            const PATTERN = /\{[^\}]*\}/i;
            const titleTag = document.querySelector('title');
            if (titleTag) {
                const foundReplacer = titleTag.innerText.match(PATTERN);
                if (foundReplacer) {
                    titleTag.innerHTML = titleTag.innerHTML.replace(PATTERN, value);
                } else {
                    titleTag.innerHTML = `${value}${separator}${titleTag.innerHTML}`;
                }
            }

            return true;
        });
    }

    public static unsetFixedTitle(value: string, separator: string = ' - '): Promise<void> {
        return new Promise(resolve => {
            if (!value) {
                return true;
            }
            const titleTag = document.querySelector('title');

            if (titleTag) {
                titleTag.innerHTML = titleTag.innerHTML.replace(`${value}${separator}`, '');
            }
            // if (titleTag) {
            //     const foundReplacer = titleTag.innerText.match(PATTERN);
            //     if (foundReplacer) {
            //         titleTag.innerHTML = titleTag.innerHTML.replace(PATTERN, value);
            //     } else {
            //         titleTag.innerHTML = `${value}${separator}${titleTag.innerHTML}`;
            //     }
            // }

            return true;
        });
    }
}
