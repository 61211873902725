export class Arrays {
    public static findCommonItems<T>(arr1: T[], arr2: T[]): T[] | null {
        try {
            if (!(arr1 instanceof Array) || !(arr2 instanceof Array)) {
                throw new Error('Unable to find common items in arrays. Invalid arguments. Need to provide arrays');
            }
            const _a1 = [...arr1].sort((a: any, b: any) => a - b);
            const _a2 = [...arr2].sort((a: any, b: any) => a - b);

            const initial = _a1.length > _a2.length ? _a2 : _a1;
            const second = initial === _a1 ? _a2 : _a1;

            const common = initial.reduce((acc, item, index) => {
                if (second.includes(item)) {
                    return [...new Set([
                        ...acc,
                        item
                    ])];
                }

                return acc;
            }, [] as T[]);

            return common.length ? common : null;

        } catch (error) {
            console.error(error, 'Provided:', arr1, arr2);

            return null;
        }

    }

    public static sortByDisplayIndex(items: any[], order: string = 'asc'): any[] {
        if (!items || items instanceof Array && items.length === 0) {
            return items;
        }

        let newArr: any[];

        if (order === 'desc') {
            newArr = items.sort((item1: any, item2: any) => {
                if (!item1.hasOwnProperty('DisplayIndex') || !item2.hasOwnProperty('DisplayIndex')) {
                    return 0;
                }

                return item2['DisplayIndex'] - item1['DisplayIndex'];
            });
        } else {
            newArr = items.sort((item1: any, item2: any) => {
                if (!item1.hasOwnProperty('DisplayIndex') || !item2.hasOwnProperty('DisplayIndex')) {
                    return 0;
                }

                return item1['DisplayIndex'] - item2['DisplayIndex'];
            });
        }

        return newArr;
    }

    public static sortByProp(propName: string, direction: string = 'desc'): any {
        return (a, b) => {
            switch (true) {
                case (direction === 'desc' ? a[propName] < b[propName] : a[propName] > b[propName]):
                    return 1;
                case (direction === 'desc' ? a[propName] > b[propName] : a[propName] < b[propName]):
                    return -1;
                default:
                    return 0;
            }
        };
    }

    public static remove(arr: any[], element: any): any[] {
        return arr.filter(item => item !== element);
    }
}
