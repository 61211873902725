import { createSelector, createFeatureSelector } from '@ngrx/store';
import { IStateShared, IReorder } from '../interface';

const state = createFeatureSelector<IStateShared, IReorder[]>('reorder');

export const getReorder = (orderId: number, locationNo: number, pickupTime: OLO.Ordering.IPickupTime) => createSelector(
    state,
    reorder => {
        if (!orderId || !locationNo || !pickupTime) return null;
        const foundReorder = reorder.find(obj => obj.LocationNo === locationNo && obj.OrderId === orderId && obj.PickupTime !== null && obj.PickupTime.Id === pickupTime.Id);
        return foundReorder || null;
    }
);

export const canAddToCartReorderItems = (orderId: number, locationNo: number, pickupTime: OLO.Ordering.IPickupTime) => createSelector(
    state,
    reorder => {
        if (!orderId || !locationNo || !pickupTime) return false;
        const foundReorder = reorder.find(obj => obj.LocationNo === locationNo && obj.OrderId === orderId && obj.PickupTime !== null && obj.PickupTime.Id === pickupTime.Id);
        if (!foundReorder || !foundReorder.data || !foundReorder.data.cart) return false;

        let foundCartItemSelected = !!foundReorder.data.cart.itemsMenuFlow.find(obj => obj._IsSelected === true && !obj._IsDisabled);
        if (!foundCartItemSelected) {
            foundCartItemSelected = !!foundReorder.data.cart.itemsSimple.find(obj => obj._IsSelected === true && !obj._IsDisabled);
        }

        return foundCartItemSelected;
    }
);

export const reorderSelectedItemsTotalValue = (orderId: number, locationNo: number, pickupTime: OLO.Ordering.IPickupTime) => createSelector(
    state,
    reorder => {
        if (!orderId || !locationNo || !pickupTime) return 0;
        const foundReorder = reorder.find(obj => obj.LocationNo === locationNo && obj.OrderId === orderId && obj.PickupTime !== null && obj.PickupTime.Id === pickupTime.Id);
        if (!foundReorder || !foundReorder.data || !foundReorder.data.cart) return 0;

        let menuFlowsTotal: number = 0;
        for (let i = 0, j = foundReorder.data.cart.itemsMenuFlow.length; i < j; i++) {
            let menuFlow = foundReorder.data.cart.itemsMenuFlow[i];
            if (menuFlow._IsSelected && !menuFlow._IsDisabled) {
                menuFlowsTotal += menuFlow.UnitTotalValue * menuFlow.Quantity || 0;
            }
        }

        let simpleItemsTotal: number = 0;
        for (let i = 0, j = foundReorder.data.cart.itemsSimple.length; i < j; i++) {
            let simpleItem = foundReorder.data.cart.itemsSimple[i];
            if (simpleItem._IsSelected && !simpleItem._IsDisabled) {
                simpleItemsTotal += simpleItem.UnitPrice * simpleItem.Quantity;
            }
        }

        return menuFlowsTotal + simpleItemsTotal;
    }
);

export const reorderSelectedItemsTotalQuantity = (orderId: number, locationNo: number, pickupTime: OLO.Ordering.IPickupTime) => createSelector(
    state,
    reorder => {
        if (!orderId || !locationNo || !pickupTime) return 0;
        const foundReorder = reorder.find(obj => obj.LocationNo === locationNo && obj.OrderId === orderId && obj.PickupTime !== null && obj.PickupTime.Id === pickupTime.Id);
        if (!foundReorder || !foundReorder.data || !foundReorder.data.cart) return 0;

        let menuFlowsTotal: number = 0;
        for (let i = 0, j = foundReorder.data.cart.itemsMenuFlow.length; i < j; i++) {
            let menuFlow = foundReorder.data.cart.itemsMenuFlow[i];
            if (menuFlow._IsSelected && !menuFlow._IsDisabled) {
                menuFlowsTotal += menuFlow.Quantity || 0;
            }
        }

        let simpleItemsTotal: number = 0;
        for (let i = 0, j = foundReorder.data.cart.itemsSimple.length; i < j; i++) {
            let simpleItem = foundReorder.data.cart.itemsSimple[i];
            if (simpleItem._IsSelected && !simpleItem._IsDisabled) {
                simpleItemsTotal += simpleItem.Quantity;
            }
        }

        return menuFlowsTotal + simpleItemsTotal;
    }
);
