export interface IWizzard {
    errors: IWizzardError[];
    recalculatePrices: IWizzardPricesCalculate;
    itemsMenuFlow: IWizzardMenuFlow;
    itemsSimple: IWizzardSimpleItem;
}

export interface IWizzardError {
    id: number;
    pageIdentifier: number;
    error: WIZZARD_ERROR;
}

export enum WIZZARD_ERROR {
    REQUIRED = 0,
    OVERFLOW = 1,
}

export interface IWizzardPricesCalculate {
    isCalculating: boolean;
    hasSucceeded: boolean;
    hasFailed: boolean;
}

export interface IWizzardMenuFlow extends OLO.Ordering.IMenuFlowItemMock<IWizzardMenuFlowPage> { }
export interface IWizzardMenuFlowPage extends OLO.Ordering.IMenuFlowItemPage<IWizzardMenuFlowItem> { }
export interface IWizzardMenuFlowItem extends OLO.Ordering.IMenuFlowItemPageProduct { }
export interface IWizzardSimpleItem extends OLO.Ordering.ISimpleItem {
    DisplayName?: string;
    _Id?: number;
}

export interface IWizzardPageReport {
    isComplete?: boolean;
    errors?: boolean;
    pageIdentifier?: number;
}
