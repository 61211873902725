import { MetaReducer, Action } from '@ngrx/store';
import { IStateShared } from './interface';

import { __devMetaReducer } from './dev/dev.meta-reducers';
import { resetMetaReducer } from './reset/reset.meta-reducers';
// import { routerMetaReducer } from './router/router.meta-reducers';
import { stateMetaReducer } from './state/state.meta-reducer';

export {
    __devMetaReducer,
    resetMetaReducer,
    // routerMetaReducer,
    stateMetaReducer,
};

export const stateMetaReducers: MetaReducer<IStateShared, Action>[] = [
    __devMetaReducer,
    resetMetaReducer,
    // routerMetaReducer,
    stateMetaReducer,
];
