export class MemberAgeChecker {
    private _isValid: boolean = true;

    constructor(
        private _dateOfBirth: Date,
        private _minAgeYears: number,
        private _maxAgeYears?: number,
    ) {
        this._isValid = this._validateDateOfBirth();
    }

    private _validateDateOfBirth(): boolean {
        /* Logic transpiled from our backend! https://pastebin.com/ifv8kigC  */
        if (!this._minAgeYears) return true;

        const today = this._now;

        let calculatedAge: number = today.getFullYear() - this._dateOfBirth.getFullYear();

        if (today.getMonth() < this._dateOfBirth.getMonth() || (today.getMonth() === this._dateOfBirth.getMonth() && today.getDate() < this._dateOfBirth.getDate())) {
            calculatedAge--;
        }

        if (calculatedAge < this._minAgeYears) {
            return false;
        }

        if (typeof this._maxAgeYears === 'number' && calculatedAge > this._maxAgeYears) {
            return false;
        }

        return true;
    }

    public isValid(): boolean {
        return this._isValid;
    }

    private get _now(): Date {
        return new Date();
    }
}
