import { Injectable } from '@angular/core';
import { Action, Store, select } from '@ngrx/store';
import { Effect, Actions, ofType } from '@ngrx/effects';

import * as actions from '../actions';
import * as selectors from '../selectors';
import * as Services from '@shared/core/services';
import * as Utils from '@shared/core/utils';
import * as StateModels from '../interface';

import { Observable, of, never } from 'rxjs';
import { map, catchError, switchMap, delay, withLatestFrom, take } from 'rxjs/operators';

@Injectable()
export class OnlineMenuPagesImagesEffects {
    @Effect() public onOnlineMenuSuccessGetPagesImages$: Observable<Action> = this._actions$
        .pipe(
            ofType(actions.OnlineMenuPagesSuccessRequest),
            withLatestFrom(
                this._store
                    .pipe(
                        select(selectors.getOnlineMenuPagesImages),
                    )
            ),
            switchMap(([action, images]) => {
                const imageType: OLO.Enums.IMAGE_TYPE = OLO.Enums.IMAGE_TYPE.ForWeb;
                const imageTypeName: string = Utils.Images.toImageTypeString(imageType);
                const pageIds = action.payload.Pages.reduce((acc, page) => {

                    const foundImg = images[imageTypeName].reduce((imgFound, type) => {
                        if (imgFound) return imgFound;
                        return type[imageType]?.find(obj => obj.pageId === page.Id) || null;

                    }, null);

                    const shouldDownload = !foundImg || foundImg.hasSucceeded === false && foundImg.isDownloading === false;
                    if (shouldDownload) {
                        return [
                            ...acc,
                            page.Id,
                        ];
                    }

                    return acc;

                }, [] as Array<number>);

                if (!pageIds.length) return never();

                return of(actions.OnlineMenuPagesImagesRequest({
                    params: {
                        width: window.innerWidth,
                    },
                    imageType,
                    pageIds
                }));
            })
        );

    @Effect() public requestOnlineMenuPagesImages$: Observable<Action> = this._actions$
        .pipe(
            ofType(
                actions.OnlineMenuPagesImagesRequest
            ),
            switchMap(action => {
                return this._imagesService.getImagesForOnlineMenuPages(action.params, action.imageType, action.pageIds)
                    .pipe(
                        delay(1000),
                        map(payload => actions.OnlineMenuPagesImagesSuccessRequest({ pageIds: action.pageIds, imageType: action.imageType, payload })),
                        catchError(ex => {
                            console.error('Error downloading online menu pages images', ex);
                            return of(actions.OnlineMenuPagesImagesErrorRequest({ pageIds: action.pageIds, imageType: action.imageType, ex }));
                        })
                    );
            }),
        );

    constructor(
        private _actions$: Actions,
        private _imagesService: Services.ImagesService,
        private _store: Store<StateModels.IStateShared>,
    ) { }
}
